import { assets } from '@puzzles/core/asset-utility';
import { VehicleView, VehicleViewProps } from './vehicle-view';
import { AnimatedSprite, Container, Sprite, Spritesheet, Texture } from 'pixi.js';
import { gsap } from 'gsap';
import { wait } from '@puzzles/core/async/awaitable';
import { VehicleProgressView } from './vehicle-progress-view';
import { Vector2 } from '@pixi-spine/all-4.1';
import { shakePosition } from '@puzzles/core/animation-utility';

import rocketData from '../assets/vehicle/rocket-mine.json';
import rocketSprite from '../assets/vehicle/rocket-mine.png?url';
import lightSprite from '../assets/vehicle/light.png?url';
import circleSprite from '../assets/vehicle/circle.png?url';

@assets('RocketMineView', {
  rocketSprite: rocketSprite,
  lightSprite: lightSprite,
  circleSprite: circleSprite,
})

export class RocketMineView extends VehicleView {
  private cockpitContainer: Container = new Container();
  private windowMask: Sprite = new Sprite();
  private prop!: AnimatedSprite;

  constructor(data: VehicleViewProps) {
    super(data);

    this.characterContainer.scale.set(0.54);
    this.init(data);
  }

  private async init(data: VehicleViewProps) {
    const spritesheet = new Spritesheet(Texture.from(rocketSprite), rocketData);
    await spritesheet.parse();

    this.vehicleContainer.addChild(this.cockpitContainer);

    let sprite = new Sprite(spritesheet.textures['rocket.png']);
    sprite.scale.set(0.5);
    this.cockpitContainer.addChild(sprite);

    let window = new Sprite(Texture.from(lightSprite));
    window.anchor.set(0.5);
    window.scale.set(0.45);
    window.position.set(0, -3.5);
    this.cockpitContainer.addChild(window);

    this.cockpitContainer.addChild(this.characterContainer);

    let highlight = new Sprite(Texture.from(circleSprite));
    highlight.zIndex = 9;
    highlight.scale.set(0.12);
    highlight.anchor.set(0.5);
    highlight.position.set(-6, -12);
    this.cockpitContainer.addChild(highlight);

    this.windowMask = new Sprite(Texture.from(circleSprite));
    this.windowMask.anchor.set(0.5);
    this.windowMask.scale.set(0.45);
    this.windowMask.position.set(0, -3.5);

    this.prop = new AnimatedSprite(spritesheet.animations.prop);
    this.prop.anchor.set(0.5, 1);
    this.prop.scale.set(0.5);
    this.prop.position.set(0, -41);
    this.prop.loop = true;
    this.vehicleContainer.addChild(this.prop);
    this.prop.currentFrame = 0;

    this.vehicleProgress = new VehicleProgressView(data, this.vehicleContainer, spritesheet.textures['shadow.png'], new Vector2(130, 120));
    this.vehicleContainer.addChild(this.vehicleProgress);
    this.vehicleProgress.y = -12;

    this.pivot.y = 45;
  }

  public async launch(success: boolean): Promise<void> {
    await wait(100);
    this.cockpitContainer.addChild(this.windowMask);
    this.characterContainer.mask = this.windowMask;

    let duck = this.targetDuck.DuckContainer;
    this.characterContainer.addChild(duck);
    duck.position.set(0);
    this.characterContainer.angle = -10;
    this.characterContainer.position.set(0, duck.height);

    await gsap.to(this.characterContainer, {
      y: 25,
      duration: 0.3,
      ease: 'back.out',
    });

    if (success) {
      this.prop.play();
      gsap.fromTo(
        this.prop,
        { animationSpeed: 1 / 10 },
        { animationSpeed: 1 / 1.5, duration: 0.7, ease: 'power1.in' },
      );
      shakePosition(this.cockpitContainer, 1, 1, 1, 30, 0.5, 'in');
      await wait(500);
  
      shakePosition(this.cockpitContainer, 1, 1, 1.5, 45, 1.3);
      await gsap.to(this.vehicleContainer, { x: 0, y: -1000, duration: 1.3, ease: 'power3.in' });
    } else {
      await wait(200);
      await gsap.to(this.vehicleContainer, { y: '-=60', duration: 0.5, ease: 'power2.out' });
      await wait(100);
      await gsap.to(this.vehicleContainer, { y: 0, duration: 0.4, ease: 'bounce.out' });
    }
  }
}
