import gsap from 'gsap';
import { Container, Graphics, IDestroyOptions } from 'pixi.js';
import { Rect, Rectangle } from '../core/math/rectangle';
import { Rescalable } from '../core/pixi/display-scaler';
import { rectLerp } from '../core/math/interpolation';
import { FractionView } from './views/fraction-view';
import { FractionData } from '../core/math/fraction';
import { defaultRectStyleProps, RectStyleProps } from './action-view-props';
import { FractionDisplayMode } from './puzzle-data';

export type ActionDataProps = {
  value: FractionData;
  display: FractionDisplayMode;
};

export type ActionProps = ActionDataProps & ActionViewStyleProps;

export type ActionViewStyleProps = {
  positive: RectStyleProps;
  negative: RectStyleProps;
};

export const defaultActionViewStyleProps: ActionViewStyleProps = {
  positive: {
    ...defaultRectStyleProps,

    fillColor: 0x1d9ce4,
    lineColor: 0xffffff,
    textColor: 0xffffff,
  },
  negative: {
    ...defaultRectStyleProps,
    fillColor: 0xe83189,
    lineColor: 0xffffff,
    textColor: 0xffffff,
  },
};

export class ActionView extends Container implements Rescalable {
  static nextId = 0;
  id: number;

  private container: Container = new Container();
  private graphics: Graphics = new Graphics();
  private fractionView: FractionView;
  private focusGraphics: Graphics[] = [];
  private focusContainer: Container = new Container();

  private isPositive: boolean;

  props: ActionProps;
  originalProps: ActionProps;
  animatedProps: ActionProps;
  rect: Rect = { x: 0, y: 0, width: 0, height: 0 };
  animatedRect: { t: number; from: Rect; to: Rect } = {
    t: 0,
    from: { x: 0, y: 0, width: 0, height: 0 },
    to: { x: 0, y: 0, width: 0, height: 0 },
  };

  constructor(props: ActionDataProps & Partial<ActionViewStyleProps>) {
    super();
    this.id = ActionView.nextId++;
    this.props = {
      ...defaultActionViewStyleProps,
      positive: { ...defaultActionViewStyleProps.positive },
      negative: { ...defaultActionViewStyleProps.negative },
      ...props,
    };
    this.originalProps = {
      ...this.props,
      positive: { ...this.props.positive },
      negative: { ...this.props.negative },
    };
    this.animatedProps = {
      ...this.props,
      positive: { ...this.props.positive },
      negative: { ...this.props.negative },
    };
    this.isPositive = this.props.value.numerator > 0 && this.props.value.denominator > 0;

    this.container.addChild(this.graphics);
    this.addChild(this.container);

    this.interactive = true;
    this.pivot.set(0.5);

    const currentProps = this.isPositive ? this.props.positive! : this.props.negative!;
    this.fractionView = new FractionView(
      this.props.value,
      {
        fontFamily: currentProps.textFont,
        fontSize: 20,
        fill: currentProps.textColor,
        align: 'center',
        fontWeight: currentProps.textFontWeight,
      },
      { showPositiveSymbol: true },
    );
    this.fractionView.setMode(this.props.display);
    this.container.addChild(this.fractionView);
 
    for(let i=0; i < 3; i++){
      this.focusGraphics[i] = new Graphics();
      this.focusContainer.addChild(this.focusGraphics[i]);
    }
    this.focusContainer.scale.set(0);
    this.container.addChild(this.focusContainer);
  }

  destroy(options?: boolean | IDestroyOptions | undefined): void {
    this.fractionView?.destroy();
    super.destroy(options);
  }

  moveTo(target: Rect, duration: number = 0.3) {
    const anim = this.animatedRect;
    anim.t = 0;
    anim.from = this.getBounds();
    anim.to = target;

    gsap.killTweensOf(this.position);
    gsap.killTweensOf(this.scale);
    gsap.killTweensOf(anim);

    gsap.to(anim, {
      duration: duration,
      t: 1,
      ease: 'back.out',
      onUpdate: () => this.resize(rectLerp(anim.from, anim.to, anim.t)),
    });

    gsap.to(this.scale, {
      duration: duration,
      ease: 'back.out',
      x: 1,
      y: 1,
    });
  }

  rescale(scaleFactor: number): void { }

  resize(rect: Rect) {
    this.rect = rect;
    this.position.set(rect.x + rect.width / 2, rect.y + rect.height / 2);

    this.props = {
      ...this.originalProps,
      positive: { ...this.originalProps.positive },
      negative: { ...this.originalProps.negative },
    };
    const scaleFactor = rect.height * 0.01;
    this.props.positive.lineWidth *= scaleFactor;
    this.props.positive.radius *= scaleFactor;
    this.props.negative.lineWidth *= scaleFactor;
    this.props.negative.radius *= scaleFactor;
    switch (true) {
      case window.matchMedia('(min-width: 1024px)').matches:
        this.props.negative.textFontWeight = '700';
        this.props.positive.textFontWeight = '700';
        break;
      default:
        this.props.negative.textFontWeight = '600';
        this.props.positive.textFontWeight = '600';
    }

    this.draw();
  }

  public focus(b: boolean) {
    gsap.killTweensOf(this.container);
    gsap.killTweensOf(this.focusContainer)
    this.zIndex = b? 9:0;
    if(b){
      gsap.to(this.container.scale, { x: 1.15, y: 1.15, duration: 0.3, ease: 'back.out(3)' });
      gsap.to(this.container, { angle: -5, duration: 0.3, ease: 'back.out(2)' });
      gsap.to(this.focusContainer.scale, { x: 1, y: 1, duration: 0.3, ease: 'back.out(3)' });
    }
    else{
      gsap.to(this.container.scale, { x: 1, y: 1, duration: 0.1});
      gsap.to(this.container, { angle: 0, duration: 0.1 });
      gsap.to(this.focusContainer.scale, { x: 0, y: 0, duration: 0.1 });
    }
  }

  draw(rect: Rect = this.rect, props: ActionProps = this.props) {
    const currentProps = this.isPositive ? props.positive : props.negative;
    const isVertical = rect.width / rect.height < 1.1;

    this.graphics.clear();

    const centered = {
      x: -rect.width / 2 / this.scale.x,
      y: -rect.height / 2 / this.scale.y,
      width: rect.width / this.scale.x,
      height: rect.height / this.scale.y,
    };

    this.graphics.lineStyle({
      width: currentProps.lineWidth,
      color: currentProps.lineColor,
      alignment: 1,
    });
    this.graphics.beginFill(currentProps.fillColor);
    this.graphics.drawRoundedRect(
      centered.x,
      centered.y,
      centered.width,
      centered.height,
      currentProps.radius,
    );
    this.graphics.endFill();

    const inner = new Rectangle();
    const innerScale = 0.35;

    inner.height =
      centered.height - (isVertical ? currentProps.lineWidth * 2 : currentProps.lineWidth);
    inner.width = isVertical
      ? centered.width - currentProps.lineWidth * 2
      : centered.width * innerScale;

    inner.height = centered.height - currentProps.lineWidth * 2;
    inner.width = centered.width - currentProps.lineWidth * 2;

    inner.x = centered.x + centered.width / 2 - inner.width / 2;
    inner.y = centered.y + (centered.height - inner.height) / 2;

    this.graphics.lineStyle(0);
    this.graphics.beginFill(currentProps.fillColor);
    this.graphics.drawRoundedRect(
      inner.x,
      inner.y,
      inner.width,
      inner.height,
      currentProps.radius - (centered.height - inner.height) / 2,
    );
    this.graphics.endFill();

    this.fractionView.x = inner.x + inner.width / 2;
    this.fractionView.y = inner.y + inner.height / 2;
    const isHorizontal = inner.height < inner.width;
    this.fractionView.setFontSize (
      (isHorizontal ? inner.height : inner.width) *
      (isHorizontal ? 0.42 : 0.4) *
      currentProps.textScaling, inner.width * 0.9 );

    let focusRect :Rect = {
      x: 0,
      y: 0,
      width: currentProps.lineWidth * 5,
      height: currentProps.lineWidth * 2,
    };
    focusRect.x = focusRect.width / -2;
    focusRect.y = focusRect.height / -2;

    for(let i=0; i < this.focusGraphics.length; i++){
      let g= this.focusGraphics[i];

      g.clear();
      g.lineStyle({ width: currentProps.lineWidth, color: currentProps.lineColor, alignment: 1 });
      g.beginFill(currentProps.fillColor);
      g.drawRoundedRect(focusRect.x, focusRect.y, focusRect.width, focusRect.height, currentProps.radius);
      
      g.angle = -5 + i * 40;
    }

    this.focusGraphics[0].position.set( -focusRect.width / 2, focusRect.height * 1.4);
    this.focusGraphics[1].position.set( -focusRect.width / 1.4, -focusRect.height * 0.8);
    this.focusGraphics[2].position.set( focusRect.width / 2.5, -focusRect.height * 2.5);

    this.focusContainer.position.set(centered.x - focusRect.width / 2, centered.y);
  }
}
