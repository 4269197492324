import { arcLenghtAngle, arcLength, pointOnCircleClockwise } from '@puzzles/core/math/geometry';
import { generateSegments, isCloseToZero } from '@puzzles/core/math/utility';
import { Rescalable } from '@puzzles/core/pixi/display-scaler';
import { Container, Graphics, ILineStyleOptions, LINE_CAP, Point } from 'pixi.js';

export function drawDashedArc(
  graphics: Graphics,
  origin: Point,
  radius: number,
  angleStart: number,
  angleEnd: number,
  dash: number = 12,
  lineStyle?: ILineStyleOptions,
  fillColor: number = 0xe83189,
  fillAlpha: number = 0.5,
  anticlockwise: boolean = false,
) {
  graphics.clear();
  lineStyle = lineStyle ?? {
    width: 5,
    color: 0xffffff,
    cap: LINE_CAP.ROUND,
  };

  const start = pointOnCircleClockwise(radius, angleStart + Math.PI / 2);
  const end = pointOnCircleClockwise(radius, angleEnd + Math.PI / 2);
  const arc = Math.abs(arcLength(radius, angleEnd - angleStart));

  // pixi y-axis is inverted
  start.y *= -1;
  end.y *= -1;

  // graphics.lineStyle(1, 0xff0000);
  // graphics.drawCircle(angleStartPoint.x, angleStartPoint.y, 20);
  // graphics.lineStyle(1, 0x00ff00);
  // graphics.drawCircle(origin.x, origin.y, 5);
  // graphics.lineStyle(1, 0x0000ff);
  // graphics.drawCircle(angleEndPoint.x, angleEndPoint.y, 20);

  graphics
    .beginFill(fillColor, fillAlpha)
    .moveTo(0, 0)
    .lineStyle(dash == 0 ? lineStyle : { width: 0, alpha: 0 })
    .lineTo(start.x, start.y)
    .arc(0, 0, radius, angleStart, angleEnd, anticlockwise)
    .lineTo(0, 0)
    .endFill();

  if (dash == 0) return;

  let angle = angleStart;
  let drawing = true;
  let remainingSegment = dash;

  graphics.moveTo(start.x, start.y);

  for (var [_, segment] of generateSegments([arc], dash)) {
    const segmentAngle = arcLenghtAngle(radius, segment) * (anticlockwise ? -1 : 1);
    graphics
      .lineStyle({
        width: drawing ? lineStyle.width : 0,
        color: lineStyle.color,
        cap: lineStyle.cap,
      })
      .arc(origin.x, origin.y, radius, angle, angle + segmentAngle, anticlockwise);
    angle += segmentAngle;
    remainingSegment -= segment;

    if (isCloseToZero(remainingSegment)) {
      drawing = !drawing;
      remainingSegment = dash;
    }
  }
}

export type DashedArcViewProps = {
  fillColor: number;
  dash: number;
  lineWidth: number;
  lineColor: number;
};

const defaultDashedArcViewProps: DashedArcViewProps = {
  fillColor: 0x000000,
  dash: 12,
  lineWidth: 5,
  lineColor: 0xffffff,
};

export class DashedArcView extends Container implements Rescalable {
  graphics: Graphics = new Graphics();
  private props: DashedArcViewProps;
  private originalProps: DashedArcViewProps;
  constructor(props: Partial<DashedArcViewProps> = {}) {
    super();
    this.props = { ...defaultDashedArcViewProps, ...props };
    this.originalProps = { ...this.props };
    this.addChild(this.graphics);
  }

  rescale(scaleFactor: number): void {
    this.props = { ...this.originalProps };
    this.props;
  }

  draw(radius: number, angleStart: number, angleEnd: number, anticlockwise: boolean = false) {
    drawDashedArc(
      this.graphics,
      new Point(0, 0),
      radius,
      angleStart,
      angleEnd,
      this.props.dash,
      {
        width: this.props.lineWidth,
        color: this.props.lineColor,
        cap: LINE_CAP.ROUND,
      },
      this.props.fillColor,
      0.5,
      anticlockwise,
    );
  }
}
