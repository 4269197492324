import { Assets, Container } from 'pixi.js';
import { CharacterView, ChracterAnimationType } from './character-view';
import { assets } from '../core/asset-utility';
import { Emitter } from '@pixi/particle-emitter';
import { Tickable } from '../core/interfaces/tickable';
import { Rescalable } from '../core/pixi/display-scaler';

import sweatEmitter from './assets/duck/emitter-sweat.json?url';
import sadEmitter from './assets/duck/emitter-sad.json?url';
import fallDownEmitter from './assets/duck/emitter-fall-down.json?url';

@assets('CharacterFeedbackController', {
  sweatEmitter: sweatEmitter,
  sadEmitter: sadEmitter,
  fallDownEmitter: fallDownEmitter,
})
export class CharacterFeedbackController extends Container implements Tickable, Rescalable {
  private character: CharacterView;
  private sweatEmitter: Emitter;
  private sweatContainer: Container = new Container();
  private sadEmitter: Emitter;
  private sadContainer: Container = new Container();
  private fallDownEmitter: Emitter;
  rescaleEnabled: boolean;

  constructor(character: CharacterView, rescaleEnabled: boolean = true) {
    super();
    this.character = character;
    this.rescaleEnabled = rescaleEnabled;

    this.addChild(this.sweatContainer);
    this.sweatEmitter = new Emitter(this.sweatContainer, Assets.get('sweatEmitter'));

    this.addChild(this.sadContainer);
    this.sadEmitter = new Emitter(this.sadContainer, Assets.get('sadEmitter'));

    this.fallDownEmitter = new Emitter(this, Assets.get('fallDownEmitter'));

    this.character.onAnime = (animation: ChracterAnimationType) => this.playFeedback(animation);
    character.onDestroy = () => this.destroy();
  }

  rescale(scaleFactor: number): void {
    if (!this.rescaleEnabled) return;
    this.scale.set(0.65 * scaleFactor);
  }

  tick(delta: number): void {
    if (!this.character) return;

    if (this.sweatEmitter.emit) this.setEmitterPosition(this.sweatEmitter);
  }

  private playFeedback(animation: ChracterAnimationType): void {
    switch (animation) {
      case 'idle':
      default:
        this.sweatEmitter.emit = this.sadEmitter.emit = false;
        break;
      case 'jump':
        this.sweatContainer.scale.x = this.character.scale.x > 0 ? 1 : -1;
        this.sweatEmitter.emit = true;
        break;
      case 'sad':
        this.sadContainer.scale.x = this.character.scale.x > 0 ? 1 : -1;
        this.setEmitterPosition(this.sadEmitter);
        this.sadEmitter.emit = true;
        break;
      case 'shock':
        this.setEmitterPosition(this.fallDownEmitter);
        this.fallDownEmitter.emit = true;
        break;
    }
  }

  private setEmitterPosition(emitter: Emitter) {
    let moveDir = emitter.parent.scale.x > 0 ? 1 : -1;
    let pos = this.toLocal(this.character.DuckContainer.getGlobalPosition());
    emitter.updateOwnerPos(pos.x * moveDir, pos.y);
    emitter.rotate(this.character.rotation * moveDir);
  }
}
