import { EntityView } from './entity-view';
import { assets } from '../../core/asset-utility';
import { Assets, Sprite, Texture } from 'pixi.js';
import { Emitter } from '@pixi/particle-emitter';

import portalSprite from '../assets/teleport.png?url';
import emitterPortal from '../assets/emitter-portal.json?url';
import emitterPortal2 from '../assets/emitter-portal2.json?url';

@assets('portalView', {
  portalSprite: portalSprite,
  emitterPortal: emitterPortal,
  emitterPortal2: emitterPortal2,
})
export class PortalView extends EntityView {
  constructor() {
    super();

    let emitter = new Emitter(this, Assets.get('emitterPortal'));
    emitter.updateOwnerPos(0, -35);

    let emitter2 = new Emitter(this, Assets.get('emitterPortal2'));
    emitter2.updateOwnerPos(0, -35);

    let sprite = new Sprite(Texture.from(portalSprite));
    sprite.scale.set(0.5);
    sprite.anchor.set(0.5, 1);
    sprite.zIndex = 1;
    this.addChild(sprite);

    this.sortableChildren = true;
    this.pivot.y = -10;
    this.sortChildren();
  }
}
