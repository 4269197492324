import { IEntityView, EntityView } from './entity-view';
import { Container} from 'pixi.js';
import { CharacterView } from '../character-view';
import { VehicleProgressView } from './vehicle-progress-view';

export interface IVehicleView extends IEntityView {
  chargeEnergy(): void;
}

export type VehicleViewProps = {
  energy: number;
  max: number;
};

export abstract class VehicleView extends EntityView implements IVehicleView {
  protected characterContainer = new Container();
  public targetDuck!: CharacterView;
  protected vehicleContainer = new Container();
  protected vehicleProgress!: VehicleProgressView;

  constructor(data: VehicleViewProps) {
    super();

    this.vehicleContainer.zIndex = 9;
    this.vehicleContainer.sortableChildren = true;
    this.sortableChildren = true;
    
    this.addChild(this.vehicleContainer);
  }

  public chargeEnergy(): void {
    this.vehicleProgress.chargeEnergy();
  }

  public abstract launch(success: boolean): Promise<void>;
}
