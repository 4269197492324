import { Container, Graphics, Rectangle } from 'pixi.js';
import { Rect, generateSubRects } from '../core/math/rectangle';
import { CharacterView } from './character-view';
import { BackgroundView } from './background-view';
import { BatteryView } from './entityviews/battery-view';
import { EntityView } from './entityviews/entity-view';
import { PortalView } from './entityviews/portal-view';
import { RulerCommonData, RulerData } from './puzzle-data';
import { CircleRulerView, IRulerPixiView, LineRulerView } from './ruler-view';
import { CharacterFeedbackController } from './character-feedback-controller';
import { Entity, PuzzleState, Vehicle } from './puzzle-state';
import { distance } from '@puzzles/core/math/vector';
import { RulerMarkerView } from './ruler-marker-view';
import { PuzzleSkinData, getVehicleAssetClass } from './puzzle-skin';

type PuzzleViewOptions = {
  marginX: number;
  marginY: number;
};

const defaultPuzzleViewOptions: PuzzleViewOptions = {
  marginX: 0.05,
  marginY: 0.05,
};

type Constructor<T> = new (...args: any[]) => T;

export class PuzzleView extends Container {
  graphics: Graphics;
  rulers: IRulerPixiView[] = [];
  entities: EntityView[] = [];
  entityRulerMap: Map<EntityView, IRulerPixiView> = new Map();
  props: PuzzleViewOptions;
  originalProps: PuzzleViewOptions;
  rect?: Rect;
  characterMarker!: RulerMarkerView;
  background?: BackgroundView;

  constructor(options: Partial<PuzzleViewOptions> = {}) {
    super();
    this.props = { ...defaultPuzzleViewOptions, ...options };
    this.originalProps = { ...this.props };

    this.graphics = new Graphics();
    this.addChild(this.graphics);
    this.graphics.lineStyle(1, 0xff0000);

    this.interactive = false;
  }

  resize(container: Rect) {
    this.rect = container;
    this.graphics.clear();

    this.background?.draw(this.rect);

    let i = 0;
    let r = this.rulers.length;
    let marginRect = this.getGameView(container, this.props.marginX, this.props.marginY);
    let positionMap = new Map<EntityView, number>();
    this.entities.forEach((e) => positionMap.set(e, this.entityRulerMap.get(e)!.getFraction(e)));
    const subRects = this.generateRulerRects(marginRect, r);
    for (let ruler of this.rulers) {
      let rect = subRects[i];
      if(ruler instanceof LineRulerView) rect.pad(Math.min(-this.rect.width * 0.03, -8), 0);
      ruler.resize(rect);
      i++;
    }

    for (let entity of this.entities) {
      const transform = this.entityRulerMap.get(entity)!.getTransform(positionMap.get(entity)!)[0];
      entity.x = transform.x;
      entity.y = transform.y;

      
      if(entity instanceof CharacterView){
        this.characterMarker.position.set(entity.x, entity.y);
        this.characterMarker.rotation = entity.rotation;
      }
    }
  }

  private getGameView(container: Rect, marginX: number, marginY: number): Rect {
    var marginX = -container.width * marginX;
    var marginY = -container.height * marginY;
    var marginRect = new Rectangle(container.x, container.y, container.width, container.height).pad(
      Math.min(marginX, marginY)
    );
    return marginRect;
  }

  private generateRulerRects(container: Rect, count: number) {
    if (
      this.rulers.filter((r) => r instanceof CircleRulerView).length == 0 ||
      this.rulers.filter((r) => r instanceof CircleRulerView).length == 1
    ) {
      return [...generateSubRects(container, count, 'center', 'y', 0)];
    }

    var vertical = container.width < container.height;
    var axis: 'x' | 'y' = vertical ? 'y' : 'x';
    return [...generateSubRects(container, count, 'center', axis, 0)];
  }

  public isInstanceOf<T extends EntityView>(e: EntityView, ctor: Constructor<T>): e is T {
    return e instanceof ctor;
  }

  // public selectEntity<T>( typeName: Constructor<T>): T {
  //   return this.entities.find( e => e instanceof typeName) as T;
  // }

  public selectEntity<T extends EntityView>(predicate: (e: EntityView) => boolean, ruler?: number, fraction?: number) {
    if (ruler === undefined) {
      return this.entities.find(predicate) as T;
    } else {
      return this.entities
        .filter((e) => {
          const entityRuler = this.entityRulerMap.get(e) as IRulerPixiView;
          const rulerIndex = this.rulers.indexOf(entityRuler);
          return fraction === undefined
            ? ruler == rulerIndex
            : rulerIndex === ruler && distance(e.position, entityRuler.getTransform(fraction)[0]) < 0.1;
        })
        .find(predicate) as T;
    }
  }
}

export class PuzzleViewBuilder {
  build(puzzle: PuzzleState, skin: PuzzleSkinData): PuzzleView {
    var puzzleView = new PuzzleView();

    puzzleView.background = new BackgroundView(skin.background);
    puzzleView.addChild(puzzleView.background);

    for (var [, rulerView] of this.buildRulers(puzzle.rulers)) {
      puzzleView.addChild(rulerView);
      puzzleView.rulers.push(rulerView);
      
      if(rulerView instanceof LineRulerView){
        let index = puzzleView.rulers.length - 1;
        (rulerView as LineRulerView).addEntityFractions(puzzle.entities.filter(e => e.ruler == index));
      };
    }

    puzzleView.characterMarker = new RulerMarkerView();
    puzzleView.addChild(puzzleView.characterMarker);

    for (var [data, view] of this.buildRulerEntities(puzzle.entities, skin)) {
      const rulerView = puzzleView.rulers[data.ruler];
      puzzleView.addChild(view);
      puzzleView.entities.push(view);
      puzzleView.entityRulerMap.set(view, rulerView);

      const [pos, rot] = rulerView.getTransform(data);

      view.x = pos.x;
      view.y = pos.y;
      view.rotation = rot;

      if (view instanceof CharacterView) {
        let feedback = new CharacterFeedbackController(view);
        feedback.zIndex = view.zIndex;
        puzzleView.addChild(feedback);
      }
    }

    puzzleView.sortableChildren = true;
    puzzleView.sortChildren();

    return puzzleView;
  }

  *buildRulers(rulers: RulerData[]): Generator<[RulerCommonData, IRulerPixiView]> {
    for (var ruler of rulers) {
      yield [ruler, this.buildRuler(ruler)];
    }
  }

  buildRuler(ruler: RulerData): IRulerPixiView {
    switch (ruler.type) {
      case 'circle':
        return new CircleRulerView({
          ...ruler,
          radius: 10000,
          angleOffset: ruler.angleOffset ? ruler.angleOffset : 0,
          gizmos: false,
        }); // we need the radius to be big enough
      case 'line':
        return new LineRulerView({ ...ruler, gizmos: false });
      case 'segment':
        return new LineRulerView({ ...ruler, gizmos: false }); // TODO
    }
  }

  *buildRulerEntities(entities: Entity[], skin: PuzzleSkinData): Generator<[Entity, EntityView]> {
    for (var entity of entities) {
      yield [entity, this.buildEntity(entity, skin)];
    }
  }

  buildEntity(entity: Entity, skin: PuzzleSkinData): EntityView {
    switch (entity.type) {
      case 'duck': {
        let view = new CharacterView();
        view.setSkin(skin.characterSkin);
        view.zIndex = 1;
        return view;
      }
      case 'battery': {
        let view = new BatteryView();
        view.zIndex = 9;
        return view;
      }
      case 'portal':
        return new PortalView();
      case 'vehicle': {
        let vehicle = entity as Vehicle;
        let view = new (getVehicleAssetClass(skin.vehicle))({
          energy: vehicle.energy,
          max: vehicle.max,
        });
        view.zIndex = 99;
        return view;
      }
      default:
        throw new Error('not supported');
    }
  }
}
