import { playBump } from '@puzzles/core/animation-utility';
import { Rescalable } from '@puzzles/core/pixi/display-scaler';
import { Container, Graphics } from 'pixi.js';
import gsap from 'gsap';

export type RulerMarkerProps = {
  lineColor: number;
  lineWidth: number;
  fillColor: number;
  radius: number;
};

const defaultRulerMarkerProps: RulerMarkerProps = {
  lineColor: 0x0a0878,
  lineWidth: 4,
  fillColor: 0xffbe18,
  radius: 6,
};

export class RulerMarkerView extends Container implements Rescalable {
  private graphics: Graphics;
  private props: RulerMarkerProps;
  private originalProps: RulerMarkerProps;

  constructor(props: Partial<RulerMarkerProps> = {}) {
    super();

    this.props = { ...defaultRulerMarkerProps, ...props };
    this.originalProps = { ...this.props };

    this.graphics = new Graphics();
    this.addChild(this.graphics);

    this.graphics.scale.set(0);
  }

  public show(show: boolean){
    gsap.killTweensOf(this.graphics);
    if(show)
      playBump(this.graphics, 0.25, 0, 1);
    else
      gsap.to(this.graphics.scale, {x:0, y:0, duration: 0.15});
  }
   
  rescale(scaleFactor: number): void {
    this.props = { ...this.originalProps };
    this.props.lineWidth *= scaleFactor;
    this.props.radius *= scaleFactor;
    this.pivot.y = -9 * scaleFactor;

    this.draw();
  }

  draw() {
    this.graphics.clear();
    this.graphics.lineStyle(this.props.lineWidth, this.props.lineColor, 1);
    this.graphics.beginFill(this.props.fillColor, 1);
    this.graphics.drawCircle(0, 0, this.props.radius);
    this.graphics.endFill();
  }
}
