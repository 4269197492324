import { TextStyleFontWeight } from 'pixi.js';

export type RectStyleProps = {
  fillColor: number;
  lineColor: number;
  lineWidth: number;
  radius: number;
  textColor: number;
  textFont: string;
  textFontSize: number;
  textFontWeight: TextStyleFontWeight;
  textScaling: number;
  preferredSize: { width: number; height: number };
};

export const defaultRectStyleProps: RectStyleProps = {
  fillColor: 0xffffff,
  lineColor: 0x1d9ce4,
  lineWidth: 5,
  radius: 12,
  textColor: 0xffffff,
  textFont: 'Montserrat',
  textFontSize: 20,
  textFontWeight: '600',
  textScaling: 1,
  preferredSize: { width: 150, height: 70 },
};
