import { EntityView } from './entity-view';
import { assets } from '../../core/asset-utility';
import { Assets, Container, Sprite, Texture } from 'pixi.js';
import { Emitter } from '@pixi/particle-emitter';
import { ColorOverlayFilter } from '@pixi/filter-color-overlay';
import gsap from 'gsap';

import emitterSpark from '../assets/emitter-battery.json?url';
import batterySprite from '../assets/battery.png?url';

@assets('BatteryView', {
  batterySprite: batterySprite,
  emitterSpark: emitterSpark,
})
export class BatteryView extends EntityView {
  private container = new Container();
  private whiteFilter = new ColorOverlayFilter(0xffffff, 0.01);

  constructor() {
    super();
    this.addChild(this.container);

    let sprite = new Sprite(Texture.from(batterySprite));
    sprite.scale.set(0.5);
    sprite.anchor.set(0.5);
    this.container.addChild(sprite);

    new Emitter(this.container, Assets.get('emitterSpark'));
    this.pivot.y = 40;
    this.idle();
  }

  private idle(){
    this.timeline = gsap.timeline();
    this.timeline.to(this.container, {y: '-=5', duration: 1.5, yoyo: true, repeat: -1});
  }

  public async pickAnimation() {
    gsap.killTweensOf(this.container);

    this.filters = [this.whiteFilter];
    gsap.to(this.container, { angle: '-= 30', duration: 0.25 });
    gsap.to(this.container, { y: -70, duration: 0.3 });
    gsap.to(this.container.scale, { x: '+= 0.2', y: '+= 0.2', duration: 0.3 });

    await gsap.to(this.whiteFilter, { alpha: 1, duration: 0.2 });
    await gsap.to(this.container, { alpha: 0, duration: 0.2 });
    this.filters = [];
    this.visible = false;
  }
}
