import { cubicLerp } from './interpolation';
import { Vec2 } from './vector';

/**
 * Generates a list of points along a cubic bezier curve
 * @param p0 control point 0
 * @param p1 control point 1
 * @param p2 control point 2
 * @param p3 control point 3
 * @param points number of points
 * @param out for memory efficiency, make sure to consume the values before calling next()
 */
export function* generateCubicBezier(
  p0: Vec2,
  p1: Vec2,
  p2: Vec2,
  p3: Vec2,
  points: number,
  out?: Vec2
): Generator<Readonly<Vec2>> {
  for (let i = 0; i < points; i++) {
    const t = i / (points - 1);
    const x = cubicLerp(p0.x, p1.x, p2.x, p3.x, t);
    const y = cubicLerp(p0.y, p1.y, p2.y, p3.y, t);
    if (out) {
      out.x = x;
      out.y = y;
      yield out;
    } else yield { x: x, y: y };
  }
}
