import gsap from 'gsap';
import { assets } from '../core/asset-utility';
import { Assets, Container } from 'pixi.js';
import { wait, waitUntil } from '../core/async/awaitable';
import { CancellationToken, CancellationTokenSource } from '../core/async/cancellations';
import { EntityView } from './entityviews/entity-view';
import { Vec2 } from '../core/math/vector';
import { ColorOverlayFilter } from '@pixi/filter-color-overlay';
import { Spine } from '@pixi-spine/all-4.1';
import { randomInt } from '@puzzles/core/math/random';
import { sound } from '@pixi/sound';

import jumpSound from './assets/sound/duck_jump.mp3?url';
import legWiggleSound from './assets/sound/duck_jump_legs_wiggle.mp3?url';
import teleportSound from './assets/sound/duck_teleport_001.mp3?url';
import introSound from './assets/sound/duck_intro_quack_013.mp3?url';
import sadSound0 from './assets/sound/duck_fail_quack_001.mp3?url';
import sadSound1 from './assets/sound/duck_fail_quack_002.mp3?url';
import fallSound0 from './assets/sound/duck_hit_wall_001.mp3?url';
import fallSound1 from './assets/sound/duck_hit_wall_005.mp3?url';

export type ChracterAnimationType = 'idle' | 'jump' | 'sad' | 'happy' | 'shock' | 'ride';
export type CharacterSkinType = 'f1' | 'helico' | 'moto' | 'water' | 'none';

@assets('CharacterView', {
  duckData: '/fraction/duck.json',
  jumpSound: jumpSound,
  legWiggleSound: legWiggleSound,
  teleportSound: teleportSound,
  introSound: introSound,
  sadSound0: sadSound0,
  sadSound1: sadSound1,
  fallSound0: fallSound0,
  fallSound1: fallSound1,
})
export class CharacterView extends EntityView {
  private duckContainer: Container = new Container();

  public get DuckContainer() {
    return this.duckContainer;
  }

  private body: Spine;
  private whiteFilter = new ColorOverlayFilter(0xffffff, 0.01);

  public onAnime?: (animation: ChracterAnimationType) => void;
  public onDestroy?: () => void;

  private jumpHeight = 20;
  private blinkCancellation?: CancellationTokenSource;

  constructor() {
    super();
    this.addChild(this.duckContainer);

    this.body = new Spine(Assets.get('duckData').spineData);
    this.body.scale.set(0.5);
    this.duckContainer.addChild(this.body);

    this.idle();
  }

  public async land() {
    sound.play('introSound');
    this.playAnimation('jump', true);
    await gsap.fromTo(this.duckContainer, { y: -1000 }, { y: 0, duration: 1, ease: 'power2.out' });
    this.idle();
  }

  public async jump(token: CancellationToken, playSound = true) {
    gsap.killTweensOf(this.duckContainer);

    await gsap.to(this.duckContainer.scale, { x: 1.3, y: 0.7, duration: 0.1 });
    gsap.to(this.duckContainer.scale, { x: 1, y: 1, duration: 0.15, ease: 'back.out(4)' });
    gsap.to(this.duckContainer, { y: -this.jumpHeight / this.scale.y, duration: 0.1 });
    this.playAnimation('jump', true);

    async function playJumpSound(){
      sound.play('jumpSound');
      await wait(200);
      sound.play('legWiggleSound', { loop: true, volume: 0.4 });
    }
    if(playSound) {
      playJumpSound();
    }
    await waitUntil(() => token.isCancelled);
    sound.stop('legWiggleSound');

    this.idle();
    await gsap.to(this.duckContainer, { y: 0, duration: 0.05 });
    await gsap.to(this.duckContainer.scale, { x: 1.2, y: 0.9, duration: 0.05 });
    await gsap.to(this.duckContainer.scale, { x: 1, y: 1, duration: 0.1 });
  }

  public async happy() {
    this.playAnimation('happy', false);
  }

  public sad() {
    this.playAnimation('sad', false);
    sound.play('sadSound'+ randomInt(0, 1).toString());
  }

  public idle() {
    this.playAnimation('idle', false);
  }

  public ride() {
    this.playAnimation('ride', false);
  }

  private playAnimation(animation: ChracterAnimationType, loop: boolean) {
    if (this.onAnime) this.onAnime(animation);

    sound.stop('legWiggleSound');
    this.body.state.setAnimation(0, animation, loop);
    if (animation === 'idle') {
      if (!this.blinkCancellation) {
        this.blinkCancellation = new CancellationTokenSource();
        this.blink(this.blinkCancellation.token);
      }
    } else {
      this.body.state.clearTrack(1);
      this.blinkCancellation?.cancel();
      this.blinkCancellation = undefined;
    }
  }

  private async blink(token: CancellationToken) {
    while (this.blinkCancellation) {
      if (token.isCancelled) return;
      await wait(randomInt(1500, 7000));
      for (let i = 0; i < randomInt(1, 2); i++) {
        if (token.isCancelled) return;
        this.body.state.setAnimation(1, 'blink', false);
        if (token.isCancelled) return;
        await wait(190);
      }
    }
  }

  public async teleportTo(pos: Vec2, rot: number) {
    this.filters = [this.whiteFilter];
    await wait(100);

    sound.play('teleportSound');
    gsap.to(this.duckContainer, { y: -45, duration: 0.1 });
    await wait(200);
    gsap.to(this.whiteFilter, { alpha: 1, duration: 0.1 });
    await gsap.to(this.duckContainer.scale, { x: 0, duration: 0.15 });

    this.x = pos.x;
    this.y = pos.y;
    this.rotation = rot;

    await wait(300);
    gsap.to(this.duckContainer.scale, { x: 1, duration: 0.25, ease: 'back.out(5)' });
    await gsap.to(this.whiteFilter, { alpha: 0, duration: 0.25 });
    await gsap.to(this.duckContainer, { y: 0, duration: 0.1 });
    this.filters = [];
  }

  public async fallDown() {
    gsap.killTweensOf(this.duckContainer);
    this.playAnimation('shock', false);
    sound.play('fallSound' + randomInt(0, 1).toString());
    this.parent.setChildIndex(this, this.parent.children.length - 1);

    gsap.to(this.duckContainer, { angle: '-=30', duration: 0.3, ease: 'power1.out' });
    await gsap.to(this.duckContainer, { x: '-=100', y: '-=80', duration: 0.4, ease: 'power1.out' });
    gsap.to(this.duckContainer, { angle: '-=60', duration: 1.5, ease: 'power1.in' });
    await gsap.to(this.duckContainer, { x: '-=200', y: 1000, duration: 1.5, ease: 'power1.in' });
  }

  destroy() {
    if (this.onDestroy) this.onDestroy();
    super.destroy();
  }

  setSkin(skin: CharacterSkinType) {
    if (skin === 'none') return;

    this.body.skeleton.setSkinByName(skin);
    this.body.skeleton.setSlotsToSetupPose();
  }
}
