import { add, toString as fractionToString, subtract } from '../core/math/fraction';
import {
  ActionData,
  BatteryData,
  calculateVehicleEnergy,
  DuckData,
  EntityData,
  EntityType,
  PortalData,
  PositionData,
  PuzzleData,
  RulerData,
  VehicleData,
} from './puzzle-data';
import { areEquivalent } from './ruler';

export type PuzzleState = Pick<PuzzleData, 'rulers'> & {
  entities: Entity[];
  actions: Action[];
  done: Action[];
  rules: Rule[];
  events: Event[];
};

export type Action = ActionData;
export type RuleType = 'charge' | 'teleport';
export type Rule = { type: RuleType; used: boolean };
export type Entity = Duck | Battery | Portal | Vehicle;
export type Duck = DuckData;
export type Battery = BatteryData & { used: boolean };
export type Vehicle = VehicleData & { energy: number; max: number };
export type Portal = PortalData & { used: boolean };

export type EventType = 'move' | 'charge' | 'teleport' | 'complete';
export type Event = MoveEvent | ChargeEvent | TeleportEvent | DoneEvent;
export type MoveEvent = {
  type: 'move';
  from: PositionData;
  to: PositionData;
  action?: ActionData;
};
export type ChargeEvent = {
  type: 'charge';
  energy: number;
  ruler: number;
  numerator: number;
  denominator: number;
};
export type TeleportEvent = {
  type: 'teleport';
  ruler: number;
  numerator: number;
  denominator: number;
};
export type DoneEvent = { type: 'complete' };

function ofType(e: Entity, type: EntityType): e is Duck | Battery | Portal | Vehicle {
  return e.type == type;
}

function inRuler(e: Entity, ruler?: number): boolean {
  return ruler === undefined || e.ruler == ruler;
}

export function selectEntity(state: PuzzleState, entity: EntityType, ruler?: number): Entity | undefined {
  return state.entities.find((e) => ofType(e, entity) && inRuler(e, ruler));
}

export function selectEntities(state: PuzzleState, entity: EntityType, predicate: (e: Entity) => boolean): Entity[] {
  return state.entities.filter((e) => ofType(e, entity) && predicate(e));
}

export function selectDuck(state: PuzzleState, ruler?: number): Duck | undefined {
  return selectEntity(state, 'duck', ruler) as Duck;
}

export function selectBattery(state: PuzzleState, ruler?: number): Battery {
  return selectEntity(state, 'battery', ruler) as Battery;
}

export function selectPortal(state: PuzzleState, ruler?: number): Portal {
  return selectEntity(state, 'portal', ruler) as Portal;
}

export function selectVehicle(state: PuzzleState, ruler?: number): Vehicle {
  return selectEntity(state, 'vehicle', ruler) as Vehicle;
}

export function selectOtherPortal(state: PuzzleState, other: Portal): Portal {
  return state.entities.find((e) => ofType(e, 'portal') && e !== other) as Portal;
}

export function selectRuler(state: PuzzleData, entity: EntityData): RulerData {
  return state.rulers[entity.ruler];
}

export function selectComplete(state: PuzzleState): boolean {
  var duck = selectDuck(state)!;
  var target = selectVehicle(state);

  if (target == undefined) throw new Error('No vehicle');
  if (duck == undefined) throw new Error('No duck');
  if (duck.ruler >= state.rulers.length) throw new Error('Invalid ruler for duck');

  var ruler = selectRuler(state, duck);

  return areEquivalent(duck, target, ruler.type) && duck.ruler == target.ruler && target.energy === target.max;
}

export function selectDistance(state: PuzzleState, a: Entity, b: Entity): number {
  if (!state.entities.includes(a) || !state.entities.includes(b)) throw new Error('Invalid entities');
  if (a.ruler >= state.rulers.length || b.ruler >= state.rulers.length)
    throw new Error('Invalid ruler for entity ' + JSON.stringify(a) + ' or ' + JSON.stringify(b));
  if (a.ruler == b.ruler) return Math.abs(a.numerator / a.denominator - b.numerator / b.denominator);
  else {
    var portalA = selectPortal(state, a.ruler);
    var portalB = selectPortal(state, b.ruler);
    if (!portalA || !portalB) throw new Error('No portal for ruler ' + a.ruler + ' or ' + b.ruler);
    var distanceAP = Math.abs(a.numerator / a.denominator - portalA.numerator / portalB.denominator);
    var distanceBP = Math.abs(b.numerator / b.denominator - portalB.numerator / portalB.denominator);
    return distanceAP + distanceBP;
  }
}

export function createState(data: PuzzleData): PuzzleState {
  return {
    rulers: data.rulers.map((ruler) => ({ ...ruler })),
    entities: data.entities.map((entity) => {
      switch (entity.type) {
        case 'duck':
          return { ...entity };
        case 'battery':
          return { ...entity, used: false };
        case 'portal':
          return { ...entity, used: false };
        case 'vehicle':
          const e = calculateVehicleEnergy(data);
          return {
            ...entity,
            energy: e.energy,
            max: e.max,
          };
      }
    }),
    actions: data.actions.map((action) => ({ ...action })),
    rules: [
      { type: 'charge', used: false },
      { type: 'teleport', used: false },
    ],
    done: [],
    events: [],
  };
}

export function cloneState(state: PuzzleState): PuzzleState {
  return {
    rulers: state.rulers.map((ruler) => ({ ...ruler })),
    entities: state.entities.map((entity) => ({ ...entity })),
    actions: state.actions.map((action) => ({ ...action })),
    rules: state.rules.map((rule) => ({ ...rule })),
    done: state.done.map((action) => ({ ...action })),
    events: state.events.map((event) => ({ ...event })),
  };
}

export function reducer(state: PuzzleState, action: Action): PuzzleState {
  const actionIndex = state.actions.indexOf(action);

  state = cloneState(state);
  state = applyAction(state, action);
  state = applyRules(state);

  state.actions.splice(actionIndex, 1);
  state.done.push(action);

  try {
    if (selectComplete(state)) {
      state.events.push({ type: 'complete' });
    }
  } catch (e) {}

  return state;
}

export function applyAction(state: PuzzleState, action: ActionData): PuzzleState {
  switch (action.type) {
    case 'move':
      var duck = selectDuck(state)!;
      var from = { ruler: duck.ruler, numerator: duck.numerator, denominator: duck.denominator };
      add(from, action, duck);
      var to = { ruler: duck.ruler, numerator: duck.numerator, denominator: duck.denominator };
      let newState: MoveEvent = {
        type: 'move',
        from: from,
        to: to,
        action: { ...action },
      };
      state.events.push(newState);
      break;
  }

  return state;
}

export function applyRules(state: PuzzleState): PuzzleState {
  for (var rule of state.rules) {
    switch (rule.type) {
      case 'charge':
        var duck = selectDuck(state)!;
        var ruler = selectRuler(state, duck);
        var batteries = selectEntities(
          state,
          'battery',
          (battery) =>
            duck.ruler == battery.ruler && areEquivalent(duck, battery, ruler.type) && !(battery as Battery).used
        );
        if (batteries.length > 0) {
          var battery = batteries[0] as Battery;
          var rocket = selectVehicle(state);
          battery.used = true;
          rocket.energy = Math.max(0, Math.min(rocket.energy + 1, rocket.max));
          rule.used = true;
          state.events.push({
            type: 'charge',
            energy: 1,
            ruler: battery.ruler,
            numerator: battery.numerator,
            denominator: battery.denominator,
          });
        }
        break;
      case 'teleport':
        var duck = selectDuck(state)!;
        var source = selectPortal(state, duck.ruler);
        var ruler = selectRuler(state, duck);

        if (source && areEquivalent(duck, source, ruler.type)) {
          var target = selectOtherPortal(state, source);
          duck.numerator = target.numerator;
          duck.denominator = target.denominator;
          duck.ruler = target.ruler;
          rule.used = true;
          target.used = true;
          source.used = true;
          state.events.push({
            type: 'teleport',
            ruler: duck.ruler,
            numerator: duck.numerator,
            denominator: duck.denominator,
          });
        }
        break;
    }
  }

  return state;
}

export function findAllSolutions(source: PuzzleState): PuzzleState[] {
  var results: PuzzleState[] = [];
  findAllSolutionsRecursive(source, results);
  return results;
}

export function findAllSolutionsRecursive(state: PuzzleState, solutions: PuzzleState[]) {
  if (selectComplete(state)) {
    solutions.push(state);
    return;
  }
  if (state.actions.length === 0) {
    return;
  }
  for (var action of state.actions) {
    findAllSolutionsRecursive(reducer(state, action), solutions);
  }
}

export function generateShortestPathToVehicle(state: PuzzleState): ActionData[] {
  var computeState = {
    ...cloneState(state),
    actions: [],
  };
  var result = generateShortestPathToVehicleRecursive(computeState);
  if (findAllSolutions(result).length == 0) throw new Error('No solution found');
  return result.done.filter((e) => e.type == 'move').map((e) => e as ActionData);
}

export function generateShortestPathToVehicleRecursive(state: PuzzleState): PuzzleState {
  if (selectComplete(state)) {
    return state;
  }
  var candidate = selectBestCandidate(state);
  if (candidate === undefined) {
    throw new Error('No candidate found');
  }
  var duck = selectDuck(state)!;
  var jump = subtract(candidate, duck);
  var action: ActionData = {
    type: 'move',
    numerator: jump.numerator,
    denominator: jump.denominator,
  };
  state.actions.push(action);
  return generateShortestPathToVehicleRecursive(reducer(state, action));
}

export function selectBestCandidate(state: PuzzleState): Entity | undefined {
  //const usedBatteries = selectEntities(state, 'battery', b => (b as Battery).used).length;
  //const batteries = selectEntities(state, 'battery', () => true).length;
  //const remaining = batteries - usedBatteries;
  const duck = selectDuck(state)!;
  const vehicle = selectVehicle(state);

  if (vehicle.energy == vehicle.max && duck.ruler == vehicle.ruler) return vehicle;
  var candidates = state.entities
    .filter(
      (e) =>
        e.type != 'duck' &&
        e.type != 'vehicle' &&
        e.ruler == duck.ruler &&
        (e.type != 'battery' || !(e as Battery).used)
    )
    .sort((a, b) => {
      if (a.type == 'battery' && b.type != 'battery') return -1;
      if (a.type != 'battery' && b.type == 'battery') return 1;
      return selectDistance(state, duck, a) > selectDistance(state, duck, b) ? 1 : -1;
    });
  return candidates.length > 0 ? candidates[0] : undefined;
}

export function calculateIntermediateJumps(state: PuzzleState): number {
  const solutions = findAllSolutions(state);
  let shortest = Number.MAX_SAFE_INTEGER;
  let shortestSolution = undefined;
  for (const s of solutions) {
    if (s.done.length < shortest) {
      shortest = s.done.length;
      shortestSolution = s;
    }
  }
  return shortestSolution?.events.filter((e) => e.type == 'move').length || 0;
}

export function dumpState(state: PuzzleState): string {
  return (
    state.rulers.map((r) => r.type + '(' + r.fractions + ')').join(' | ') +
    ' | ' +
    state.entities.map((e) => dumpEntity(e)).join(' | ') +
    ' | actions(' +
    state.actions.map((a) => fractionToString(a)).join(',') +
    ')' +
    ' |  done(' +
    state.done.map((a) => fractionToString(a)).join(',') +
    ')'
  );

  function dumpEntity(entity: Entity): string {
    switch (entity.type) {
      case 'duck':
        return 'duck(' + fractionToString(entity) + ', ' + entity.ruler + ')';
      case 'battery':
        return 'battery(' + fractionToString(entity) + ', ' + entity.ruler + ', ' + entity.used + ')';
      case 'portal':
        return 'portal(' + fractionToString(entity) + ', ' + entity.ruler + ', ' + entity.used + ')';
      case 'vehicle':
        return (
          'vehicle(' + fractionToString(entity) + ', ' + entity.ruler + ', ' + entity.energy + '/' + entity.max + ')'
        );
      default:
        throw new Error('Unknown entity type');
    }
  }
}
