import { Rescalable } from '@puzzles/core/pixi/display-scaler';
import { Container, ITextStyle, TextStyle } from 'pixi.js';
import { FractionView } from './fraction-view';
import { ActionData } from '../puzzle-data';
import gsap from 'gsap';

export class DashedFractionView extends Container implements Rescalable {
  protected fractionView: FractionView;

  constructor(
    action: ActionData,
    style: Partial<ITextStyle> | TextStyle = {
      fontFamily: 'Montserrat',
      fontSize: 30,
      fill: 0xffffff,
      align: 'justify',
      fontWeight: 'bold',
    }
  ) {
    super();

    this.fractionView = new FractionView(action, style, { showPositiveSymbol: true });
    this.fractionView.setMode(action.text ?? 'fraction');
    this.fractionView.scale.set(0);
    this.addChild(this.fractionView);
  }
  rescale(scaleFactor: number): void {
    this.scale.set(scaleFactor * 0.7);
  }

  public show() {
    gsap.to(this.fractionView.scale, { x: 1, y: 1, duration: 0.3, ease: 'back.out(1.7)' });
  }
}
