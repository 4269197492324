import { Container, Graphics } from 'pixi.js';
import { generateSubRects, Rect, Rectangle } from '../core/math/rectangle';
import { Rescalable } from '../core/pixi/display-scaler';
import { lerp } from '../core/math/interpolation';
import { ActionView } from './action-view';
import { ActionSlotView } from './action-slot-view';
import { SlotCollectionMap } from './slot-collection-map';
import { ActionMenuButtonView } from './action-menu-button-view';
import gsap from 'gsap';
import { ActionData } from './puzzle-data';

export type ActionMenuLayoutProps = {
  horizontalRect: Rect;
  horizontalAspectRatio: number;
  verticalRect: Rect;
};

export type ActionMenuStyleProps = {
  sourceFillColor: number;
  targetFillColor: number;
  sourceSlotColor: number;
  targetSlotColor: number;
  targetSlotLineWidth: number;
  slotColor: number;
  slotRadius: number;
  sourceScrewColor: number;
  targetScrewColor: number;
  screwRadius: number;
  screwOffset: number;
  lineColor: number;
  lineWidth: number;
  gameRectRadius: number;
};

const defaultActionMenuLayoutProps: ActionMenuLayoutProps = {
  horizontalRect: { x: 0, y: 0, width: 0.75, height: 1 },
  horizontalAspectRatio: 1 / 2,
  verticalRect: { x: 0, y: 0, width: 1, height: 0.6 },
};

const defaultActionMenuStyleProps: ActionMenuStyleProps = {
  sourceFillColor: 0xffbe18,
  sourceSlotColor: 0xf9a211,
  targetFillColor: 0xfba11b,
  targetSlotColor: 0xe1920c,
  slotColor: 0x1d9ce4,
  slotRadius: 12,
  sourceScrewColor: 0xfbb848,
  targetScrewColor: 0xffd569,
  screwRadius: 4,
  screwOffset: 10,
  lineColor: 0xffffff,
  lineWidth: 4,
  targetSlotLineWidth: 4,
  gameRectRadius: 0,
};

type ActionMenuProps = ActionMenuLayoutProps & ActionMenuStyleProps;

type CornerMask = {
  topLeft: boolean;
  topRight: boolean;
  bottomLeft: boolean;
  bottomRight: boolean;
};

const defaultCornerMask: CornerMask = {
  topLeft: true,
  topRight: true,
  bottomLeft: true,
  bottomRight: true,
};

export class ActionMenuView extends Container implements Rescalable {
  rect: Rect = { x: 0, y: 0, width: 0, height: 0 };
  props: ActionMenuProps;
  originalProps: ActionMenuProps;
  animatedProps: ActionMenuProps;
  actionButton: ActionMenuButtonView;
  slotCollectionMap: SlotCollectionMap<ActionSlotView, ActionView> = new SlotCollectionMap();
  public get sourceSlots() {
    return this.slotCollectionMap.getSlots('source');
  }
  public get targetSlots() {
    return this.slotCollectionMap.getSlots('target');
  }

  private graphics: Graphics = new Graphics();
  private hideSourceSlotGraphics = new Graphics();
  private cornerCircleGraphics = new Graphics();

  game: Rect = { x: 0, y: 0, width: 0, height: 0 };
  target: Rect = { x: 0, y: 0, width: 0, height: 0 };
  targetList: Rect = { x: 0, y: 0, width: 0, height: 0 };
  source: Rect = { x: 0, y: 0, width: 0, height: 0 };
  sourceList: Rect = { x: 0, y: 0, width: 0, height: 0 };

  constructor(props: Partial<ActionMenuProps> = {}) {
    super();
    this.props = { ...defaultActionMenuLayoutProps, ...defaultActionMenuStyleProps, ...props };
    this.originalProps = { ...this.props };
    this.animatedProps = { ...this.props };
    this.addChild(this.graphics);

    this.hideSourceSlotGraphics.zIndex = 1;
    this.hideSourceSlotGraphics.alpha = 0.8;
    this.addChild(this.hideSourceSlotGraphics);

    this.cornerCircleGraphics.zIndex = 2;
    this.addChild(this.cornerCircleGraphics);
    
    this.actionButton = new ActionMenuButtonView();
    this.actionButton.zIndex = 9;
    this.addChild(this.actionButton);

    this.sortableChildren = true;
  }

  refresh(actions: ActionData[] = [], targetCount: number): Iterable<ActionView> {
    for (var action of this.slotCollectionMap.getElements()) action.destroy();
    for (var tag of ['source', 'target'])
      if (this.slotCollectionMap.hasTag(tag))
        for (var slot of this.slotCollectionMap.getSlots(tag)) slot.slot.destroy();
    this.slotCollectionMap.clear();

    for (var tag of ['source', 'target']) {
      for (var i = 0; i < actions.length; i++) {
        if (tag === 'target' && i >= targetCount) break;
        var slotView = new ActionSlotView({
          lineColor: tag === 'source' ? 0 : 0xffffff,
          lineWidth: tag === 'source' ? 0 : this.props.targetSlotLineWidth,
          fillColor: tag == 'source' ? this.props.sourceSlotColor : this.props.targetSlotColor,
          radius: this.props.slotRadius,
        });
        this.slotCollectionMap.addSlot(tag, slotView);
        this.addChild(slotView);
      }

      this.sortChildren();
    }

    actions.forEach((action) => {
      const actionView = new ActionView({
        value: action,
        display: action.text ?? 'fraction',
      });
      this.addChild(actionView);
      this.slotCollectionMap.addElement('source', actionView);
    });
    return this.slotCollectionMap.getElements();
  }

  rescale(scaleFactor: number): void {
    this.props = { ...this.originalProps };
    this.props.lineWidth *= scaleFactor;
    this.props.screwRadius *= scaleFactor;
    this.props.screwOffset *= scaleFactor;
    this.props.slotRadius *= scaleFactor;
    this.props.gameRectRadius *= scaleFactor;
  }

  drawAnimation(rect: Rect = this.rect) {
    this.animatedProps = { ...this.props };
    this.animatedProps.verticalRect = { x: 0, y: 0, width: 1, height: 1 };
    this.animatedProps.horizontalRect = { x: 0, y: 0, width: 1, height: 1 };
    this.rect = rect;
    var value = { t: 0 };
    gsap.to(value, {
      duration: 10,
      t: 1,
      ease: 'back.out',
      onUpdate: () => {
        this.animatedProps.verticalRect.height = lerp(
          this.animatedProps.verticalRect.height,
          this.props.verticalRect.height,
          value.t
        );
        this.animatedProps.verticalRect.width = lerp(
          this.animatedProps.verticalRect.width,
          this.props.verticalRect.width,
          value.t
        );
        this.animatedProps.horizontalRect.height = lerp(
          this.animatedProps.horizontalRect.height,
          this.props.horizontalRect.height,
          value.t
        );
        this.animatedProps.horizontalRect.width = lerp(
          this.animatedProps.horizontalRect.width,
          this.props.verticalRect.width,
          value.t
        );
        this.draw(rect, this.animatedProps);
      },
    });
  }

  resize(rect: Rect) {
    this.rect = rect;
    this.game = this.getGameRect();
    this.target = this.getTargetRect();
    this.source = this.getSourceRect();
    this.actionButton.resize(this.getActionButtonRect());

    this.sourceList = this.getSourceActionsRect();
    this.targetList = this.getTargetActionRect();
    this.draw();
  }

  draw(rect: Rect = this.rect, props: ActionMenuProps = this.props) {
    const vertical = this.isVertical(rect);

    this.graphics.clear();
    this.graphics.lineStyle(0);

    this.graphics.beginFill(props.sourceFillColor);
    this.graphics.drawRect(this.target.x, this.target.y, rect.width, this.target.height);
    this.graphics.endFill();

    this.graphics.beginFill(props.targetFillColor);
    this.graphics.drawRect(this.source.x, this.source.y, this.source.width, this.source.height);
    this.graphics.drawPolygon(
      { x: this.target.x, y: this.source.y },
      { x: this.target.x, y: this.source.y - props.gameRectRadius * 0.5 },
      { x: this.target.x - props.gameRectRadius * 0.5, y: this.source.y }
    );
    this.graphics.endFill();

    this.graphics.lineStyle({ width: props.lineWidth, color: props.lineColor });
    this.graphics.beginFill(0xfffff, 0);
    this.graphics.drawRect(
      this.game.x - 100,
      this.game.y - 100,
      this.game.width + 100 + (vertical ? 100 : 0),
      this.game.height + 100 + (vertical ? 0 : 100)
    );
    this.graphics.endFill();

    const sourceMask = vertical ? { topLeft: false, topRight: false } : { topRight: false, bottomRight: false };
    const targetMask = vertical ? { bottomLeft: false, bottomRight: false } : {};

    this.cornerCircleGraphics.clear();
    this.drawCornerCircles(this.source, props.sourceScrewColor, sourceMask);
    this.drawCornerCircles(this.target, props.targetScrewColor, targetMask);

    let width = this.targetList.width;
    let height = this.targetList.height;
    const axis = vertical ? 'x' : 'y';
    const mode = 'center';
    let marginX = vertical ? -width * 0.015 : -width * 0.02;
    let marginY = vertical ? -height * 0.03 : -height * 0.015;

    let i = 0;
    let slots = this.targetSlots;
    let slotCount = slots.length;
    let aspect = vertical ? 2.1 / 2 : 3.4 / 2;

    for (const slotRect of generateSubRects(this.targetList, slotCount, mode, axis, aspect)) {
      const slot = slots[i];
      slotRect.pad(marginX, marginY);
      slot.slot.resize(slotRect);
      i++;
    }

    i = 0;
    slots = this.sourceSlots;
    slotCount = slots.length;
    width = this.sourceList.width;
    height = this.sourceList.height;
    marginX = vertical ? -width * 0.015 : -width * 0.08;
    aspect = vertical ? 2.1 / 2 : 2.1 / 2;

    this.hideSourceSlotGraphics.clear();
    this.hideSourceSlotGraphics.beginFill(props.targetFillColor);
    this.hideSourceSlotGraphics.drawRect(this.source.x + props.lineWidth, this.source.y, this.source.width - props.lineWidth, this.source.height);
    this.hideSourceSlotGraphics.endFill();

    for (const slotRect of generateSubRects(this.sourceList, slotCount, mode, axis, aspect)) {
      const slot = slots[i];
      slotRect.pad(marginX, marginY);
      slot.slot.resize(slotRect);
      i++;
    }

    for (const tag of ['source', 'target']) {
      for (const slot of this.slotCollectionMap.getSlots(tag)) {
        if (slot.element) {
          slot.element.resize(slot.slot.getBounds());
        }
      }
    }
  }

  isVertical(container: Rect = this.rect): boolean {
    return container.height > container.width;
  }

  getGameRect(container: Rect = this.rect, props: ActionMenuProps = this.props): Rect {
    return this.isVertical(container)
      ? {
          x: props.verticalRect.x,
          y: props.verticalRect.y,
          width: props.verticalRect.width * container.width,
          height: props.verticalRect.height * container.height,
        }
      : {
          x: 0,
          y: props.horizontalRect.y,
          width: Math.min(
            container.width * props.horizontalRect.width,
            container.width - container.height * props.horizontalAspectRatio
          ),
          height: container.height,
        };
  }

  getSourceRect(container: Rect = this.rect, props: ActionMenuProps = this.props): Rect {
    let horizontalWidth = container.height * props.horizontalAspectRatio;
    return this.isVertical(container)
      ? {
          x: 0,
          y: container.height - (container.height * (1 - props.verticalRect.height)) / 2,
          width: container.width,
          height: (container.height * (1 - props.verticalRect.height)) / 2,
        }
      : {
          x: Math.min(container.width * props.horizontalRect.width, container.width - horizontalWidth),
          y: 0,
          width: horizontalWidth * 0.38,
          height: container.height,
        };
  }

  getTargetRect(container: Rect = this.rect, props: ActionMenuProps = this.props): Rect {
    let horizontalWidth = Math.max(container.height * props.horizontalAspectRatio);
    return this.isVertical(container)
      ? {
          x: 0,
          y: container.height * props.verticalRect.height,
          width: container.width,
          height: (container.height * (1 - props.verticalRect.height)) / 2,
        }
      : {
          x: Math.min(
            container.width * props.horizontalRect.width + horizontalWidth * 0.38,
            container.width - horizontalWidth * 0.62
          ),
          y: 0,
          width: horizontalWidth * 0.62,
          height: container.height,
        };
  }

  getActionButtonRect(container: Rect = this.rect, targetRect: Rect = this.target): Rect {
    var rect = new Rectangle();

    if (this.isVertical(container)) {
      rect.height = targetRect.height * 0.55;
      rect.width = rect.height * 1.6;
      rect.x = targetRect.x + targetRect.width * 0.5 - rect.width * 0.5;
      rect.y = targetRect.y + targetRect.height - rect.height * 0.5;
    } else {
      rect.width = targetRect.width * 0.75;
      rect.height = rect.width * 0.6;
      rect.x = targetRect.x + targetRect.width * 0.5 - rect.width * 0.5 - 5;
      rect.y = targetRect.height - targetRect.height * 0.05 - rect.height;
    }
    return rect;
  }

  getTargetActionRect(
    container: Rect = this.rect,
    target: Rect = this.target,
    source: Rect = this.source,
    action: Rect = this.actionButton.rect
  ) {
    var v = this.isVertical(container);
    var rect = new Rectangle(
      target.x - (v ? 0 : 5),
      target.y + (v ? target.height * 0.05 : -target.height * 0.02),
      target.width - 5,
      target.height - (v ? action.height / 1.5 : action.height - target.height * 0.02)
    );
    rect.pad(-rect.width * (v ? 0.05 : 0.1), -rect.height * 0.1);
    return rect;
  }

  getSourceActionsRect(container: Rect = this.rect, source: Rect = this.source, action: Rect = this.actionButton.rect) {
    var v = this.isVertical(container);
    var rect = new Rectangle(
      source.x,
      source.y + (v ? action.height * 0.5 : -source.height * 0.02),
      source.width,
      source.height - (v ? action.height * 0.5 : action.height - source.height * 0.02)
    );
    rect.pad(-rect.width * (this.isVertical(container) ? 0.05 : 0.1), -rect.height * 0.1);
    return rect;
  }

  drawCornerCircles(rect: Rect, color: number, mask: Partial<CornerMask>) {
    mask = { ...defaultCornerMask, ...mask };
    const g = this.cornerCircleGraphics;
    const r = this.props.screwRadius;
    const o = this.props.screwOffset;
    g.lineStyle(0);
    g.beginFill(color);
    if (mask.topLeft) g.drawCircle(rect.x + o, rect.y + o, r);
    if (mask.topRight) g.drawCircle(rect.x + rect.width - o, rect.y + o, r);
    if (mask.bottomLeft) g.drawCircle(rect.x + o, rect.y + rect.height - o, r);
    if (mask.bottomRight) g.drawCircle(rect.x + rect.width - o, rect.y + rect.height - o, r);
    g.endFill();
  }

  public hideSourceSlots(hide: boolean) {
    gsap.killTweensOf(this.hideSourceSlotGraphics);
    if (hide) {
      this.hideSourceSlotGraphics.visible = true;
      gsap.to(this.hideSourceSlotGraphics, { alpha: 0.8, duration: 0.2 });
    } else {
      gsap.to(this.hideSourceSlotGraphics, {
        alpha: 0,
        duration: 0.2,
        onComplete: () => {
          this.hideSourceSlotGraphics.visible = false;
        },
      });
    }
  }
}

// Currently unused
// class PointBuffer {
//   points: Vec2[] = [];
//   max = 10;
//   constructor(max: number = 10) {
//     this.max = max;
//     for (var i = 0; i < max; i++) {
//       this.points.push({ x: 0, y: 0 });
//     }
//   }
//   push(point: Point) {
//     this.points.push(point);
//     this.points.shift();
//   }
//   get(index: number) {
//     return this.points[index];
//   }
//   getAverage() {
//     var sum = { x: 0, y: 0 };
//     for (var i = 0; i < this.max; i++) {
//       add(sum, this.points[i]);
//     }
//     return sum;
//   }
// }
