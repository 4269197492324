import { Container, Graphics, Sprite, Texture } from "pixi.js";
import { VehicleViewProps } from "./vehicle-view";
import { ColorOverlayFilter } from "@pixi/filter-color-overlay";
import { Emitter } from "@pixi/particle-emitter";
import { Vec2 } from "@puzzles/core/math/vector";
import { assets } from "@puzzles/core/asset-utility";
import { gsap } from "gsap";
import { sound } from "@pixi/sound";

import energyFullEmitterUrl from '../assets/vehicle/emitter-energy-full.json?url';
import energyFullEmitter from '../assets/vehicle/emitter-energy-full.json';
import chargeSound from '../assets/sound/rocket_recharge.mp3';
import chargeFullSound from '../assets/sound/rocket_recharge_full_001.mp3';

@assets('VehicleProgress', {
    energyFullEmitter: energyFullEmitterUrl,
    chargeSound: chargeSound,
    chargeFullSound: chargeFullSound,
  })

export class VehicleProgressView extends Container{
    private nowEnergy:number;
    private maxEnergy:number;
  
    private fullEmitter!: Emitter;
    private shadow!: Sprite;
    private shadowMask!: Graphics;
    private whiteFilter = new ColorOverlayFilter(0xffffff, 0.01);
    private vehicleContainer!: Container;

    private get isEnergyFull(): boolean { return this.nowEnergy >= this.maxEnergy; }

    constructor(data: VehicleViewProps, vehicleContainer: Container, texture: Texture, size: Vec2) {
        super();

        this.nowEnergy = data.energy;
        this.maxEnergy = data.max;

        if(this.isEnergyFull) return;
        this.vehicleContainer = vehicleContainer;
        
        this.shadow = new Sprite(texture);
        this.shadow.tint = 0x000000;
        this.shadow.alpha = 0.8;
        this.shadow.scale.set(0.5);
        this.shadow.anchor.set(0.5);
        this.addChild(this.shadow);
    
        this.shadowMask = new Graphics();
        this.shadowMask.beginFill(0xffffff);
        this.shadowMask.drawRect( -size.x/2, -size.y/2, size.x, size.y );
        this.shadowMask.endFill();
        this.addChild(this.shadowMask);

        this.fullEmitter = new Emitter(this, energyFullEmitter);
        this.fullEmitter.updateOwnerPos(0, -size.y / 2.2);
    
        this.shadow.mask = this.shadowMask  
        this.zIndex = 99;
    }

    public chargeEnergy(): void {
        this.nowEnergy++;
        const isFull = this.isEnergyFull;

        sound.play( isFull? 'chargeFullSound' : 'chargeSound', { volume: 0.7 });
        let height = this.shadowMask.getLocalBounds().height;
    
        gsap.killTweensOf(this.shadowMask);
        gsap.to(this.shadowMask, 
          { y: -height * (this.nowEnergy / this.maxEnergy), duration: 0.15 , onComplete: () => {
            if (this.isEnergyFull) this.onEnergyFull();
          }
        });
    }
    
    private onEnergyFull(): void{
        this.shadow.visible = this.shadowMask.visible = false;
        this.fullEmitter.emit = true;
        this.vehicleContainer.filters = [this.whiteFilter];
        gsap.timeline()
          .to(this.whiteFilter, { alpha: 0.9, duration: 0.05 })
          .to(this.whiteFilter, { alpha: 0.01, duration: 0.2, ease: 'power2.in', onComplete: () => { this.vehicleContainer.filters = []; } });
      }
}