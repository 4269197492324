import { FractionData } from '../core/math/fraction';

export type PuzzleData = {
  rulers: RulerData[];
  actions: ActionData[];
  entities: EntityData[];
};

export type RulerData = LineRulerData | SegmentRulerData | CircleRulerData;
export type RulerType = 'line' | 'segment' | 'circle';
export type RulerDisplayLevel = 'default' | 'empty' | 'itemFractions' | 'allFractions' | 'allParts';
export type RulerCommonData = { fractions: number; displayLevel?: RulerDisplayLevel; text?: RulerDisplayMode };
export type CircleRulerData = RulerCommonData & {
  type: 'circle';
  angleOffset?: number;
};

export type LineRulerData = RulerCommonData & {
  type: 'line';
  from: number;
  to: number;
};

export type SegmentRulerData = RulerCommonData & {
  type: 'segment';
  from?: number;
  to?: number;
};

export type RulerDisplayMode = FractionDisplayMode | 'mixed';
export type FractionDisplayMode = 'fraction' | 'decimal' | 'fractionMixed';

export type EntityData = DuckData | BatteryData | PortalData | VehicleData;
export type EntityType = 'duck' | 'battery' | 'portal' | 'vehicle';
export type PositionData = FractionData & { ruler: number };
export type DuckData = PositionData & { type: 'duck' };
export type BatteryData = PositionData & { type: 'battery' };
export type PortalData = PositionData & { type: 'portal' };
export type VehicleData = PositionData & { type: 'vehicle' };
export type ActionData = { type: 'move'; text?: FractionDisplayMode } & FractionData;

export function calculateVehicleEnergy(puzzle: PuzzleData): { energy: number; max: number } {
  var batteries = puzzle.entities.filter((e) => e.type == 'battery').length;
  var energy = batteries == 0 ? 1 : 0;
  var maxEnergy = batteries == 0 ? 1 : batteries;
  return { energy, max: maxEnergy };
}

export function isPuzzleData(obj: any): obj is PuzzleData {
  try {
    validateLevelData(obj);
    return true;
  } catch (e) {
    return false;
  }
}

export function validateLevelData(input: any): PuzzleData {
  if (!Array.isArray(input.rulers) || !Array.isArray(input.actions) || !Array.isArray(input.entities)) {
    throw new Error('Invalid puzzle data: rulers, actions, and entities must be arrays.');
  }

  for (const ruler of input.rulers) {
    if (
      (ruler.type !== 'circle' && ruler.type !== 'line' && ruler.type !== 'segment') ||
      ruler.fractions == null ||
      typeof ruler.fractions !== 'number'
    ) {
      throw new Error(`Invalid puzzle data: invalid ruler object: ${JSON.stringify(ruler)}.`);
    }

    if (ruler.type === 'circle') {
      if (ruler.angleOffset !== undefined && typeof ruler.angleOffset !== 'number') {
        throw new Error(`Invalid puzzle data: invalid circle ruler object: ${JSON.stringify(ruler)}.`);
      }
    } else if (ruler.type === 'line' || ruler.type === 'segment') {
      if (
        (ruler.text !== undefined &&
          ruler.text !== 'fraction' &&
          ruler.text !== 'decimal' &&
          ruler.text !== 'mixed' &&
          ruler.text !== 'fractionMixed') ||
        (ruler.from != undefined && typeof ruler.from !== 'number') ||
        (ruler.to != undefined && typeof ruler.to !== 'number')
      ) {
        throw new Error(`Invalid puzzle data: invalid line ruler object: ${JSON.stringify(ruler)}.`);
      }
    }
  }

  const entityTypes: EntityType[] = ['duck', 'battery', 'portal', 'vehicle'];
  for (const entity of input.entities) {
    if (
      !entityTypes.includes(entity.type) ||
      entity.ruler == null ||
      typeof entity.ruler !== 'number' ||
      entity.numerator == null ||
      typeof entity.numerator !== 'number' ||
      entity.denominator == null ||
      typeof entity.denominator !== 'number'
    ) {
      throw new Error(`Invalid puzzle data: invalid entity object: ${JSON.stringify(entity)}.`);
    }
  }

  for (const action of input.actions) {
    if (
      action.type !== 'move' ||
      action.numerator == null ||
      typeof action.numerator !== 'number' ||
      action.denominator == null ||
      typeof action.denominator !== 'number'
    ) {
      throw new Error(
        `Invalid puzzle data: invalid action object (type: 'move', numerator: number, denominator: number): ${JSON.stringify(
          action
        )}.`
      );
    }
    if (
      action.text !== undefined &&
      action.text !== 'fraction' &&
      action.text !== 'decimal' &&
      action.text !== 'fractionMixed'
    ) {
      throw new Error(
        `Invalid puzzle data: invalid action object text property (decimal, fraction or fractionMixed): ${JSON.stringify(
          action
        )}.`
      );
    }
  }

  if (input.entities.find((e: any) => e.type == 'vehicle') == undefined)
    throw new Error('Invalid puzzle data: no vehicle found.');
  if (input.entities.find((e: any) => e.type == 'duck') == undefined)
    throw new Error('Invalid puzzle data: no ducks found.');
  if (input.rulers.length == 0) throw new Error('Invalid puzzle data: no rulers found.');
  if (input.actions.length == 0) throw new Error('Invalid puzzle data: no actions found.');
  if (input.entities.length == 0) throw new Error('Invalid puzzle data: no entities found.');

  for (const entity of input.entities) {
    if (entity.ruler >= input.rulers.length) {
      throw new Error(`Invalid puzzle data: entity ruler index out of bounds: ${JSON.stringify(entity)}`);
    }
  }

  return input as PuzzleData;
}
