import { approximatelyEquals } from '../core/math/utility';
import {
  FractionData,
  add,
  compareFractions,
  enumerateFractions,
  isFractionData,
  subtract,
  upgrade,
} from '../core/math/fraction';
import { randomInt, randomItem, randomItems } from '../core/math/random';
import {
  CircleRulerData,
  EntityData,
  EntityType,
  FractionDisplayMode,
  LineRulerData,
  PositionData,
  PuzzleData,
  RulerData,
  RulerDisplayLevel,
  SegmentRulerData,
} from './puzzle-data';
import { createState, dumpState, findAllSolutions, selectRuler } from './puzzle-state';
import { areEquivalent } from './ruler';

export let debug = false;

type Range<T extends number | string> = Readonly<Array<T>>;
type RangeBoolean = Readonly<(false | true)[]>;
type RangeType<T> = T extends number | string
  ? Range<T>
  : T extends boolean
  ? RangeBoolean
  : T extends ReadonlyArray<infer U>
  ? RangeType<U>[]
  : T extends Record<string, any>
  ? RangedData<T>
  : never;

type RangedData<T> = {
  [K in keyof T]: RangeType<T[K]>;
};

export type PuzzleGenerationParametrizerProps = RangedData<
  Omit<PuzzleGenerationProps, 'rulers' | 'allowedJumps' | 'text'>
> & {
  fractions: number[];
  text?: FractionDisplayMode[];
  rulerCount: number[];
  rulers: (CircleRulerRangeData | LineRulerRangeData | SegmentRulerRangeData)[];
  allowedJumps: (FractionData[] | AllowedJumpMode)[];
};

export type CircleRulerRangeData = RangedData<Omit<CircleRulerData, 'type' | 'fractions' | 'displayLevel'>> &
  RangedData<Partial<Pick<CircleRulerData, 'fractions'>>> &
  Pick<CircleRulerData, 'type'> & { displayLevel: RulerDisplayLevel[] };

export type LineRulerRangeData = RangedData<Omit<LineRulerData, 'type' | 'text' | 'fractions' | 'displayLevel'>> &
  RangedData<Partial<Pick<LineRulerData, 'fractions'>>> &
  Pick<LineRulerData, 'type'> & { text?: FractionDisplayMode[]; displayLevel: RulerDisplayLevel[] };

export type SegmentRulerRangeData = RangedData<Omit<SegmentRulerData, 'type' | 'text' | 'fractions' | 'displayLevel'>> &
  RangedData<Partial<Pick<SegmentRulerData, 'fractions'>>> &
  Pick<SegmentRulerData, 'type'> & { text?: FractionDisplayMode[]; displayLevel: RulerDisplayLevel[] };

export function randomizeGenerationProps(props: PuzzleGenerationParametrizerProps): PuzzleGenerationProps {
  const rulers = randomizeRulers(props);
  let positions = [...generateInitialPositions(rulers)].length - 1 - 2; // duck and vehicle
  let batteries = randomItem(props.batteries);

  batteries = batteries > positions ? positions : batteries;
  positions -= batteries;

  let intermediate = randomItem(props.intermediateJumps);

  intermediate = intermediate > positions ? positions : intermediate;
  positions -= intermediate;

  return {
    rulers: rulers,
    batteries: batteries,
    startAtZero: randomItem(props.startAtZero),
    negativeJumps: randomItem(props.negativeJumps),
    intermediateJumps: intermediate,
    superfluousJumps: randomItem(props.superfluousJumps),
    allowedJumps: randomItem(props.allowedJumps),
    // reduceJumps: randomItem(props.reduceJumps),
    slots: props.slots ? randomItem(props.slots) : undefined,
    text: props.text ? randomItem(props.text) : 'fraction',
  };
}

function randomizeRulers(props: PuzzleGenerationParametrizerProps): RulerData[] {
  return [
    ...randomItems(
      props.rulers.map((r) => {
        switch (r.type) {
          case 'line':
            const line = r as LineRulerRangeData;
            return {
              type: r.type,
              fractions: randomItem(r.fractions ? r.fractions : props.fractions),
              from: randomItem(line.from),
              to: randomItem(line.to),
              text: line.text ? randomItem(line.text) : undefined,
              displayLevel: line.displayLevel ? randomItem(line.displayLevel) : 'default',
            };
          case 'segment':
            const segment = r as SegmentRulerRangeData;
            return {
              type: r.type,
              fractions: randomItem(r.fractions ? r.fractions : props.fractions),
              from: segment.from ? randomItem(segment.from) : 0,
              to: segment.to ? randomItem(segment.to) : 1,
              text: segment.text ? randomItem(segment.text) : undefined,
              displayLevel: segment.displayLevel ? randomItem(segment.displayLevel) : 'default',
            };
          case 'circle':
            const circle = r as CircleRulerRangeData;
            return {
              type: r.type,
              fractions: randomItem(r.fractions ? r.fractions : props.fractions),
              angleOffset: circle.angleOffset ? randomItem(circle.angleOffset) : 0,
              displayLevel: circle.displayLevel ? randomItem(circle.displayLevel) : 'default',
            };
        }
      }),
      randomItem(props.rulerCount)
    ),
  ];
}

export type AllowedJumpMode = 'unit' | 'regular' | 'improper';
export type PuzzleGenerationProps = {
  rulers: RulerData[];
  batteries: number;
  startAtZero: boolean;
  negativeJumps: boolean;
  intermediateJumps: number;
  superfluousJumps: number;
  allowedJumps: FractionData[] | AllowedJumpMode;
  // disable reduceJump because it's confusing
  // reduceJumps: boolean;
  slots?: number;
  text?: FractionDisplayMode;
};

export type ActionType = EntityType | 'jump';

export type PositionConstraint = (space: PuzzleQuanticState, position: PositionData, action: ActionType) => boolean;
export type ActionConstraint = (space: PuzzleQuanticState, type: ActionType) => boolean;

function samePosition(p1: PositionData, p2: PositionData) {
  return p1.ruler == p2.ruler && p1.numerator == p2.numerator && p1.denominator == p2.denominator;
}

export function collapsePositions(state: PuzzleQuanticState, position: PositionData) {
  const ruler = state.result.rulers[position.ruler];
  state.itemPositions = state.itemPositions.filter((p) => {
    if (samePosition(p, position)) return false;
    if (p.ruler == position.ruler && areEquivalent(p, position, ruler.type)) return false;
    return true;
  });
  return state;
}

export const actions: ActionType[] = ['jump', 'battery', 'vehicle', 'portal', 'duck'];

export type PuzzleQuanticState = {
  result: PuzzleData;
  current: EntityData | undefined;
  previous: EntityData | undefined;
  props: PuzzleGenerationProps;
  actions: Map<ActionType, number>;
  itemPositions: PositionData[];
  positions: PositionData[];
  positionConstraints: PositionConstraint[];
  actionConstraints: ActionConstraint[];
};

export function generateSafely(generationData: PuzzleGenerationProps | PuzzleGenerationParametrizerProps): PuzzleData {
  let level: PuzzleData;
  while (true) {
    try {
      level = generate(generationData);
      break; // Break the loop if generation is successful
    } catch (e) {
      console.log('failed to generate puzzle, retrying');
    }
  }

  return level;
}

export function generate(generationData: PuzzleGenerationProps | PuzzleGenerationParametrizerProps): PuzzleData {
  const data = isProceduralLevel(generationData) ? generationData : randomizeGenerationProps(generationData);
  let state = collapse(generateInitialSpace(data), 'duck');
  do {
    state = collapse(state, selectAction(state));
  } while (selectRequiredPositions(state) > 0 && state.itemPositions.length > 0);

  if (findAllSolutions(createState(state.result)).length == 0)
    throw new Error('No solution for generated puzzle: ' + dumpState(createState(state.result)));

  if (data.superfluousJumps > 0) {
    for (var superfluousAction of generateSuperfluousActions(state, data.superfluousJumps)) {
      state.result.actions.push(superfluousAction);
    }
  }

  console.log('level generated!');
  return state.result;
}

const superfluousDenominators = [2, 3, 4, 5, 6];
export function* generateSuperfluousActions(state: PuzzleQuanticState, count: number) {
  const actions = [...randomItems(state.result.actions, count, true)];
  const existingDenominators = state.result.actions.map((a) => a.denominator);
  let newDenominators = superfluousDenominators.filter((d) => !existingDenominators.includes(d));
  if (newDenominators.length == 0) newDenominators = superfluousDenominators;
  for (var action of actions) {
    yield {
      ...action,
      denominator: randomItem(newDenominators),
      text: state.props.text,
    };
  }
}

export function generateInitialSpace(props: PuzzleGenerationProps) {
  if (props.rulers.length == 0) throw new Error('No rulers defined');

  let state: PuzzleQuanticState = {
    result: {
      rulers: props.rulers.map((ruler) => {
        return {
          ...ruler,
          text: ruler.text ? ruler.text : props.text,
        };
      }),
      entities: [],
      actions: [],
    },
    current: undefined,
    previous: undefined,
    props: props,
    actions: new Map<ActionType, number>(),
    itemPositions: [],
    positions: [],
    positionConstraints: [constrainPlayerToRulerZero, constrainJumpsToSameRulerExceptPortal],
    actionConstraints: [
      constrainFromPortalToPortal,
      constrainVehicleToLast,
      constrainJumpToTooSmallRuler,
      constrainPortalBeforeRunOut,
    ],
  };

  if (!Array.isArray(props.allowedJumps) || props.allowedJumps.length > 0) {
    state.positionConstraints.push(constrainJumpDistance);
    // for now the allowed jump distances work only with positive jumps
    // the computation of positive and negative jumps requires a lot more work to detect cul de sacs
    if (props.allowedJumps == 'unit') props.negativeJumps = false;
  }
  if (props.negativeJumps == false) state.positionConstraints.push(constrainNegativeJumps);
  if (props.startAtZero) state.positionConstraints.push(constrainSourceToZero);

  for (var initialPosition of generateInitialPositions(props.rulers)) {
    state.itemPositions.push(initialPosition);
    state.positions.push(initialPosition);
  }

  const portals = getRequiredPortals(props.rulers.length);
  const mandatorySlots = calculateMandatorySlots(portals); // duck to spaceship
  let batteries = props.batteries;
  let intermediateJumps = props.intermediateJumps;
  let targetSlots = calculateTargetSlots(batteries, intermediateJumps, portals);

  if (props.slots !== undefined) {
    let slots = props.slots;
    if (slots < mandatorySlots) {
      slots = mandatorySlots;
      console.log('upscaling generation slots prop to fit mandatory slots');
    }

    if (slots > 8) {
      slots = 8;
      console.log(
        'downscaling generation slots prop to fit maximum allowed slots (3 batteries + 3 intermediate + 2 portals)'
      );
    }

    if (slots < targetSlots) {
      const diff = targetSlots - slots;
      intermediateJumps = Math.max(0, intermediateJumps - diff);
      targetSlots = calculateTargetSlots(batteries, intermediateJumps, portals);
      console.log('downscaling generation intermediateJumps prop to fit required slots');
    }

    if (slots < targetSlots) {
      const diff = targetSlots - slots;
      batteries = Math.max(0, batteries - diff);
      targetSlots = calculateTargetSlots(batteries, intermediateJumps, portals);
      console.log('downscaling generation batteries prop to fit required slots');
    }

    if (slots > targetSlots) {
      let diff = slots - targetSlots;
      batteries = Math.min(batteries + diff, 3);
      targetSlots = calculateTargetSlots(batteries, intermediateJumps, portals);
      console.log('upscaling generation batteries prop to fit required slots');
    }

    if (slots > targetSlots) {
      let diff = slots - targetSlots;
      intermediateJumps = Math.min(intermediateJumps + diff, 3);
      targetSlots = calculateTargetSlots(batteries, intermediateJumps, portals);
      console.log('upscaling generation intermediateJumps prop to fit required slots');
    }
  }

  state.actions.set('duck', 1);
  state.actions.set('jump', intermediateJumps);
  state.actions.set('vehicle', 1);
  state.actions.set('battery', batteries);
  state.actions.set('portal', portals);

  if (state.itemPositions.length < selectRequiredPositions(state))
    throw new Error('Not enough positions to start puzzle generation');

  return state;
}

export function calculateMandatorySlots(portals: number): number {
  return 1 + Math.max(portals - 1, 0);
}

export function calculateTargetSlots(batteries: number, intermediateJumps: number, portals: number): number {
  return 1 + batteries + intermediateJumps + Math.max(0, portals - 1);
}

export function getRequiredPortals(rulers: number): number {
  return rulers == 1 ? 0 : rulers == 2 ? 2 : rulers + 1;
}

function* generateInitialPositions(rulers: RulerData[]) {
  let rulerIndex = 0;
  for (var ruler of rulers) {
    const rulerFrom =
      ruler.type == 'line' || ruler.type == 'segment'
        ? ruler.from !== undefined
          ? ruler.from * ruler.fractions
          : 0
        : 0;
    const rulerTo =
      ruler.type == 'line' || ruler.type == 'segment'
        ? ruler.to !== undefined
          ? ruler.to * ruler.fractions
          : ruler.fractions
        : ruler.fractions;

    for (var fraction of enumerateFractions(ruler.fractions, rulerFrom, rulerTo)) {
      if (ruler.type == 'circle' && fraction.numerator == ruler.fractions) continue;
      yield {
        ruler: rulerIndex,
        numerator: fraction.numerator,
        denominator: fraction.denominator,
      };
    }
    rulerIndex++;
  }
}

export function selectRequiredPositions(space: PuzzleQuanticState) {
  return [...space.actions.values()].reduce((a, b) => a + b);
}

export function selectAction(state: PuzzleQuanticState): ActionType {
  let fails: any[] = [];
  let pool = actions.filter(
    (e) =>
      state.actions.get(e as ActionType)! > 0 &&
      state.actionConstraints.every((c) => {
        var result = c(state, e);
        if (!result && debug)
          fails.push({
            constrain: c.name,
            action: e,
            state: state,
          });
        return result;
      })
  );
  if (pool.length == 0) {
    if (debug) {
      console.log('failed action constraints');
      console.dir(fails);
    }
    throw new Error('No valid entity types');
  }
  return pool[randomInt(0, pool.length - 1)];
}

export function selectCurrent(state: PuzzleQuanticState) {
  if (state.current === undefined) return undefined;
  var ruler = selectRuler(state.result, state.current);
  var current = state.current!;
  var entities = state.result.entities.filter((e) => areEquivalent(current, e, ruler.type));
  return entities.length == 0 ? current : entities.at(-1);
}

export function selectPrevious(state: PuzzleQuanticState) {
  if (state.previous === undefined) return undefined;
  var previous = state.previous;
  var ruler = selectRuler(state.result, previous);
  var entities = state.result.entities.filter((e) => areEquivalent(previous, e, ruler.type));
  return entities.length == 0 ? previous : entities.at(-1);
}

export function selectPositionPool(state: PuzzleQuanticState, action: ActionType): PositionData[] {
  var fails: any[] = [];
  let pool = state.itemPositions.filter((p) => {
    return state.positionConstraints.every((c) => {
      var result = c(state, p, action);
      if (!result && debug) {
        fails.push({
          constraint: c.name,
          position: p,
          action: action,
          state: state,
        });
      }
      return result;
    });
  });
  if (pool.length == 0) {
    if (debug) {
      console.log(
        'No valid positions for action ' +
          action +
          ', in current ' +
          selectCurrent(state)?.numerator +
          '/' +
          selectCurrent(state)?.denominator +
          ', ruler ' +
          selectCurrent(state)?.ruler
      );
      console.dir(state);
      console.dir(fails);
    }
  }
  return pool;
}

export function selectPosition(state: PuzzleQuanticState, action: ActionType): PositionData {
  const pool = selectPositionPool(state, action);
  if (pool.length == 0) throw new Error('No valid positions');
  return randomItem(pool);
}

export function collapse(state: PuzzleQuanticState, action: ActionType) {
  let position = selectPosition(state, action);
  let actionCount = state.actions.get(action)!;

  state.actions.set(action, actionCount - 1);

  let actionItem: FractionData | undefined = undefined;

  switch (action) {
    case 'duck':
      state.result.entities.push({ type: action, ...position });
      state.current = { type: 'duck', ...position };
      break;

    case 'jump':
      const current = selectCurrent(state)!;
      state.previous = { ...current };
      state.current = { type: 'duck', ...position };

      actionItem = subtract(position, current);
      break;

    case 'battery':
    case 'vehicle':
    case 'portal':
      {
        const current = selectCurrent(state)!;
        const entity = { type: action, ...position };
        state.result.entities.push(entity);
        state.previous = { ...current };
        state.current = { ...entity };

        const moveRequired = current.type != 'portal' || action != 'portal';
        if (moveRequired) {
          actionItem = subtract(position, current);
        }
      }

      break;
  }

  if (actionItem !== undefined) {
    if (Array.isArray(state.props.allowedJumps)) {
      const fraction = actionItem.numerator / actionItem.denominator;
      const targets = state.props.allowedJumps.filter((dt) =>
        approximatelyEquals(Math.abs(dt.numerator / dt.denominator), Math.abs(fraction), 0.01)
      );
      if (targets.length > 0) {
        let target = randomItem(targets);
        actionItem.numerator = Math.abs(target.numerator) * (fraction < 0 ? -1 : 1);
        actionItem.denominator = Math.abs(target.denominator);
      }
    } else {
      const actionFromFractions = state.result.rulers[state.previous!.ruler].fractions;
      if (actionItem.denominator < actionFromFractions) {
        upgrade(actionItem, actionFromFractions, actionItem);
      }
    }
    state.result.actions.push({ type: 'move', ...actionItem, text: state.props.text });
  }

  return collapsePositions(state, position);
}

export const constrainSourceToZero: PositionConstraint = (
  state: PuzzleQuanticState,
  position: PositionData,
  candidate: ActionType
) => {
  if (candidate != 'duck') return true;
  return position.numerator == state.itemPositions[0].numerator;
};

export const constrainNegativeJumps: PositionConstraint = (
  state: PuzzleQuanticState,
  position: PositionData,
  candidate: ActionType
) => {
  const positions = state.itemPositions;
  const current = selectCurrent(state) ? selectCurrent(state)! : positions[0];
  const teleport =
    selectCurrent(state) !== undefined && selectCurrent(state)!.type == 'portal' && candidate == 'portal';
  const targetRuler = teleport ? current.ruler + 1 : current.ruler;
  const fractions = state.result.rulers[targetRuler].fractions;
  const from = teleport ? positions.find((p) => p.ruler == targetRuler)! : current;
  const last = positions.reduce((acc, cur) => {
    if (cur.ruler == targetRuler && compareFractions(cur, acc) > 0) return cur;
    return acc;
  }, from);

  // for now p.ruler > targetRuler is enough because we don't support coming back to previous rulers
  const availablePositionsInOtherRulers = positions.filter((p) => p.ruler > targetRuler).length;
  const availablePositionsInTargetRuler = positions.filter((p) => p.ruler == targetRuler).length;
  const availablePositions = availablePositionsInOtherRulers + availablePositionsInTargetRuler;
  const requiredPositions = selectRequiredPositions(state);

  if (availablePositions < requiredPositions)
    throw new Error(
      'Not enough positions to generate puzzle with negative jumps: ' +
        availablePositionsInTargetRuler +
        '<' +
        requiredPositions
    );

  const requiredSpace = { numerator: requiredPositions - availablePositionsInOtherRulers - 1, denominator: fractions };
  const to = subtract(last, requiredSpace);

  return candidate == 'duck' || teleport
    ? compareFractions(from, position) <= 0 && compareFractions(position, to) <= 0
    : compareFractions(from, position) < 0 && compareFractions(position, to) <= 0;
};

export const constrainPlayerToRulerZero: PositionConstraint = (
  state: PuzzleQuanticState,
  position: PositionData,
  candidate: ActionType
) => {
  if (candidate != 'duck') return true;
  return position.ruler == 0;
};

export const constrainZeroJump: PositionConstraint = (
  state: PuzzleQuanticState,
  position: PositionData,
  candidate: ActionType
) => {
  if (candidate == 'duck') return true;
  const current = selectCurrent(state)!;
  const ruler = state.result.rulers[current.ruler];
  const jump = upgrade(subtract(position, current), ruler.fractions);
  return jump.numerator > 0;
};

export const constrainJumpsToSameRulerExceptPortal: PositionConstraint = (
  state: PuzzleQuanticState,
  position: PositionData,
  candidate: ActionType
) => {
  const current = selectCurrent(state)!;
  if (!current) return true;
  if (current.type != 'portal') return position.ruler == current.ruler;
  if (current.type == 'portal' && candidate == 'portal') return position.ruler != current.ruler;
  return position.ruler == current.ruler;
};

export const constrainJumpDistance: PositionConstraint = (
  state: PuzzleQuanticState,
  position: PositionData,
  candidate: ActionType
) => {
  const current = selectCurrent(state)!;
  if (!current) return true;
  if (selectCurrent(state)!.type == 'portal' && candidate == 'portal') return true;
  return validJumpDistance(state.props.allowedJumps, state.result.rulers, selectCurrent(state)!, position);
};

export function validJumpDistance(
  allowedJumps: FractionData[] | AllowedJumpMode,
  rulers: RulerData[],
  current: PositionData,
  position: PositionData
): boolean {
  if (current.ruler != position.ruler) return false;
  const ruler = rulers[current.ruler];
  const jump = upgrade(subtract(position, current), ruler.fractions);
  if (Array.isArray(allowedJumps)) {
    return (
      allowedJumps.length == 0 ||
      allowedJumps.some(
        (jump) =>
          areEquivalent(add(current, jump), position, ruler.type) ||
          areEquivalent(subtract(current, jump), position, ruler.type)
      )
    );
  }
  return allowedJumps == 'unit'
    ? jump.numerator == 1
    : allowedJumps == 'regular'
    ? Math.abs(jump.numerator) <= ruler.fractions
    : allowedJumps == 'improper'
    ? Math.abs(jump.numerator) <= ruler.fractions * 2
    : true;
}

export const constrainFromPortalToPortal: ActionConstraint = (state: PuzzleQuanticState, type: ActionType) => {
  if (type == 'duck') return true;
  const current = selectCurrent(state)!;
  const previous = selectPrevious(state);
  if (current.type != 'portal') return true;
  if (current.type == 'portal' && (previous == undefined || previous.type != 'portal')) return type == 'portal';
  return true;
};

export const constrainVehicleToLast: ActionConstraint = (state: PuzzleQuanticState, type: ActionType) => {
  const remaining = selectRequiredPositions(state);
  return type != 'vehicle' || remaining == 1;
};

export const constrainJumpToTooSmallRuler: ActionConstraint = (state: PuzzleQuanticState, type: ActionType) => {
  const current = selectCurrent(state);
  if (current == undefined) return true;
  if (type !== 'portal' || current.type == 'portal') return true;

  const requiredPositions = selectRequiredPositions(state) - 1;
  const otherRulerPositions = state.itemPositions.filter((p) => p.ruler != current.ruler).length;
  if (requiredPositions > otherRulerPositions) return false;
  return true;
};

export const constrainPortalBeforeRunOut: ActionConstraint = (state: PuzzleQuanticState, type: ActionType) => {
  const remainingPortals = state.actions.has('portal') ? state.actions.get('portal') : 0;
  const ruler = selectCurrent(state) ? selectCurrent(state)!.ruler : 0;
  if (remainingPortals == 0) return true;
  const availablePositions = state.itemPositions.filter((p) => p.ruler == ruler).length;
  if (availablePositions == 1) return type == 'portal';
  return true;
};

export function isProceduralLevel(props: any): props is PuzzleGenerationProps {
  return (
    'rulers' in props &&
    Array.isArray(props.rulers) &&
    'batteries' in props &&
    typeof props.batteries == 'number' &&
    'startAtZero' in props &&
    typeof props.startAtZero == 'boolean' &&
    'negativeJumps' in props &&
    typeof props.negativeJumps == 'boolean' &&
    'intermediateJumps' in props &&
    typeof props.intermediateJumps == 'number' &&
    'superfluousJumps' in props &&
    typeof props.superfluousJumps == 'number' &&
    'allowedJumps' in props &&
    (Array.isArray(props.allowedJumps) ||
      props.allowedJumps == 'unit' ||
      props.allowedJumps == 'regular' ||
      props.allowedJumps == 'improper')
    //   && 'reduceJumps' in props &&
    // typeof props.reduceJumps == 'boolean'
  );
}

export function isProceduralRamdomizeLevel(obj: any): obj is PuzzleGenerationParametrizerProps {
  try {
    validateProceduralRanomizeLevelData(obj);
    return true;
  } catch (e) {
    return false;
  }
}

export function validateProceduralLevelData(input: any): PuzzleGenerationProps {
  if (!Array.isArray(input.rulers)) {
    throw new Error('Invalid puzzle data: rulers entity must be an array.');
  }

  if (input.allowedJumps === undefined) {
    throw new Error('Invalid puzzle data: allowedJumps must be defined');
  } else if (Array.isArray(input.allowedJumps)) {
    if (!input.allowedJumps.every((jump: any) => isFractionData(jump))) {
      throw new Error(
        'Invalid puzzle data: allowedJumps must be a list of fractions or a string (regular, improper, unit)'
      );
    }
  } else if (
    typeof input.allowedJumps !== 'string' ||
    (input.allowedJumps !== 'regular' && input.allowedJumps !== 'improper' && input.allowedJumps !== 'unit')
  ) {
    throw new Error(
      'Invalid puzzle data: allowedJumps must be a list of fractions or a string (regular, improper, unit)'
    );
  }

  for (const ruler of input.rulers) {
    if (
      (ruler.type !== 'circle' && ruler.type !== 'line' && ruler.type !== 'segment') ||
      ruler.fractions == null ||
      typeof ruler.fractions !== 'number'
    ) {
      throw new Error(`Invalid puzzle data: invalid ruler object: ${JSON.stringify(ruler)}.`);
    }

    if (ruler.type === 'circle') {
      if (ruler.angleOffset !== undefined && typeof ruler.angleOffset !== 'number') {
        throw new Error(`Invalid puzzle data: angleOffset must of a number: ${JSON.stringify(ruler)}.`);
      }
    } else if (ruler.type === 'line' || ruler.type === 'segment') {
      if (
        (ruler.text !== undefined &&
          ruler.text !== 'fraction' &&
          ruler.text !== 'decimal' &&
          ruler.text !== 'mixed' &&
          ruler.text !== 'fractionMixed') ||
        ruler.from == null ||
        typeof ruler.from !== 'number' ||
        ruler.to == null ||
        typeof ruler.to !== 'number'
      ) {
        throw new Error(`Invalid puzzle data: invalid line ruler object: ${JSON.stringify(ruler)}.`);
      }
    }
  }
  return input as PuzzleGenerationProps;
}

export function validateProceduralRanomizeLevelData(input: any): PuzzleGenerationParametrizerProps {
  if (input.rulerCount == null) {
    throw new Error('no ruler count list specified');
  }
  if (!Array.isArray(input.rulerCount)) {
    throw new Error('ruler count must be a list of numbers');
  }

  if (!Array.isArray(input.rulers)) {
    throw new Error('rulers entity must be an array.');
  }

  let fractionsGloballyDefined = false;
  if (input.fractions) {
    fractionsGloballyDefined = true;
    if (!Array.isArray(input.fractions)) {
      throw new Error('fractions must be a list of numbers');
    }
  }

  if (input.batteries === undefined) {
    throw new Error('batteries must be defined');
  }

  if (!Array.isArray(input.batteries)) {
    throw new Error('batteries must be a list of numbers');
  }

  if (input.startAtZero === undefined) {
    throw new Error('startAtZero must be defined');
  }

  if (!Array.isArray(input.startAtZero)) {
    throw new Error('startAtZero must be a list of boolean');
  }

  if (input.negativeJumps === undefined) {
    throw new Error('negativeJumps must be defined');
  }

  if (!Array.isArray(input.negativeJumps)) {
    throw new Error('negativeJumps must be a list of boolean');
  }

  if (input.intermediateJumps === undefined) {
    throw new Error('intermediateJumps must be defined');
  }

  if (!Array.isArray(input.intermediateJumps)) {
    throw new Error('intermediateJumps must be a list of numbers');
  }

  if (input.superfluousJumps === undefined) {
    throw new Error('superfluousJumps must be defined');
  }

  if (!Array.isArray(input.superfluousJumps)) {
    throw new Error('superfluousJumps must be a list of numbers');
  }

  if (input.text !== undefined && !Array.isArray(input.text)) {
    throw new Error('text must be a list of strings: decimal, fraction or fractionMixed');
  }

  for (const ruler of input.rulers) {
    if (ruler.type !== 'circle' && ruler.type !== 'line' && ruler.type !== 'segment') {
      throw new Error(`invalid ruler object: ${JSON.stringify(ruler)}.`);
    }
    if (!fractionsGloballyDefined) {
      if (ruler.fractions === undefined) {
        throw new Error(`ruler does not define fractions property: ${JSON.stringify(ruler)}.`);
      }
    }
    if (ruler.type === 'line') {
      if (ruler.from === undefined || ruler.to === undefined) {
        throw new Error(`ruler does not define from and to properties: ${JSON.stringify(ruler)}.`);
      }
    }
  }
  return input as PuzzleGenerationParametrizerProps;
}
