import { clamp01 } from './utility';

export type RgbColor = { r: number; g: number; b: number };
export type RgbaColor = RgbColor & { a: number };

export function parseColorToNumber(color: string) {
  return parseInt(color.replace('#', ''), 16);
}

export function colorToRGBA(color: number) {
  return {
    r: (color >> 24) & 0xff,
    g: (color >> 16) & 0xff,
    b: (color >> 8) & 0xff,
    a: color & 0xff,
  };
}

export function rgbToNumber(r: number, g: number, b: number): number {
  return ((r << 16) | (g << 8) | b) >>> 0;
}

export function rgbaToNumber(r: number, g: number, b: number, a: number): number {
  const alpha = Math.round(a);
  return ((r << 24) | (g << 16) | (b << 8) | alpha) >>> 0;
}

export function colorLerp(color1: number, color2: number, t: number): number {
  t = clamp01(t);
  const c1 = colorToRGBA(color1);
  const c2 = colorToRGBA(color2);
  const r = Math.round(c1.r + (c2.r - c1.r) * t);
  const g = Math.round(c1.g + (c2.g - c1.g) * t);
  const b = Math.round(c1.b + (c2.b - c1.b) * t);
  const a = Math.round(c1.a + (c2.a - c1.a) * t);

  return rgbaToNumber(r, g, b, a);
}

export function toRgbHex(color: number): string {
  const hexString = color.toString(16);
  return `#${'000000'.slice(0, Math.max(0, 6 - hexString.length)) + hexString}`;
}

export function toRgbaHex(color: number): string {
  const colorValue = color >>> 0;
  const alpha = colorValue & 0xff;
  const blue = (colorValue >> 8) & 0xff;
  const green = (colorValue >> 16) & 0xff;
  const red = colorValue >>> 24;
  return `#${red < 16 ? '0' : ''}${red.toString(16)}${green < 16 ? '0' : ''}${green.toString(16)}${
    blue < 16 ? '0' : ''
  }${blue.toString(16)}${alpha < 16 ? '0' : ''}${alpha.toString(16)}`;
}
