import { VehicleView, VehicleViewProps } from "./vehicle-view";
import { assets } from "@puzzles/core/asset-utility";
import { Assets, Container, Graphics, Sprite, Texture } from "pixi.js";
import { gsap } from "gsap";
import { wait } from "@puzzles/core/async/awaitable";
import { shakePosition } from "@puzzles/core/animation-utility";
import { Emitter } from "@pixi/particle-emitter";
import { directionFromAngleClockwise } from "@puzzles/core/math/geometry";
import { Vector2 } from "@puzzles/core/math/vector";
import { VehicleProgressView } from "./vehicle-progress-view";
import { sound } from "@pixi/sound";

import rocketSprite from '../assets/vehicle/rocket-space.png?url';
import rocketEmitter from '../assets/vehicle/emitter-rocket.json?url';
import shakeRocketEmitter from '../assets/vehicle/emitter-shake-rocket.json?url';
import launchRocketEmitter from '../assets/vehicle/emitter-launch-rocket.json?url';
import launchSound from '../assets/sound/regular_rocket_launch_001.mp3?url';
import launchFailSound from '../assets/sound/regular_rocket_launch_fail_001.mp3?url';

@assets('RocketSpaceView', {
    rocketSprite: rocketSprite,
    rocketEmitter: rocketEmitter,
    shakeRocketEmitter: shakeRocketEmitter,
    launchRocketEmitter: launchRocketEmitter,
    launchSound: launchSound,
    launchFailSound: launchFailSound,
})

export class RocketSpaceView extends VehicleView{
    private windowMask: Graphics = new Graphics();
    private rocketEmitter: Emitter;
    private shakeEmitter: Emitter;
    private launchEmitter: Emitter;
  
    constructor(data: VehicleViewProps) {
        super(data);

        this.rocketEmitter = new Emitter(this, Assets.get('rocketEmitter'));
        this.shakeEmitter = new Emitter(this, Assets.get('shakeRocketEmitter'));

        this.vehicleContainer.addChild(this.characterContainer);

        let tex = Texture.from(rocketSprite);
        let sprite = new Sprite(tex);
        sprite.scale.set(0.5);
        sprite.anchor.set(0.5);
        this.vehicleContainer.addChild(sprite);

        let emitterC = new Container();
        emitterC.zIndex = 99;
        this.launchEmitter = new Emitter(emitterC, Assets.get('launchRocketEmitter'));
        this.addChild(emitterC);

        this.windowMask.beginFill(0xffffff);
        this.windowMask.drawEllipse(0, -146, 90, 100);
        this.windowMask.endFill();

        this.vehicleProgress = new VehicleProgressView( data, this.vehicleContainer, tex, new Vector2(100, 115));
        this.vehicleContainer.addChild(this.vehicleProgress);

        this.sortChildren();
        this.pivot.y = 55;
    }

    public async launch(success: boolean): Promise<void> {
        await wait(250);

        this.characterContainer.position.set(0, this.pivot.y);
    
        let duck = this.targetDuck.DuckContainer;
        this.characterContainer.addChild(duck);
        duck.position.set(0);

        this.targetDuck.ride();
        gsap.to(this.characterContainer.scale, {x: 0.75, y: 0.75, duration: 0.2});
        await gsap.to(this.characterContainer, {y: -75, duration: 0.25, ease: 'power1.out'});
        
        this.vehicleContainer.removeChild(this.characterContainer);
        this.vehicleContainer.addChild(this.characterContainer);
        this.vehicleContainer.addChild(this.windowMask);
        this.characterContainer.mask = this.windowMask;

        await gsap.to(this.characterContainer, {
          y: -35,
          duration: 0.3,
          ease: 'back.out(1)',
        });
        await wait(150);

        let dir = directionFromAngleClockwise(0);
        let pos = this.vehicleContainer.position;
    
        sound.play(success? 'launchSound' : 'launchFailSound');
        this.shakeEmitter.updateOwnerPos(
          pos.x + dir.x * this.pivot.y,
          pos.y + dir.y * this.pivot.y,
        );
        this.shakeEmitter.emit = true;
        await shakePosition(this.vehicleContainer, 1, 1, 5, 45, 0.7, 'in');
        this.shakeEmitter.emit = false;

        this.launchEmitter.updateOwnerPos(
          this.vehicleContainer.x + dir.x * 15,
          this.vehicleContainer.y + dir.y * 15,
        );
        this.launchEmitter.emit = true;
    
        if (success) {
          this.rocketEmitter.emit = true;
          gsap.to(this.vehicleContainer.scale, {
            x: 0.8,
            y: 1.2,
            duration: 0.15,
            yoyo: true,
            repeat: 1,
          });
          await gsap.to(this.vehicleContainer, {
            y: this.vehicleContainer.y - 1000,
            duration: 1.2,
            ease: 'power1.in',
            onUpdate: () => {
              this.rocketEmitter.updateOwnerPos(
                this.vehicleContainer.x + dir.x * 15,
                this.vehicleContainer.y + dir.y * 15,
              );
            },
          });
    
          this.rocketEmitter.emit = false;
        } else {
          gsap.to(this.vehicleContainer.scale, {
            x: 0.8,
            y: 1.3,
            duration: 0.15,
            yoyo: true,
            repeat: 1,
          });
          await gsap.to(this.vehicleContainer, { y: '-=110', duration: 0.7, ease: 'power2.out' });
          gsap
            .to(this.vehicleContainer, { angle: -90, duration: 0.25, ease: 'back.out(1.7)' })
            .delay(0.1);
          gsap.to(this.vehicleContainer, { x: -20, duration: 0.3, ease: 'power3.out' }).delay(0.15);
          await gsap.to(this.vehicleContainer, { y: 15, duration: 0.5, ease: 'bounce.out' });
        }
    }
}