import { Container, Graphics, FederatedPointerEvent, Sprite, Assets, Texture } from 'pixi.js';
import { Rect, Rectangle } from '../core/math/rectangle';
import { defaultRectStyleProps, RectStyleProps } from './action-view-props';
import { Rescalable } from '../core/pixi/display-scaler';
import { assets } from '@puzzles/core/asset-utility';
import gsap from 'gsap';
import { sound } from '@pixi/sound';

import playIcon from './assets/icon-play.png?url';
import stopIcon from './assets/icon-stop.png?url';
import clickSound from './assets/sound/fraction_square_pickup_001.mp3?url';

export type ButtonStyleProps = RectStyleProps & {
  innerColor: number;
  innerPadding: number;
  paddingX: number;
  paddingY: number;
};

export const defaultButtonInnerStyleProps: ButtonStyleProps = {
  ...defaultRectStyleProps,
  fillColor: 0xe96200,
  innerColor: 0xffbe18,
  lineColor: 0xffffff,
  lineWidth: 8,
  radius: 12.3,
  textColor: 0xffffff,
  innerPadding: 0.4,
  paddingX: 0,
  paddingY: 0,
};

export const disableButtonInnerStyleProps: ButtonStyleProps = {
  ...defaultRectStyleProps,
  fillColor: 0x87807d,
  innerColor: 0x534c48,
  lineColor: 0x534c48,
  lineWidth: 8,
  radius: 12.3,
  textColor: 0x87807d,
  innerPadding: 0.4,
  paddingX: 0,
  paddingY: 0,
};

@assets('ActionMenuButtonView', {
  playIcon: playIcon,
  stopIcon: stopIcon,
  clickSound: clickSound,
})
export class ActionMenuButtonView extends Container implements Rescalable {
  private graphics: Graphics = new Graphics();
  private defaultProps: ButtonStyleProps;
  private props: ButtonStyleProps;
  private animatedProps: ButtonStyleProps;
  private disabledProps: ButtonStyleProps = disableButtonInnerStyleProps;

  private playIcon: Sprite;

  public rect: Rect = { x: 0, y: 0, width: 100, height: 70 };
  public set isActioning(b: boolean) {
    this.playIcon.texture = b ? Assets.get('stopIcon') : Assets.get('playIcon');
    this.props = this.disabled ? this.disabledProps : this.defaultProps;
    this.draw(this.rect);
  }

  private disabled: boolean = false;
  public set Disabled(b: boolean) {
    this.disabled = b;
    this.interactive = !b;
    this.props = this.disabled ? this.disabledProps : this.defaultProps;
    this.draw(this.rect);
  }

  constructor(style: Partial<ButtonStyleProps> = {}) {
    super();
    this.props = { ...defaultButtonInnerStyleProps, ...style };
    this.defaultProps = { ...this.props };
    this.animatedProps = { ...this.props };

    this.addChild(this.graphics);

    this.playIcon = new Sprite(Texture.from(playIcon));
    this.playIcon.anchor.set(0.5);
    this.addChild(this.playIcon);

    this.isActioning = false;

    this.on('pointerdown', this.onPointerDown, this);
    this.on('pointerup', this.onPointerUp, this);

    this.Disabled = true;
  }
  
  rescale(scaleFactor: number): void { }

  private onPointerDown(e: FederatedPointerEvent) {
    if (!this.rect) return;

    this.animatedProps = { ...this.props };
    this.animatedProps.fillColor = this.props.innerColor;
    this.animatedProps.innerColor = this.props.fillColor;

    gsap.to(this.animatedProps, {
      duration: 0.1,
      paddingX: '-=0.02',
      paddingY: '-=0.02',
      radius: this.props.radius * 1.2,
      onUpdate: () => this.draw(this.rect!, this.animatedProps),
    });
  }

  private onPointerUp() {
    if (!this.rect || !this.interactive) return;

    sound.play('clickSound');
    this.animatedProps.fillColor = this.props.fillColor;
    this.animatedProps.innerColor = this.props.innerColor;

    gsap.to(this.animatedProps, {
      duration: 0.2,
      paddingX: this.props.paddingX,
      paddingY: this.props.paddingY,
      radius: this.props.radius,
      onUpdate: () => this.draw(this.rect!, this.animatedProps),
    });
  }

  resize(rect: Rect) {
    this.position.set(rect.x, rect.y);
    this.rect = rect;

    const scaleFactor = rect.height / 100;
    this.props =this.disabled ?{ ...this.disabledProps }: { ...this.defaultProps };
    this.props.lineWidth *= scaleFactor;
    this.props.radius *= scaleFactor;

    this.draw(rect);
  }

  draw(rect: Rect = this.rect, props: ButtonStyleProps = this.props) {
    let r = new Rectangle(0, 0, rect.width, rect.height).pad(
      rect.width * props.paddingX,
      rect.height * props.paddingY,
    );

    this.graphics.clear();
    this.graphics.lineStyle({
      width: props.lineWidth,
      color: props.lineColor,
    });

    this.graphics.beginFill(props.fillColor);
    this.graphics.drawRoundedRect(r.x, r.y, r.width, r.height, props.radius);
    this.graphics.endFill();

    r.y += props.lineWidth;
    r.x += props.lineWidth;
    r.width -= props.lineWidth * 2;
    r.height = r.height * (1 - props.innerPadding);

    this.graphics.lineStyle(0);
    this.graphics.beginFill(props.innerColor);
    this.graphics.drawRoundedRect(r.x, r.y, r.width, r.height, props.radius - props.lineWidth);
    this.graphics.endFill();

    this.playIcon.scale.set(r.height * 0.0085);
    this.playIcon.x = r.x + r.width / 2;
    this.playIcon.y = r.y + r.height / 2;
    this.playIcon.tint = props.textColor;
  }
}
