import { Container } from 'pixi.js';
import { Disposable } from '../core/interfaces/disposable';
import { Rect } from '../core/math/rectangle';
import { ActionMenuView } from './action-menu-view';
import { CharacterView } from './character-view';
import { PuzzleState, findAllSolutions } from './puzzle-state';
import { PuzzleView, PuzzleViewBuilder } from './puzzle-view';
import { CircleRulerView, LineRulerView } from './ruler-view';
import {
  ICharacterRulerViewController,
  CircleRulerViewController,
  LineRulerViewController,
} from './ruler-view-controller';
import { ActionViewController } from './action-view-controller';
import { ActionData } from './puzzle-data';
import { wait } from '../core/async/awaitable';
import { playBump } from '../core/animation-utility';
import { PuzzleSkinData } from './puzzle-skin';
import { Vec2 } from '@puzzles/core/math/vector';
import { assets } from '@puzzles/core/asset-utility';
import { sound } from '@pixi/sound';
import gsap from 'gsap';

import spawnSound from './assets/sound/spaceship_appear_001.mp3?url';
import spawnRulerSound from './assets/sound/fraction_lines_populate_001.mp3?url';

@assets('PuzzleActivityViewController', {
  spawnSound: spawnSound,
  spawnRulerSound: spawnRulerSound,
})
export class PuzzleActivityViewController implements Disposable {
  puzzleView?: PuzzleView;
  actionView: ActionMenuView;
  actionViewController: ActionViewController;
  characterRulers: ICharacterRulerViewController[] = [];
  container: Container;
  private skin: PuzzleSkinData;
  private rect: Rect;

  constructor(
    state: PuzzleState,
    skin: PuzzleSkinData,
    container: Container,
    containerRect: Rect,
    onAction: (actions: ActionData[]) => void,
    reset: () => void,
    launchAnimationDelay: number,
    fadeIn: boolean
  ) {
    this.container = container;
    this.skin = skin;
    this.rect = containerRect;
    this.actionView = new ActionMenuView();
    this.actionView.zIndex = 9;
    this.container.addChild(this.actionView);
    this.actionViewController = new ActionViewController(this.actionView, this.container, onAction, reset);

    this.refresh(state, launchAnimationDelay, fadeIn);
  }

  dispose() {
    this.puzzleView?.destroy();
    this.actionView?.destroy();
    this.actionViewController?.dispose();
  }

  resize(rect: Rect) {
    this.rect = rect;
    this.actionView.resize(rect);
    this.puzzleView?.resize(this.actionView.getGameRect(rect));
    this.characterRulers.forEach((c) => c.resize());
  }

  async refreshGameView(
    state: PuzzleState,
    resize: boolean = false,
    playAnimation: boolean = false,
    launchAnimationDelay: number = 400,
    fadeIn: boolean = false
  ) {
    this.puzzleView?.destroy();
    this.puzzleView = new PuzzleViewBuilder().build(state, this.skin);
    this.container.addChild(this.puzzleView);
    this.container.setChildIndex(this.actionView, this.container.children.length - 1);

    for (var controller of this.characterRulers) {
      controller.dispose();
    }
    this.characterRulers = [];
    const character = this.puzzleView?.entities.find((e) => e instanceof CharacterView)! as CharacterView;
    for (var ruler of this.puzzleView!.rulers) {
      var controller: ICharacterRulerViewController =
        ruler instanceof LineRulerView
          ? new LineRulerViewController(ruler, character)
          : new CircleRulerViewController(ruler as CircleRulerView, character);

      controller.onUpdate = (pos: Vec2, rot: number) => this.rulerOnUpdate(pos, rot);
      this.characterRulers.push(controller);
    }

    if (resize) this.resize(this.rect);
    this.actionViewController.blockActionViews(true);
    if (playAnimation) await this.launchAnimation(launchAnimationDelay, fadeIn);
    else {
      sound.play('spawnRulerSound');
      for (var r in this.characterRulers) this.characterRulers[r].ruler.launch(true);
    }
    this.puzzleView!.characterMarker.show(true);

    this.actionViewController.resetActioning();
  }

  private rulerOnUpdate(pos: Vec2, rot: number): void {
    this.puzzleView!.characterMarker.position.set(pos.x, pos.y);
    this.puzzleView!.characterMarker.rotation = rot;
  }

  private async launchAnimation(launchAnimationDelay: number, fadeIn: boolean) {
    const entities = [...this.puzzleView!.entities];
    entities.forEach((e) => (e.visible = false));

    if(fadeIn) await gsap.fromTo(this.puzzleView!,{alpha: 0}, {alpha: 1, duration: 0.3});

    let duck = this.puzzleView!.selectEntity<CharacterView>((e) => e instanceof CharacterView);
    entities.splice(entities.indexOf(duck), 1);

    await wait(launchAnimationDelay);

    sound.play('spawnSound', { volume: 0.8 });
    for (var e in entities) {
      let entity = entities[e];
      entity.visible = true;
      playBump(entity, 0.4, 0, entity.scale.x, 'back.out(3)');
      await wait(50);
    }

    await wait(300);
    for (var r in this.characterRulers) await this.characterRulers[r].ruler.launch(true);
    
    duck.visible = true;
    await duck.land();
  }

  refresh(state: PuzzleState, launchAnimationDelay: number = 400, fadeIn: boolean) {
    this.actionViewController.refresh(state.actions, calculateTargetSlots(state));
    this.refreshGameView(state, true, true, launchAnimationDelay, fadeIn);
  }
}

export function calculateTargetSlots(state: PuzzleState) {
  let solutions = findAllSolutions(state);
  let targetCount = Number.MAX_SAFE_INTEGER;
  solutions.forEach((s) => {
    if (s.done.length < targetCount) targetCount = s.done.length;
  });

  targetCount = targetCount == Number.MIN_SAFE_INTEGER ? state.actions.length : targetCount;
  return targetCount;
}
