import { Container, Graphics, Text, ITextStyle, TextStyle, TextMetrics } from 'pixi.js';
import { FractionData } from '../../core/math/fraction';
import { FractionDisplayMode } from '../puzzle-data';

export type FractionDisplayProps = {
  showPositiveSymbol: boolean;
};

const defaultFractionDisplayProps: FractionDisplayProps = {
  showPositiveSymbol: false,
};

export class FractionView extends Container {
  private symbol?: Text;
  private numerator: Text;
  private denominator: Text;
  private value: Text;
  private separator: Graphics = new Graphics();
  private fractionContainer: Container = new Container();

  private style: Partial<ITextStyle> | TextStyle;
  private fraction: FractionData;
  private props: FractionDisplayProps;

  private displayMode: FractionDisplayMode = "fraction";
  public get DisplayMode() { return this.displayMode; }

  constructor(
    fractionData: FractionData,
    style: Partial<ITextStyle> | TextStyle,
    props: Partial<FractionDisplayProps> = {}
  ) {
    super();
    this.style = style;
    this.style.align = 'center';
    this.fraction = fractionData;
    this.props = { ...defaultFractionDisplayProps, ...props };

    let val = fractionData.numerator / fractionData.denominator;

    if (this.props.showPositiveSymbol || val < 0) {
      this.symbol = new Text(val < 0 ? '-' : '+', style);
      this.symbol.anchor.set(0.5);
      this.addChild(this.symbol);
    }

    this.numerator = new Text("", style);
    this.numerator.anchor.set(0.5);
    this.denominator = new Text("", style);
    this.denominator.anchor.set(0.5);
    this.value = new Text("", style);
    this.value.anchor.set(0.5);

    this.addChild(this.value);

    this.addChild(this.fractionContainer);
    this.fractionContainer.addChild(this.numerator);
    this.fractionContainer.addChild(this.denominator);
    this.fractionContainer.addChild(this.separator);

    this.setMode(this.displayMode);

    this.draw();
  }

  setMode(mode: FractionDisplayMode) {
    this.displayMode = mode;

    const numerator = Math.abs(this.fraction.numerator);
    const denominator = Math.abs(this.fraction.denominator);
    const decimalVal = numerator / denominator;
    
    switch (mode) {
      case 'fraction':
        this.fractionContainer.visible = true;
        this.value.visible = false;
        this.numerator.text = numerator.toString();
        this.denominator.text = denominator.toString();
        break;

      case 'decimal':
        this.fractionContainer.visible = false;
        this.value.visible = true;
        let val = decimalVal.toString();
        this.value.text = val.split('.')[1]?.length > 2? decimalVal.toFixed(2): val;
        break;

      case 'fractionMixed':
        let numberVal = Math.floor(decimalVal);
        this.value.visible = numberVal > 0;
        if(numberVal > 0) this.value.text = numberVal.toString();
        
        let restNumerator = (numerator % denominator);
        this.fractionContainer.visible = restNumerator > 0;
        if(restNumerator > 0){
          this.numerator.text = restNumerator.toString();
          this.denominator.text = denominator.toString();
        }
        
        break;
    }

    this.draw();
  }

  public setFontSize(size: number, rectWidth: number| undefined = undefined) {
    if (!this.style) return;

    this.style.fontSize = size;
    if (this.symbol){
      this.symbol.style = this.style;
      this.symbol.width = TextMetrics.measureText(this.symbol.text, this.symbol.style).width;
    }
    this.value.style = this.numerator.style = this.denominator.style = this.style;

    if(this.value.visible){
      (this.value.style.fontSize as number) *= 1.1;
      this.value.width = TextMetrics.measureText(this.value.text, this.value.style).width;
      this.value.scale.set(rectWidth !== undefined? Math.min(1, (rectWidth * 0.6) / this.value.width) : 1);
    }

    if(this.fractionContainer.visible) {
      this.numerator.width = TextMetrics.measureText(this.numerator.text, this.numerator.style).width;
      this.denominator.width = TextMetrics.measureText(this.denominator.text, this.denominator.style).width;
      const scale = rectWidth !== undefined? Math.min(1, (rectWidth * 0.5) / Math.max(this.numerator.width, this.denominator.width)) : 1;
      this.numerator.scale.set(scale);
      this.denominator.scale.set(scale);

      if(this.value.visible) {
        (this.numerator.style.fontSize as number) *= 0.8;
        (this.denominator.style.fontSize as number) *= 0.8;
  
        this.numerator.width = TextMetrics.measureText(this.numerator.text, this.numerator.style).width;
        this.denominator.width = TextMetrics.measureText(this.denominator.text, this.denominator.style).width;
      }
    }

    this.draw();
  }

  draw() {
    const displayMode = this.displayMode;
    let seperatorWidth = Math.max(this.numerator.width, this.denominator.width) * this.numerator.scale.x;
    if(!this.value.visible) seperatorWidth += (this.numerator.style.fontSize as number) / 4;

    const separatorLineWidth = (this.numerator.style.fontSize as number) / 9;
    const textOffset = this.symbol? this.symbol.width * this.symbol.scale.x / 3 : 0;
    
    if(this.fractionContainer.visible){
      this.numerator.y = -this.numerator.height / 2;
      this.denominator.y = this.denominator.height / 2;

      this.separator.clear();
      this.separator.lineStyle({
        width: separatorLineWidth,
        color: (this.style.fill ?? 0xffffff) as number,
      });

      this.separator.moveTo(-seperatorWidth / 2, 0);
      this.separator.lineTo(seperatorWidth / 2 * 1.1, 0);

      if(this.value.visible){
        this.value.x = -seperatorWidth / 2 + textOffset;
        this.fractionContainer.x = seperatorWidth / 2 + textOffset;
      }
      else{
        this.fractionContainer.x = textOffset;
      }
    }
    else{
      this.value.x = textOffset;
    }

    if (this.symbol) {
      const symbolWidth = this.symbol.width;
      switch (displayMode) {
        case 'fraction':
          this.symbol.y = -separatorLineWidth;
          this.symbol.x = textOffset + (-seperatorWidth / 2 - symbolWidth / 1.5);
          break;
        case 'decimal':
          this.symbol.y = 0
          this.symbol.x = this.value.x - this.value.width * this.value.scale.x / 2 - symbolWidth / 1.5;
          break;
        case 'fractionMixed':
          if(this.value.visible){
            this.symbol.y = 0;
            this.symbol.x = this.value.x - this.value.width;
          }
          else{
            this.symbol.y = -separatorLineWidth;
            this.symbol.x = -seperatorWidth / 2 * 1.2;
          }
          break;
      }
    }

    //this.drawGizmos();
  }

  // currently unused
  // private drawGizmos() {
  //   this.graphics.lineStyle({ width: 1, color: 0xff0000 });
  //   this.graphics.drawCircle(0, 0, 2);
  //   this.graphics.drawCircle(this.numerator.x, this.numerator.y, 2);
  //   this.graphics.drawCircle(this.denominator.x, this.denominator.y, 2);
  // }
}
