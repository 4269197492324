import { Sprite, Texture } from 'pixi.js';
import { Rect } from '../core/math/rectangle';

export class BackgroundView extends Sprite {
  private aspectRatio: number = 958 / 1573;

  constructor(url: string) {
    super();

    this.texture = Texture.from(url);
    this.anchor.set(0.5, 0);
    this.position.y = -2;
  }

  public draw(rect: Rect) {
    let scaleX = rect.width * this.aspectRatio > rect.height;
    this.width = scaleX ? rect.width : rect.height / this.aspectRatio;
    this.height = scaleX ? rect.width * this.aspectRatio : rect.height + 4;
    this.x = rect.width / 2;
  }
}
