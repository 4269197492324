import { PuzzleData } from '../../../puzzle-data';

export { specimen as level01 };
export { specimenMix as level02 };

const specimen: PuzzleData = {
  rulers: [
    { type: 'segment', text: 'decimal', fractions: 4, to: 2, displayLevel: 'allFractions' },
    { type: 'line', text: 'fractionMixed', fractions: 4, from: 0, to: 2, displayLevel: 'empty' },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 4 },
    { type: 'portal', ruler: 0, numerator: 6, denominator: 4 },
    { type: 'battery', ruler: 0, numerator: 4, denominator: 4 },
    { type: 'portal', ruler: 1, numerator: 6, denominator: 4 },
    { type: 'vehicle', ruler: 1, numerator: 4, denominator: 4 },
  ],
  actions: [
    { type: 'move', text: 'fractionMixed', numerator: 5, denominator: 4 },
    { type: 'move', text: 'decimal', numerator: 4, denominator: 4 },
    { type: 'move', numerator: -1, denominator: 2 },
    { type: 'move', text: 'decimal', numerator: 1, denominator: 2 },
  ],
};

const specimenMix: PuzzleData = {
  rulers: [
    { type: 'line', text: 'fraction', fractions: 4, from: 0, to: 3 },
    { type: 'circle', fractions: 2, angleOffset: 0, displayLevel: 'empty' },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 6, denominator: 4 },
    { type: 'portal', ruler: 0, numerator: 12, denominator: 4 },
    { type: 'battery', ruler: 0, numerator: 10, denominator: 4 },
    { type: 'portal', ruler: 1, numerator: 10, denominator: 4 },
    { type: 'vehicle', ruler: 1, numerator: 8, denominator: 4 },
  ],
  actions: [
    { type: 'move', numerator: -2, denominator: 2 },
    { type: 'move', numerator: 4, denominator: 4 },
    { type: 'move', numerator: -5, denominator: 2 },
    { type: 'move', numerator: 1, denominator: 2 },
  ],
};
