import { isFloat } from './utility';

export function isFractionData(obj: any): obj is FractionData {
  return (
    typeof obj === 'object' && obj !== null && typeof obj.numerator === 'number' && typeof obj.denominator === 'number'
  );
}

export function greatestCommonDivisor(a: number, b: number): number {
  while (b !== 0) {
    const temp = b;
    b = a % b;
    a = temp;
  }
  return a;
}

export function lowestCommonDenominator(a: number, b: number): number {
  return (a * b) / greatestCommonDivisor(a, b);
}

export function* enumerateFractions(
  denominator: number,
  fromNumerator: number,
  toNumerator: number
): Generator<FractionData> {
  for (let i = fromNumerator; i <= toNumerator; i++) {
    yield { numerator: i, denominator: denominator };
  }
}

export function compareFractions(a: FractionData, b: FractionData): number {
  const value1 = a.numerator / a.denominator;
  const value2 = b.numerator / b.denominator;

  if (value1 < value2) {
    return -1;
  } else if (value1 > value2) {
    return 1;
  } else {
    return 0;
  }
}

export function reduce(fraction: FractionData, out?: FractionData): FractionData {
  out = out ?? { numerator: 0, denominator: 0 };
  const gcd = greatestCommonDivisor(fraction.numerator, fraction.denominator);
  out.numerator = fraction.numerator / gcd;
  out.denominator = fraction.denominator / gcd;
  return out;
}

export function upgrade(fraction: FractionData, denominator: number, out?: FractionData): FractionData {
  if (denominator < fraction.denominator) throw new Error('Cannot upgrade fraction to a smaller denominator');
  const factor = denominator / fraction.denominator;
  if (!Number.isInteger(factor)) throw new Error('Cannot upgrade fraction not divisible by the denominator');
  out = out ?? { numerator: 0, denominator: 0 };
  out.numerator = fraction.numerator * factor;
  out.denominator = denominator;
  return out;
}

export function add(a: FractionData, b: FractionData, out?: FractionData): FractionData {
  out = out ?? { numerator: 0, denominator: 0 };
  const lcm = lowestCommonDenominator(a.denominator, b.denominator);

  out.numerator = (a.numerator * lcm) / a.denominator + (b.numerator * lcm) / b.denominator;
  out.denominator = lcm;

  return reduce(out, out);
}

export function subtract(a: FractionData, b: FractionData, out?: FractionData): FractionData {
  out = out ?? { numerator: 0, denominator: 0 };
  const lcm = lowestCommonDenominator(a.denominator, b.denominator);
  out.numerator = (a.numerator * lcm) / a.denominator - (b.numerator * lcm) / b.denominator;
  out.denominator = lcm;
  return reduce(out, out);
}

export function multiply(a: FractionData, b: FractionData, out?: FractionData): FractionData {
  out = out ?? { numerator: 0, denominator: 0 };
  out.numerator = a.numerator * b.numerator;
  out.denominator = a.denominator * b.denominator;
  return reduce(out, out);
}

export function divide(a: FractionData, b: FractionData, out?: FractionData): FractionData {
  out = out ?? { numerator: 0, denominator: 0 };
  out.numerator = a.numerator * b.denominator;
  out.denominator = a.denominator * b.numerator;
  return reduce(out, out);
}

export function value(fraction: FractionData): number {
  return fraction.numerator / fraction.denominator;
}

export function toString(fraction: FractionData): string {
  return `${fraction.numerator}/${fraction.denominator}`;
}

export type FractionData = {
  numerator: number;
  denominator: number;
};

export class Fraction implements FractionData {
  public numerator: number;
  public denominator: number;

  constructor(fraction: string);
  constructor(numerator: number, denominator: number);
  constructor(fraction: FractionData);
  constructor(a: string | number | FractionData, b?: number) {
    let n = NaN;
    let d = NaN;
    if (typeof a === 'string') {
      const split = a.split('/');
      if (split.length > 2) throw new Error('invalid number of arguments');
      const [numeratorStr, denominatorStr] = split;
      n = parseInt(numeratorStr);
      d = parseInt(denominatorStr);
    } else if (typeof a === 'object') {
      n = a.numerator;
      d = a.denominator;
    } else {
      if (b === undefined) throw new Error('invalid number of arguments');
      n = a;
      d = b;
    }

    if (isNaN(n) || isNaN(d)) throw new Error('construction parameter are not numbers');
    if (isFloat(n) || isNaN(d)) throw new Error('construction params must be integers');
    if (d == 0) throw new Error('denominator cannot be zero');

    this.numerator = n;
    this.denominator = d;
    this.reduce();
  }

  private reduce() {
    reduce(this, this);
  }

  add(other: FractionData): FractionData {
    return add(this, other);
  }

  subtract(other: FractionData): FractionData {
    return subtract(this, other);
  }

  multiply(other: FractionData): FractionData {
    return multiply(this, other);
  }

  divide(other: FractionData): FractionData {
    return divide(this, other);
  }

  value(): number {
    return this.numerator / this.denominator;
  }

  toString(): string {
    return `${this.numerator}/${this.denominator}`;
  }
}
