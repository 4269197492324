export type Slot<S, E> = {
  tag: string;
  element?: E;
  previousElement?: E;
  slot: S;
};

export class SlotCollectionMap<S, E> {
  private slotMap: Map<string, Slot<S, E>[]> = new Map();
  private elementMap: Map<E, Slot<S, E>> = new Map();

  clear() {
    this.slotMap.clear();
    this.elementMap.clear();
  }

  hasTag(tag: string) {
    return this.slotMap.has(tag);
  }

  addSlot(tag: string, slot: S, element?: E) {
    const slots = this.slotMap.get(tag) || [];
    slots.push({ tag, slot, element: element, previousElement: undefined });
    this.slotMap.set(tag, slots);
    if (element) this.elementMap.set(element, slots[slots.length - 1]);
    return slots[slots.length - 1];
  }

  getSlot(tag: string, index: number) {
    if (!this.slotMap.has(tag)) throw new Error(`No slots for tag ${tag}`);
    const slots = this.slotMap.get(tag)!;
    return slots[index];
  }

  getSlots(tag: string): Readonly<Slot<S, E>[]> {
    if (!this.slotMap.has(tag)) throw new Error(`No slots for tag ${tag}`);
    return this.slotMap.get(tag)!;
  }

  getSlotCount(tag: string): number {
    if (!this.slotMap.has(tag)) throw new Error(`No slots for tag ${tag}`);
    return this.slotMap.get(tag)!.length;
  }

  getEmptySlot(tag: string): Slot<S, E> | undefined {
    if (!this.slotMap.has(tag)) throw new Error(`No slots for tag ${tag}`);
    return this.slotMap.get(tag)!.find(slot => !slot.element);
  }

  getEmptySlots(tag: string): Readonly<Slot<S, E>[]> {
    if (!this.slotMap.has(tag)) throw new Error(`No slots for tag ${tag}`);
    return this.slotMap.get(tag)!.filter(slot => !slot.element);
  }

  getElementSlot(element: E): Slot<S, E> | undefined {
    return this.elementMap.get(element);
  }

  addElement(tag: string, element: E) {
    const slot = this.getClosestEmptySlot(tag);
    if (!slot) throw new Error(`No empty slots for tag ${tag}`);
    this.setElementSlot(slot, element);
  }

  setElementSlot(slot: Slot<S, E>, element: E) {
    const existingSlot = this.elementMap.get(element);
    if (slot == existingSlot) return;
    if (existingSlot) {
      existingSlot.previousElement = existingSlot.element;
      existingSlot.element = undefined;
    }

    if (slot.element) this.elementMap.delete(slot.element);
    slot.previousElement = slot.element;
    slot.element = element;
    this.elementMap.set(element, slot);
  }

  removeElement(element: E) {
    const slot = this.elementMap.get(element);
    if (!slot) return;
    this.elementMap.delete(element);
    slot.previousElement = slot.element;
    slot.element = undefined;
  }

  getElements(): Iterable<E> {
    return this.elementMap.keys();
  }

  getClosestFilteredSlot(
    tag: string,
    from: number,
    filter: (s: Slot<S, E>) => boolean,
  ): Slot<S, E> | undefined {
    const slots = this.slotMap.get(tag)!;
    if (slots == undefined) throw new Error(`No slots for tag ${tag}`);
    const slotCount = slots.length;
    const start = from;
    var i = 0;
    while (i < slotCount) {
      var index = (start + i) % slotCount;
      var slot = slots[index];
      if (filter(slot)) return slots[index];
      i++;
    }
    return undefined;
  }

  getClosestEmptySlot(tag: string, slot: number = 0): Slot<S, E> | undefined {
    const slots = this.slotMap.get(tag)!;
    const slotCount = slots.length;
    const start = slot;
    var i = 0;
    while (i < slotCount) {
      var index = (start + i) % slotCount;
      if (!slots[index].element) return slots[index];
      i++;
    }
    return undefined;
  }
}
