import { VehicleView, VehicleViewProps } from "./vehicle-view";
import { assets } from "@puzzles/core/asset-utility";
import { Assets, Container, Sprite, Texture } from "pixi.js";
import { Emitter } from "@pixi/particle-emitter";
import { wait } from "@puzzles/core/async/awaitable";
import { directionFromAngleClockwise } from "@puzzles/core/math/geometry";
import { shakePosition } from "@puzzles/core/animation-utility";
import { VehicleProgressView } from "./vehicle-progress-view";
import { Vector2 } from "@puzzles/core/math/vector";
import gsap from "gsap";
import { sound } from "@pixi/sound";

import rocketSprite from '../assets/vehicle/rocket-desert.png?url';
import shakeRocketEmitter from '../assets/vehicle/emitter-shake-rocket-desert.json?url';
import launchRocketEmitter from '../assets/vehicle/emitter-launch-rocket-desert.json?url';
import rocketEmitterUrl from '../assets/vehicle/emitter-rocket-desert.json?url';
import launchSound from '../assets/sound/regular_rocket_launch_001.mp3?url';
import launchFailSound from '../assets/sound/regular_rocket_launch_fail_001.mp3?url';

@assets('RocketDesertView', {
    rocketSprite: rocketSprite,
    shakeRocketEmitter: shakeRocketEmitter,
    launchRocketEmitter: launchRocketEmitter,
    rocketEmitter: rocketEmitterUrl,
    launchSound: launchSound,
    launchFailSound: launchFailSound,
})
export class RocketDesertView extends VehicleView{   
    private rocketEmitter!: Emitter;
    private shakeEmitter!: Emitter;
    private launchEmitter!: Emitter;
    
    constructor(data: VehicleViewProps) {
        super(data);

        this.characterContainer.scale.set(0.9);

        this.rocketEmitter = new Emitter(this, Assets.get('rocketEmitter'));

        this.vehicleContainer.addChild(this.characterContainer);

        let tex = Texture.from(rocketSprite);
        let sprite = new Sprite(tex);
        sprite.scale.set(0.5);
        sprite.anchor.set(0.5);
        this.vehicleContainer.addChild(sprite);

        let emitterC = new Container();
        emitterC.zIndex = 99;
        this.addChild(emitterC);          
        this.shakeEmitter = new Emitter(emitterC, Assets.get('shakeRocketEmitter'));
        this.launchEmitter = new Emitter(emitterC, Assets.get('launchRocketEmitter'));

        this.vehicleProgress = new VehicleProgressView(data, this.vehicleContainer, tex, new Vector2(100, 130));
        this.vehicleContainer.addChild(this.vehicleProgress);

        this.sortChildren();
        this.pivot.y = 58;
    }
    
    public async launch(success: boolean): Promise<void> {
        await wait(250);
        let duck = this.targetDuck.DuckContainer;

        this.characterContainer.y = this.pivot.y;
        this.characterContainer.addChild(duck);

        this.targetDuck.ride();
        gsap.to(this.characterContainer, {x: -35, y: -15, duration: 0.2});
        await gsap.to(this.characterContainer, {angle: -95, duration: 0.2});
        this.vehicleContainer.removeChild(this.characterContainer);
        this.vehicleContainer.addChild(this.characterContainer);
        gsap.to(this.characterContainer, {x: -5, y: -10, duration: 0.15});
        await gsap.to(this.characterContainer, {angle: -82, duration: 0.15});
        await wait(200);

        let dir = directionFromAngleClockwise(0);
        let pos = this.vehicleContainer.position;

        sound.play(success? 'launchSound' : 'launchFailSound');
        this.shakeEmitter.updateOwnerPos(
            pos.x + dir.x * 50,
            pos.y + dir.y * 50,
        );
        this.shakeEmitter.emit = true;
        await shakePosition(this.vehicleContainer, 1, 1, 5, 45, 0.7, 'in');
        this.shakeEmitter.emit = false;

        this.launchEmitter.updateOwnerPos(
            pos.x + dir.x * 15,
            pos.y + dir.y * 15,
        );
        this.launchEmitter.emit = true;
    
        if (success) {
          this.rocketEmitter.emit = true;
          gsap.to(this.vehicleContainer.scale, {
            x: 0.8,
            y: 1.2,
            duration: 0.15,
            yoyo: true,
            repeat: 1,
          });
          await gsap.to(this.vehicleContainer, {
            y: this.vehicleContainer.y - 1000,
            duration: 1.3,
            ease: 'power1.in',
            onUpdate: () => {
              this.rocketEmitter.updateOwnerPos(
                pos.x + dir.x * 15,
                pos.y + dir.y * 15,
              );
            },
          });
    
          this.rocketEmitter.emit = false;
        } else {
          gsap.to(this.vehicleContainer.scale, {
            x: 0.8,
            y: 1.2,
            duration: 0.15,
            yoyo: true,
            repeat: 1,
          });
          await gsap.to(this.vehicleContainer, { y: '-=110', duration: 0.7, ease: 'power2.out' });
          gsap
            .to(this.vehicleContainer, { angle: -90, duration: 0.25, ease: 'back.out(1.7)' })
            .delay(0.1);
          gsap.to(this.vehicleContainer, { x: -20, duration: 0.3, ease: 'power3.out' }).delay(0.15);
          await gsap.to(this.vehicleContainer, { y: 15, duration: 0.5, ease: 'bounce.out' });
        }
    }
}