import { Vec2, fromTo, magnitude } from '@puzzles/core/math/vector';
import { Rescalable } from '@puzzles/core/pixi/display-scaler';
import { Container, Graphics, LINE_CAP } from 'pixi.js';
import { DashedFractionView } from './dashed-fraction-view';
import { ActionData } from '../puzzle-data';
import { generateCubicBezier } from '@puzzles/core/math/curves';
import { clamp01 } from '@puzzles/core/math/utility';
import { angleFromVector } from '@puzzles/core/math/geometry';
import { drawDashedLine } from '@puzzles/core/pixi/dashed-line';
import { rangeFrom } from '@puzzles/core/iterable';
import { colorLerp } from '@puzzles/core/math/color';

export type DashedBezierCurveViewProps = {
  lineWidth: number;
  distance: number;
  radius: number;
  dash: number;
  height: number;
  points: number;
  fillColor: number;
};

const defaultDashedBezierCurveViewProps: DashedBezierCurveViewProps = {
  lineWidth: 5,
  distance: 100,
  radius: 4,
  dash: 10,
  height: 100,
  points: 200,
  fillColor: 0x000000,
};

export class DashedBezierCurveView extends Container implements Rescalable {
  graphics: Graphics = new Graphics();
  debug: Graphics = new Graphics();
  points!: Readonly<Vec2>[];
  vector: Vec2 = { x: 0, y: 0 };
  origin: Vec2 = { x: 0, y: 0 };
  center: Vec2 = { x: 0, y: 0 };
  p0!: { x: number; y: number };
  p1!: { x: number; y: number };
  p2!: { x: number; y: number };
  p3!: { x: number; y: number };

  props: DashedBezierCurveViewProps;
  unscaledProps: DashedBezierCurveViewProps;
  fractionView?: DashedFractionView;

  constructor(props: Partial<DashedBezierCurveViewProps> = {}, action?: ActionData) {
    super();

    this.props = { ...defaultDashedBezierCurveViewProps, ...props };
    this.unscaledProps = { ...this.props };

    this.calculatePoints();

    this.addChild(this.graphics);
    this.addChild(this.debug);
  }

  private calculatePoints() {
    this.center = { x: this.props.distance / 2, y: 0 };
    this.p0 = { x: 0, y: 0 };
    this.p1 = { x: 0, y: -this.props.height };
    this.p2 = { x: this.props.distance, y: -this.props.height };
    this.p3 = { x: this.props.distance, y: 0 };
    this.points = [...generateCubicBezier(this.p0, this.p1, this.p2, this.p3, 200)];

    this.fractionView?.position.set(this.p1.x + (this.p2.x - this.p1.x) / 2, this.p1.y * 1.05);
  }

  rescale(scaleFactor: number) {
    this.props = { ...this.unscaledProps };
    this.props.height *= scaleFactor;
    this.props.radius *= scaleFactor;
    this.props.dash *= scaleFactor;
    this.props.lineWidth *= scaleFactor;
    this.calculatePoints();
    this.draw();
    this.fractionView?.scale.set(scaleFactor * 0.6);
  }

  draw(t: number = 1) {
    if (clamp01(t) != t) throw new Error('t must be between 0 and 1');

    const start = 0;
    const end = this.indexOfClosestSmallerPoint(t);

    this.graphics.clear();
    this.drawFill(start, end);
    this.drawDashLine(start, end);
    //this.drawGizmos();
  }

  getPoint(t: number): Vec2 {
    if (clamp01(t) != t) throw new Error('t must be between 0 and 1');
    const index = this.indexOfClosestSmallerPoint(t);
    return this.points[index];
  }

  indexOfClosestSmallerPoint(t: number): number {
    if (t == 0) return 0;
    if (t >= 1) return this.points.length - 1;
    const tX = magnitude(fromTo(this.origin, this.p3, this.vector)) * t;
    for (let i = 1; i < this.points.length; i++) {
      if (Math.abs(this.points[i].x) > tX) return i - 1;
    }
    return this.points.length - 1;
  }

  indexOfFirstPointAfterAngle(angle: number): number {
    for (let i = 0; i < this.points.length; i++) {
      if (this.angleFromPoint(this.points[i]) >= angle) return i;
    }
    return this.points.length - 1;
  }
  indexOfLastPointBeforeAngle(angle: number): number {
    for (let i = 1; i < this.points.length; i++) {
      if (this.angleFromPoint(this.points[i]) >= angle) return i - 1;
    }
    return this.points.length - 1;
  }

  angleFromPoint(point: Vec2) {
    fromTo(this.origin, point, this.vector);
    return angleFromVector(this.vector) + Math.PI / 2;
  }

  private drawDashLine(start: number, end: number) {
    const { graphics } = this;

    graphics.lineStyle({
      width: this.props.lineWidth,
      cap: LINE_CAP.ROUND,
      color: 0xffffff,
    });

    drawDashedLine(graphics, rangeFrom(this.points, start, end - start), this.props.dash);
  }

  drawFill(start: number, end: number) {
    const { graphics, points } = this;

    graphics.lineStyle(0);
    graphics.moveTo(points[0].x, points[0].y);
    graphics.beginFill(this.props.fillColor, 0.9);

    for (let i = start; i < points.length; i++) {
      const current = points[i];
      graphics.lineTo(current.x, current.y);
      if (i >= end) break;
    }

    graphics.lineTo(points[end].x, this.origin.y);
    graphics.lineTo(this.origin.x, this.origin.y);
    graphics.endFill();
  }

  drawGizmos() {
    const graphics = this.debug;
    graphics.lineStyle(1, 0x00ff00);
    graphics.drawCircle(this.origin.x, this.origin.y, this.props.radius);
    graphics.drawCircle(this.center.x, this.center.y, this.props.radius);

    graphics.drawCircle(this.p0.x, this.p0.y, this.props.radius);
    graphics.drawCircle(this.p1.x, this.p1.y, this.props.radius);
    graphics.drawCircle(this.p2.x, this.p2.y, this.props.radius);
    graphics.drawCircle(this.p3.x, this.p3.y, this.props.radius);

    this.points.forEach((p, i) => {
      graphics.lineStyle(2, colorLerp(0xff0000, 0x0000ff, i / this.points.length));
      graphics.drawCircle(p.x, p.y, this.props.radius);
      graphics.endFill();
    });
  }
}
