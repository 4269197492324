import { Container } from 'pixi.js';
import { Rescalable } from '../../core/pixi/display-scaler';

export interface IEntityView {}

export abstract class EntityView extends Container implements IEntityView, Rescalable {
  protected timeline?: gsap.core.Timeline;
  protected adjustScale: number = 0.9;

  rescale(scaleFactor: number): void {
    this.scale.set(this.adjustScale * scaleFactor);
  }
}
