import { Container, FederatedPointerEvent, DisplayObject, Point } from 'pixi.js';
import { Tickable } from '../core/interfaces/tickable';
import { Disposable } from '../core/interfaces/disposable';

export class DragController extends EventTarget implements Tickable, Disposable {
  stage: Container;
  entities: DisplayObject[] = [];
  dragEntity: DisplayObject | null = null;
  dragOffset: Point = new Point();
  dragStart: Point = new Point();
  dragMove: Point = new Point();
  dragEnd: Point = new Point();
  dragHistory: Point[] = [];
  dragHistoryMax = 10;
  dragHistoryTime = 1000;
  dragHistoryElapsed = 0;

  constructor(stage: Container) {
    super();

    this.stage = stage;
    for (var i = 0; i < this.dragHistoryMax; i++) {
      this.dragHistory.push(new Point());
    }
    this.bind();
  }

  bind() {
    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
  }

  dispose(): void {
    this.cleanStageListeners();
    this.entities.forEach(e => e.off('pointerdown', this.onPointerDown));
  }

  private cleanStageListeners() {
    this.stage.off('pointermove', this.onPointerMove);
    this.stage.off('pointerup', this.onPointerUp);
    this.stage.off('pointerupoutside', this.onPointerUp);
    this.stage.off('pointerleave', this.onPointerUp);
  }

  tick(delta: number): void {
    this.dragHistory.push(this.dragMove.clone());
  }
  clear() {
    this.entities.forEach(e => this.remove(e));
  }

  add(entity: DisplayObject) {
    if (this.entities.includes(entity)) throw new Error('Entity already added');
    this.entities.push(entity);
    entity.interactive = true;
    entity.on('pointerdown', this.onPointerDown);
  }

  remove(entity: DisplayObject) {
    if (!this.entities.includes(entity)) throw new Error('Entity not added');
    this.entities.splice(this.entities.indexOf(entity), 1);
  }

  public onDragStart<T extends DisplayObject>(call: (e: T) => void) {
    this.addEventListener('dragstart', ((e: CustomEvent) =>
      call(e.detail.entity)) as EventListener);
  }
  public onDragMove<T extends DisplayObject>(call: (e: T) => void) {
    this.addEventListener('dragmove', ((e: CustomEvent) => call(e.detail.entity)) as EventListener);
  }
  public onDragEnd<T extends DisplayObject>(call: (e: T) => void) {
    this.addEventListener('dragend', ((e: CustomEvent) => call(e.detail.entity)) as EventListener);
  }

  private onPointerDown(event: FederatedPointerEvent) {
    this.dragEntity = event.target as DisplayObject;
    this.dragOffset.set(event.global.x - this.dragEntity.x, event.global.y - this.dragEntity.y);
    this.dragStart.set(this.dragEntity.x, this.dragEntity.y);
    this.stage.on('pointermove', this.onPointerMove);
    this.stage.on('pointerup', this.onPointerUp);
    this.stage.on('pointerupoutside', this.onPointerUp);
    this.stage.on('pointerleave', this.onPointerUp);
    this.dragEntity.on('pointerup', this.onPointerUp);

    this.dispatchEvent(new CustomEvent('dragstart', { detail: { entity: this.dragEntity } }));
  }

  private onPointerMove(event: FederatedPointerEvent) {
    if (!this.dragEntity) return;
    this.dragMove.set(event.global.x - this.dragOffset.x, event.global.y - this.dragOffset.y);
    this.dragEntity!.x = this.dragMove.x;
    this.dragEntity!.y = this.dragMove.y;

    this.dispatchEvent(new CustomEvent('dragmove', { detail: { entity: this.dragEntity } }));
  }

  private onPointerUp(e: FederatedPointerEvent) {
    if (!this.dragEntity) return;
    this.dragEnd.set(this.dragEntity.x, this.dragEntity.y);

    this.dispatchEvent(new CustomEvent('dragend', { detail: { entity: this.dragEntity } }));
    this.dragEntity = null;
    this.cleanStageListeners();
  }
}
