import { add, dot, perpendicular, Vec2, Vector2, subtract } from '../core/math/vector';
import { compareFractions, enumerateFractions, FractionData, value } from '../core/math/fraction';
import { angleFromVectorClockwise, pointOnCircleClockwise } from '../core/math/geometry';
import { approximatelyEquals } from '../core/math/utility';
import { RulerType } from './puzzle-data';

export function areEquivalent(a: FractionData, b: FractionData, ruler: RulerType): boolean {
  switch (ruler) {
    default:
      return compareFractions(a, b) == 0;
    case 'circle':
      let angle1 = (value(a) * Math.PI * 2) % (Math.PI * 2);
      let angle2 = (value(b) * Math.PI * 2) % (Math.PI * 2);
      angle1 = angle1 > 0 ? angle1 : Math.PI * 2 + angle1;
      angle2 = angle2 > 0 ? angle2 : Math.PI * 2 + angle2;
      return approximatelyEquals(angle1, angle2, 0.01);
  }
}

export function* enumerateRulerMarks(ruler: IRuler, units: number, offset: Vec2 = { x: 0, y: 0 }): Generator<Vec2> {
  const from = 0;
  const to = ruler.fractions * units;
  const denominator = ruler.fractions;
  for (var f of enumerateFractions(denominator, from, to)) {
    yield ruler.getPosition(f, offset);
  }
}

export function* enumerateRulerMarksFromTo(
  ruler: IRuler,
  from: number,
  to: number,
  offset: Vec2 = { x: 0, y: 0 }
): Generator<Vec2> {
  const denominator = ruler.fractions;
  for (var f of enumerateFractions(denominator, from, to)) {
    yield ruler.getPosition(f, offset);
  }
}

export interface IRuler {
  fractions: number;

  getPosition(fraction: number | FractionData, offset?: Vec2): Vec2;
  getFraction(position: Vec2): number;
}

export class LineRuler implements IRuler {
  fractions: number;
  origin: Vec2;
  direction: Vector2;
  result: Vector2 = new Vector2();
  defaultOffset = { x: 0, y: 0 };
  pixelsPerUnit: number;

  constructor(fractions: number, origin: Vec2, direction: Vec2 = { x: 1, y: 0 }, pixelsPerUnit: number = 1) {
    this.fractions = fractions;
    this.origin = origin;
    this.direction = new Vector2(direction);
    this.direction.normalize();
    this.pixelsPerUnit = pixelsPerUnit;
  }
  getFraction(position: Vec2): number {
    const v = new Vector2(position);
    v.x -= this.origin.x;
    v.y -= this.origin.y;
    const d = dot(v, this.direction);
    return d / this.pixelsPerUnit;
  }
  getPosition(fraction: number | FractionData, offset: Vec2 = { x: 0, y: 0 }): Vec2 {
    let target = 0;
    offset = offset ?? this.defaultOffset;
    if (typeof fraction === 'number') target = fraction;
    else target = fraction.numerator / fraction.denominator;

    this.result.x = this.direction.x;
    this.result.y = this.direction.y;
    this.result.scale(target * this.pixelsPerUnit + offset.x);
    this.result.y += offset.y;
    return { x: this.origin.x + this.result.x, y: this.origin.x + this.result.y };
  }
}

export class CircleRuler implements IRuler {
  fractions: number = 1;
  origin: Vec2;
  angleOffset: number;
  radius: number;
  direction = new Vector2();

  constructor(fractions: number = 1, origin: Vec2, angleOffset: number, radius: number = 1) {
    this.fractions = fractions;
    this.origin = origin;
    this.angleOffset = angleOffset;
    this.radius = radius;
  }
  getFraction(position: Vec2): number {
    var angle = angleFromVectorClockwise(subtract(position, this.origin, this.direction));
    var fraction = (angle - this.angleOffset) / (Math.PI * 2);
    if (fraction < 0) fraction += 1;
    return fraction;
  }

  getPosition(fraction: number | FractionData, offset: Vec2 = { x: 0, y: 0 }): Vec2 {
    var result = pointOnCircleClockwise(this.radius + offset.y, this.angle(fraction));

    add(this.origin, result, result);

    if (offset.x != 0) {
      perpendicular(result, this.direction);
      this.direction.normalize();
      this.direction.negate();
      this.direction.scale(offset.x);
      add(result, this.direction, result);
    }

    return { x: result.x, y: result.y };
  }

  angle(fraction: number | FractionData): number {
    const value = typeof fraction === 'number' ? fraction : fraction.numerator / fraction.denominator;
    return Math.PI * 2 * value + this.angleOffset;
  }

  angleFromPosition(position: Vec2): number {
    return angleFromVectorClockwise(position) + this.angleOffset;
  }
}
