// eslint-disable-next-line no-restricted-syntax
import { Graphics } from 'pixi.js';
import { Vec2, distance } from '../math/vector';

export function drawDashedLine(graphics: Graphics, points: Iterable<Vec2>, dash: number) {
  let travelled = 0;
  let last: Vec2 = { x: 0, y: 0 };
  let drawing = true;
  let i = 0;
  for (const current of points) {
    if (i == 0) {
      graphics.moveTo(current.x, current.y);
      last = current;
    }
    if (drawing) graphics.lineTo(current.x, current.y);
    else graphics.moveTo(current.x, current.y);

    travelled += distance(last, current);
    last = current;
    if (travelled >= dash) {
      travelled = 0;
      drawing = !drawing;
    }

    i++;
  }
}
