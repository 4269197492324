import { Disposable } from '@pixi-spine/all-4.1';
import { Assets, Container, FederatedPointerEvent } from 'pixi.js';
import { rectangleIntersectionArea } from '../core/math/rectangle';
import { ActionMenuView } from './action-menu-view';
import { ActionSlotView } from './action-slot-view';
import { ActionView } from './action-view';
import { DragController } from './drag-controller';
import { ActionData } from './puzzle-data';
import { Slot, SlotCollectionMap } from './slot-collection-map';
import gsap from 'gsap';
import { assets } from '@puzzles/core/asset-utility';
import { sound } from '@pixi/sound';

import dragStartSound from './assets/sound/fraction_square_pickup_001.mp3?url';
import insertSound from './assets/sound/fraction_square_insert_001.mp3?url';

@assets('ActionViewController', {
  dragStartSound: dragStartSound,
  insertSound: insertSound,
})
export class ActionViewController implements Disposable {
  menuView: ActionMenuView;
  dragController: DragController;
  actionMap: Map<ActionView, ActionData> = new Map();
  action: ((actions: ActionData[]) => void) | undefined;
  private onReset: () => void;
  private isActioning = false;
  private interactionEnabled = true;
  public get InteractionEnabled() {
    return this.interactionEnabled;
  }

  get slotCollectionMap(): SlotCollectionMap<ActionSlotView, ActionView> {
    return this.menuView.slotCollectionMap;
  }

  constructor(
    actionView: ActionMenuView,
    container: Container,
    onAction: (actions: ActionData[]) => void,
    onReset: () => void,
  ) {
    this.menuView = actionView;
    this.dragController = new DragController(container);
    this.dragController.onDragStart(this.onDragStart.bind(this));
    this.dragController.onDragMove(this.onDragMove.bind(this));
    this.dragController.onDragEnd(this.onDragEnd.bind(this));
    this.menuView.actionButton.on('pointerup', this.onAction.bind(this));
    this.action = onAction;
    this.onReset = onReset;
  }

  refresh(actions: ActionData[], targetCount: number) {
    actions.sort(() => Math.random() - 0.5);
    this.actionMap.clear();
    this.dragController.clear();

    let i = 0;
    for (var actionView of this.menuView.refresh(actions, targetCount)) {
      this.dragController.add(actionView);
      this.actionMap.set(actionView, actions[i++]);
    }
  }

  resetActions() {
    var i = 0;
    for (var action of this.slotCollectionMap.getElements()) {
      const sourceSlot = this.slotCollectionMap.getSlot('source', i);

      action.moveTo(sourceSlot.slot.rect);
      this.slotCollectionMap.setElementSlot(sourceSlot, action);
      i++;
    }
  }

  public resetActioning() {
    this.menuView.actionButton.isActioning = this.isActioning = false;
    this.blockActionViews(false);
  }

  public blockActionViews(block: boolean) {
    this.interactionEnabled = !block;
    this.actionMap.forEach((value, key) => {
      key.interactive = !block;
      if (!block) key.focus(false);
    });
    this.menuView.sortChildren();
    this.menuView.hideSourceSlots(block);
  }
  
  public disableActionButton(disable: boolean) {
    this.menuView.actionButton.Disabled = disable;
  }

  public focusAction(index: number) {
    let slots = this.slotCollectionMap.getSlots('target').filter(slot => slot.element);

    if (index > 0) slots[index - 1].element!.focus(false);
    slots[index].element!.focus(true);

    this.menuView.sortChildren();
  }

  onAction(e: FederatedPointerEvent) {
    const userSelection = Array.from(
      this.slotCollectionMap
        .getSlots('target')
        .filter(slot => slot.element)
        .map(slot => this.actionMap.get(slot.element!)!),
    );

    if (userSelection.length < 1) return;

    this.isActioning = !this.isActioning;
    this.menuView.actionButton.isActioning = this.isActioning;
    this.blockActionViews(this.isActioning);

    if (!this.isActioning) {
      this.onReset();
      return;
    }

    var i = 0;
    var elements = 0;

    for (var slot of this.slotCollectionMap.getSlots('target')) {
      if (slot.element) {
        if (elements < i) {
          var newSlot = this.slotCollectionMap.getSlot('target', elements);
          slot.element.moveTo(newSlot.slot.rect);
          this.slotCollectionMap.setElementSlot(newSlot, slot.element);
        }
        elements++;
      }
      i++;
    }

    if (this.action) {
      if (userSelection.length > 0) this.action(userSelection);
    }
  }

  onDragStart(action: ActionView) {
    action.zIndex = 10;
    this.menuView.sortChildren();
    gsap.to(action.scale, {
      duration: 0.2,
      ease: 'back.out',
      x: 0.8,
      y: 0.8,
    });
    Assets.get('dragStartSound').play();
  }
  onDragMove(action: ActionView) {
    // TODO
  }
  onDragEnd(action: ActionView) {
    action.zIndex = 0;

    let current = this.menuView.slotCollectionMap.getElementSlot(action)!;
    let target = this.getIntersectingTargetSlot(action);

    if (target) {
      if (target.element) {
        target.element.moveTo(current.slot.rect);
        this.menuView.slotCollectionMap.setElementSlot(current, target.element);
      }
    }

    let to = target ? target : current.tag == 'source' ? current : this.getEmptySourceSlot(action);

    action.moveTo(to.slot.rect);
    sound.play('insertSound');
    this.menuView.slotCollectionMap.setElementSlot(to, action);
    if (to != current)
      this.disableActionButton(
        this.slotCollectionMap.getSlots('target').filter(slot => slot.element).length <= 0,
      );
  }

  private getIntersectingTargetSlot(
    action: ActionView,
  ): Slot<ActionSlotView, ActionView> | undefined {
    if (!this.slotCollectionMap.getElementSlot(action)) throw new Error('action not added');

    let best: Slot<ActionSlotView, ActionView> | undefined = undefined;
    let actionRect = action.getBounds();

    for (var slot of this.slotCollectionMap.getSlots('target')) {
      var area = rectangleIntersectionArea(actionRect, slot.slot.rect);
      if (area > 0) {
        best = best
          ? area > rectangleIntersectionArea(actionRect, best.slot.rect)
            ? slot
            : best
          : slot;
      }
    }
    return best;
  }

  private getEmptySourceSlot(action: ActionView): Slot<ActionSlotView, ActionView> {
    if (!this.slotCollectionMap.getElementSlot(action)) throw new Error('action not added');

    let best: Slot<ActionSlotView, ActionView> | undefined = undefined;
    let actionRect = action.getBounds();

    let slots = this.slotCollectionMap.getSlots('source').filter(s => !s.element);
    for (var slot of slots) {
      var area = rectangleIntersectionArea(actionRect, slot.slot.rect);
      if (area > 0) {
        best = best
          ? area > rectangleIntersectionArea(actionRect, best.slot.rect)
            ? slot
            : best
          : slot;
      }
    }
    if (!best) best = slots[0];
    return best;
  }

  clear() {
    this.dragController.clear();
  }

  dispose() {
    this.dragController?.dispose();
  }
}
