import gsap from 'gsap';
import { VehicleView, VehicleViewProps } from './vehicle-view';
import { assets } from '../../core/asset-utility';
import { Assets, Container, Sprite, Texture } from 'pixi.js';
import { Emitter } from '@pixi/particle-emitter';
import { wait } from '../../core/async/awaitable';
import { shakePosition } from '../../core/animation-utility';
import { directionFromAngleClockwise } from '@puzzles/core/math/geometry';
import { Vector2 } from '@puzzles/core/math/vector';
import { VehicleProgressView } from './vehicle-progress-view';
import { sound } from '@pixi/sound';

import rocketSprite from '../assets/vehicle/rocket-default.png?url';
import lightSprite from '../assets/vehicle/light.png?url';
import circleSprite from '../assets/vehicle/circle.png?url';
import rocketEmitter from '../assets/vehicle/emitter-rocket.json?url';
import shakeRocketEmitter from '../assets/vehicle/emitter-shake-rocket.json?url';
import launchRocketEmitter from '../assets/vehicle/emitter-launch-rocket.json?url';
import launchSound from '../assets/sound/regular_rocket_launch_001.mp3?url';
import launchFailSound from '../assets/sound/regular_rocket_launch_fail_001.mp3?url';

@assets('RocketView', {
  rocketSprite: rocketSprite,
  lightSprite: lightSprite,
  circleSprite: circleSprite,
  rocketEmitter: rocketEmitter,
  shakeRocketEmitter: shakeRocketEmitter,
  launchRocketEmitter: launchRocketEmitter,
  launchSound: launchSound,
  launchFailSound: launchFailSound,
})

export class RocketView extends VehicleView {
  private rocketEmitter: Emitter;
  private shakeEmitter: Emitter;
  private launchEmitter: Emitter;
  private windowMask: Sprite;

  constructor(data: VehicleViewProps) {
    super(data);

    this.rocketEmitter = new Emitter(this, Assets.get('rocketEmitter'));
    this.shakeEmitter = new Emitter(this, Assets.get('shakeRocketEmitter'));

    this.characterContainer.scale.set(0.56);

    let tex = Texture.from(rocketSprite);
    let sprite = new Sprite(tex);
    sprite.scale.set(0.5);
    sprite.anchor.set(0.5);
    this.vehicleContainer.addChild(sprite);

    let light = new Sprite(Texture.from(lightSprite));
    light.scale.set(0.5);
    light.anchor.set(0.5);
    light.position.set(-1, -10.5);
    this.vehicleContainer.addChild(light);

    let highlight = new Sprite(Texture.from(circleSprite));
    highlight.zIndex = 9;
    highlight.scale.set(0.13);
    highlight.anchor.set(0.5, 1);
    highlight.position.set(-7, -16);
    this.vehicleContainer.addChild(highlight);

    let emitterC = new Container();
    emitterC.zIndex = 99;
    this.launchEmitter = new Emitter(emitterC, Assets.get('launchRocketEmitter'));
    this.addChild(emitterC);

    this.windowMask = new Sprite(Texture.from(circleSprite));
    this.windowMask.scale.set(0.5);
    this.windowMask.anchor.set(0.5);
    this.windowMask.position.set(-1, -10.5);

    this.vehicleProgress = new VehicleProgressView(data, this.vehicleContainer, tex, new Vector2(100, 110));
    this.vehicleContainer.addChild(this.vehicleProgress);

    this.sortChildren();
    this.pivot.y = 53;
  }

  public async launch(success: boolean) {
    await wait(250);

    this.vehicleContainer.addChild(this.windowMask, this.characterContainer);
    this.vehicleContainer.sortChildren();
    this.characterContainer.mask = this.windowMask;

    let duck = this.targetDuck.DuckContainer;
    this.characterContainer.addChild(duck);
    duck.position.set(0);
    this.characterContainer.angle = -10;
    this.characterContainer.position.set(0, duck.height);

    await gsap.to(this.characterContainer, {
      y: 18,
      duration: 0.3,
      ease: 'back.out',
    });
    await wait(100);

    let dir = directionFromAngleClockwise(0);
    let pos = this.vehicleContainer.position;

    sound.play(success? 'launchSound' : 'launchFailSound');
    this.shakeEmitter.updateOwnerPos(
      pos.x + dir.x * this.pivot.y,
      pos.y + dir.y * this.pivot.y,
    );
    this.shakeEmitter.emit = true;
    await shakePosition(this.vehicleContainer, 1, 1, 5, 45, 0.7, 'in');
    this.shakeEmitter.emit = false;

    this.launchEmitter.updateOwnerPos(
      pos.x + dir.x * 15,
      pos.x + dir.y * 15,
    );
    this.launchEmitter.emit = true;

    if (success) {
      this.rocketEmitter.emit = true;
      gsap.to(this.vehicleContainer.scale, {
        x: 0.8,
        y: 1.2,
        duration: 0.15,
        yoyo: true,
        repeat: 1,
      });
      await gsap.to(this.vehicleContainer, {
        y: this.vehicleContainer.y - 1000,
        duration: 1.3,
        ease: 'power1.in',
        onUpdate: () => {
          this.rocketEmitter.updateOwnerPos(
            this.vehicleContainer.x + dir.x * 15,
            this.vehicleContainer.y + dir.y * 15,
          );
        },
      });

      this.rocketEmitter.emit = false;
    } else {
      gsap.to(this.vehicleContainer.scale, {
        x: 0.8,
        y: 1.2,
        duration: 0.15,
        yoyo: true,
        repeat: 1,
      });
      await gsap.to(this.vehicleContainer, { y: '-=110', duration: 0.7, ease: 'power2.out' });
      gsap
        .to(this.vehicleContainer, { angle: -90, duration: 0.25, ease: 'back.out(1.7)' })
        .delay(0.1);
      gsap.to(this.vehicleContainer, { x: -20, duration: 0.3, ease: 'power3.out' }).delay(0.15);
      await gsap.to(this.vehicleContainer, { y: 15, duration: 0.5, ease: 'bounce.out' });
    }
  }
}
