import gsap from 'gsap';
import { wait } from '../core/async/awaitable';
import { CancellationTokenSource } from '../core/async/cancellations';
import { Disposable } from '../core/interfaces/disposable';
import { FractionData } from '../core/math/fraction';
import { clamp, clamp01 } from '../core/math/utility';
import { Vec2, distance } from '../core/math/vector';
import { CharacterView } from './character-view';
import { DashedArcView } from './views/dashed-arc-view';
import { CircleRulerView, LineRulerView, IRulerView } from './ruler-view';
import { DashedBezierCurveView } from './views/dashed-bezier-curve-view';
import { ActionData } from './puzzle-data';
import { Action } from './puzzle-state';
import { DashedFractionView } from './views/dashed-fraction-view';
import { arcLength } from '@puzzles/core/math/geometry';

type Animation = { from: number; current: number; to: number; height: number };

export interface ICharacterRulerViewController extends Disposable {
  ruler: IRulerView;
  character: CharacterView;
  onUpdate?: (pos: Vec2, rot: number) => void;

  moveFromTo(from: number | FractionData, to: number | FractionData, action: ActionData | undefined): Promise<boolean>;

  resize(): void;
  hide(): void;
}

export type CharacterRulerViewProps = {
  showFraction: boolean;
  positiveColor: number;
  negativeColor: number;
};

const defaultCharacterRulerViewProps: CharacterRulerViewProps = {
  showFraction: false,
  positiveColor: 0x1d9ce4,
  negativeColor: 0xe83189,
};

export abstract class CharacterRulerViewController implements ICharacterRulerViewController {
  ruler: IRulerView;
  character!: CharacterView;
  protected animation?: Animation;

  protected timeline?: gsap.core.Timeline;
  protected props: CharacterRulerViewProps;
  protected currentAction: ActionData | undefined;

  public onUpdate?: ( pos: Vec2, rot: number ) => void;

  abstract moveFromTo(
    from: number | FractionData,
    to: number | FractionData,
    action: ActionData | undefined
  ): Promise<boolean>;
  abstract dispose(): void;
  abstract resize(): void;

  constructor(
    ruler: IRulerView,
    character: CharacterView,
    props: Partial<CharacterRulerViewProps>
  ) {
    this.ruler = ruler;
    this.character = character;
    this.props = { ...defaultCharacterRulerViewProps, ...props };
  }

  protected moveCancellation?: CancellationTokenSource;

  protected resetMove() {
    this.timeline?.kill();
    this.moveCancellation?.cancel();
    this.moveCancellation = new CancellationTokenSource();
    return this.moveCancellation.token;
  }

  protected setCharacterDirection(from: number, to: number) {
    let scale = Math.abs(this.character.scale.x);
    this.character.scale.x = from > to ? -scale : scale;
  }

  abstract hide(): void;
}

type MoveViewData<P, T> = {
  path: P | undefined;
  text: T | undefined;
  action: Action | undefined;
  animation: Animation;
};

export class LineRulerViewController extends CharacterRulerViewController {
  private line: LineRulerView;
  private moves: MoveViewData<DashedBezierCurveView, DashedFractionView>[] = [];
  private isMoveBreak = false;

  constructor(
    ruler: LineRulerView,
    character: CharacterView,
    props: Partial<CharacterRulerViewProps> = {}
  ) {
    super(ruler, character, props);

    this.line = ruler;
  }

  resize(): void {
    this.moves.forEach((move) => this.refreshMoveView(move));
  }

  private createCurve(from: number, to: number): DashedBezierCurveView {
    const [fromPos] = this.ruler.getTransform(from);
    const [toPos] = this.ruler.getTransform(to);
    const curve = new DashedBezierCurveView({
      distance: toPos.x - fromPos.x,
      height: this.line.options.skyHeight,
      fillColor: from < to ? this.props.positiveColor : this.props.negativeColor,
    });
    curve.position.copyFrom(fromPos);
    return curve;
  }

  refreshMoveView(moveView: MoveViewData<DashedBezierCurveView, DashedFractionView>) {
    const { animation, action: actionData, path, text } = moveView;
    path?.destroy();
    text?.destroy();
    moveView.path = this.createCurve(animation.from, animation.to);
    moveView.text = actionData ? new DashedFractionView(actionData) : undefined;
    this.line.parent.addChild(moveView.path);
    this.line.parent.setChildIndex(moveView.path, this.line.parent.getChildIndex(this.line));
    if (moveView.text) {
      this.line.parent.addChild(moveView.text);
      moveView.text.position = moveView.path.toGlobal(moveView.path.getPoint(0.5));
      moveView.text.position.y -= moveView.text.scale.x * 50;
    }
    if (!this.moves.includes(moveView)) this.moves.push(moveView);

    this.update(moveView);
    return moveView;
  }

  protected resetMove() {
    this.isMoveBreak = false;
    return super.resetMove();
  }

  async moveFromTo(
    from: number | FractionData,
    to: number | FractionData,
    action: ActionData | undefined
  ): Promise<boolean> {
    const fromFloat = typeof from === 'number' ? from : from.numerator / from.denominator;
    const toFloat = typeof to === 'number' ? to : to.numerator / to.denominator;
    const fromTransform = this.ruler.getTransform(fromFloat)[0];
    const toTransform = this.ruler.getTransform(toFloat)[0];
    const d = distance(fromTransform, toTransform);
    const token = this.resetMove();
    const moveView = this.refreshMoveView({
      path: undefined,
      text: undefined,
      action: action,
      animation: { from: fromFloat, current: fromFloat, to: toFloat, height: 0 },
    });

    this.moves.push(moveView);

    moveView.path!.draw(0);

    this.setCharacterDirection(fromFloat, toFloat);
    this.character.jump(token);

    this.timeline = gsap.timeline().to(moveView.animation, {
      current: toFloat,
      duration: clamp(Math.abs(d) * (1 / 750), 0.5, 0.9),
      ease: 'power1.inOut',
      onUpdate: this.update.bind(this),
      onUpdateParams: [moveView],
      delay: 0.15,
    });
    await this.timeline;

    if (!this.isMoveBreak) moveView.text?.show();

    token.cancel();
    this.moveCancellation = undefined;

    return !this.isMoveBreak;
  }

  update(move: MoveViewData<DashedBezierCurveView, DashedFractionView>) {
    const values = move.animation;
    const [p, r] = this.ruler.getTransform(values.current);
    const t = clamp01((values.current - values.from) / (values.to - values.from));

    this.character.position.set(p.x, Math.min(p.y, p.y + move.path!.getPoint(t).y));
    move.path!.draw(t);

    if(this.onUpdate) this.onUpdate(p, r);

    if (!this.ruler.isInBounds(values.current, 0.1)) {
      this.isMoveBreak = true;
      gsap.killTweensOf(values);
      this.moveCancellation?.cancel();
    }
  }

  dispose(): void {
    this.resetMove();
    this.moves.forEach((e) => {
      e.path?.destroy();
      e.text?.destroy();
    });
  }

  hide(): void {
    if (this.moves.length === 0) return;
    let move = this.moves.at(-1)!;
    gsap.to(move.path!, {
      alpha: 0,
      duration: 0.3,
      onComplete: () => {
        move.path!.destroy();
        this.moves.splice(this.moves.indexOf(move), 1);
      },
    });
  }
}

export class CircleRulerViewController extends CharacterRulerViewController {
  private circleRuler: CircleRulerView;
  private moveView?: MoveViewData<DashedArcView, DashedFractionView>;

  constructor(
    circle: CircleRulerView,
    character: CharacterView,
    props: Partial<CharacterRulerViewProps> = {}
  ) {
    super(circle, character, props);

    this.circleRuler = circle;
  }

  refreshMoveView(from: number, to: number, action: ActionData | undefined) {
    this.moveView?.path?.destroy();
    this.moveView?.text?.destroy();
    this.moveView = this.moveView || {
      path: undefined,
      text: undefined,
      action: action,
      animation: { from: from, current: from, to: to, height: 0 },
    };

    const fillColor = from < to ? this.props.positiveColor : this.props.negativeColor;
    this.moveView.path = new DashedArcView({ fillColor: fillColor });
    this.moveView.text = action ? new DashedFractionView(action) : undefined;
    this.moveView.animation = { from: from, current: from, to: to, height: 0 };

    const path = this.moveView.path!;
    const parent = this.circleRuler.parent;

    path.position.copyFrom(this.circleRuler.position);
    parent.addChild(path);
    parent.setChildIndex(path, parent.getChildIndex(this.circleRuler));

    this.update(this.moveView);

    return this.moveView;
  }

  resize(): void {
    if (this.moveView) {
      const { animation, action: actionData } = this.moveView;
      this.moveView = this.refreshMoveView(animation.from, animation.to, actionData);
    }
  }

  dispose(): void {
    this.resetMove();
    this.moveView?.path?.destroy();
    this.moveView?.text?.destroy();
  }

  update(moveView: MoveViewData<DashedArcView, DashedFractionView>) {
    const { animation, path } = moveView;
    const ruler = this.circleRuler.ruler;
    const { from, to, current } = animation;
    const angleFrom = ruler.angle(from);
    const angleCurrent = ruler.angle(current);
    const anticlockwise = to < from;
    const radius = ruler.radius + animation.height - this.circleRuler.options.borderWidth;
    const [p, r] = this.circleRuler.getTransform(animation.current, {
      x: 0,
      y: animation.height,
    });

    this.character.rotation = r;
    this.character.position.set(p.x, p.y);

    let [p2, r2] = this.circleRuler.getTransform(animation.current);
    if(this.onUpdate) this.onUpdate(p2, r2);

    path!.draw(radius, angleFrom - Math.PI / 2, angleCurrent - Math.PI / 2, anticlockwise);
  }

  async moveFromTo(
    from: number | FractionData,
    to: number | FractionData,
    action: ActionData | undefined
  ): Promise<boolean> {
    const fromValue = typeof from === 'number' ? from : from.numerator / from.denominator;
    const toValue = typeof to === 'number' ? to : to.numerator / to.denominator;
    const token = this.resetMove();
    const angleFrom = Math.PI * 2 * fromValue;
    const angleTo = Math.PI * 2 * toValue;
    const ruler = this.circleRuler.ruler;
    const skyHeight = this.circleRuler.options.skyHeight;
    const dist = arcLength(ruler.radius + skyHeight, Math.abs(angleTo - angleFrom));

    this.refreshMoveView(fromValue, toValue, action);
    this.setCharacterDirection(fromValue, toValue);
    this.character.jump(token);

    await wait(100);

    this.timeline?.kill();
    this.timeline = gsap
      .timeline()
      .to(this.moveView!.animation, {
        current: fromValue,
        height: skyHeight,
        duration: 0.1,
        onUpdate: this.update.bind(this),
        onUpdateParams: [this.moveView],
      })
      .to(this.moveView!.animation, {
        current: toValue,
        height: skyHeight,
        duration: Math.max(Math.abs(dist) * (1 / 550), 0.5),
        onUpdate: this.update.bind(this),
        onUpdateParams: [this.moveView],
        ease: 'none',
      })
      .to(this.moveView!.animation, {
        duration: 0.1,
        current: toValue,
        height: 0,
        onUpdate: this.update.bind(this),
        onUpdateParams: [this.moveView],
      });

    await this.timeline;

    if (this.moveView?.text) {
      this.moveView?.text?.show();
    }

    token.cancel();
    this.moveCancellation = undefined;

    return true;
  }

  hide(): void {
    if (!this.moveView) return;

    const arc = this.moveView.path!;
    gsap.to(arc, { alpha: 0, duration: 0.3 });
  }
}
