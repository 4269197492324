import { Container, Graphics } from 'pixi.js';
import { Rect } from '../core/math/rectangle';
import { Rescalable } from '../core/pixi/display-scaler';
import { defaultRectStyleProps, RectStyleProps } from './action-view-props';

export class ActionSlotView extends Container implements Rescalable {
  rect: Rect = { x: 0, y: 0, width: 100, height: 70 };
  private graphics: Graphics;
  private props: RectStyleProps;
  originalProps: RectStyleProps;

  constructor(props: Partial<RectStyleProps>) {
    super();
    this.graphics = new Graphics();
    this.addChild(this.graphics);
    this.props = { ...defaultRectStyleProps, ...props };
    this.originalProps = { ...this.props };
  }
  rescale(scaleFactor: number): void {
    this.props = { ...this.originalProps };
    this.props.lineWidth *= scaleFactor;
    this.props.radius *= scaleFactor;
  }

  resize(rect: Rect = this.rect) {
    this.rect = rect;
    this.position.set(rect.x + rect.width * 0.5, rect.y + rect.height * 0.5);
    this.draw(rect, this.props);
  }
  draw(rect: Rect, props: RectStyleProps) {
    const centered = {
      x: -rect.width / 2,
      y: -rect.height / 2,
      width: rect.width,
      height: rect.height,
    };

    this.graphics.clear();
    this.graphics.lineStyle({ width: props.lineWidth, color: props.lineColor });
    this.graphics.beginFill(props.fillColor);
    this.graphics.drawRoundedRect(
      centered.x,
      centered.y,
      centered.width,
      centered.height,
      props.radius,
    );
  }
}
