import gsap from 'gsap';
import { Container, Graphics, Rectangle, TextStyleFontWeight } from 'pixi.js';
import { FractionData } from '../core/math/fraction';
import { toRadians, twicePI } from '../core/math/utility';
import { add, fromTo, scale, normalize, perpendicular, subtract, Vec2 } from '../core/math/vector';
import { CircleRuler, enumerateRulerMarks, enumerateRulerMarksFromTo, LineRuler } from './ruler';
import { Rect } from '../core/math/rectangle';
import { FractionView } from './views/fraction-view';
import { Rescalable } from '../core/pixi/display-scaler';
import { colorLerp } from '@puzzles/core/math/color';
import { wait } from '@puzzles/core/async/awaitable';
import { DashedArcView } from './views/dashed-arc-view';
import { RulerDisplayLevel, RulerDisplayMode } from './puzzle-data';

export interface IRulerView {
  launch(animate: boolean): Promise<void>;
  getTransform(fraction: number | FractionData): [Vec2, number];
  getFraction(position: Vec2): number;
  resize(screen: Rect): void;
  isInBounds(value: number, offset: number): boolean;
}

export interface IRulerPixiView extends IRulerView, Container {}

type TextViewOptions = {
  fontFamily: string;
  fontWeight: TextStyleFontWeight;
};

const defaultTextViewOptions: TextViewOptions = {
  fontFamily: 'Montserrat',
  fontWeight: '700',
};

type RulerOptions = {
  fractions: number;
};

type RulerViewDisplayOptions = {
  fillColor: number;
  borderColor: number;
  borderWidth: number;
  markerWidth: number;
  markerHeight: number;
  markerUnitScale: number;
  markerRoundRadius: number;
  floorHeight: number;
  skyHeight: number;
  gizmos: boolean;
  displayLevel: RulerDisplayLevel;
};

const defaultRulerDisplayOptions: RulerViewDisplayOptions = {
  fillColor: 0xffffff,
  borderColor: 0x0a0878,
  borderWidth: 6,
  markerWidth: 5,
  markerHeight: 22,
  markerUnitScale: 1.5,
  markerRoundRadius: 5,
  floorHeight: 8,
  skyHeight: 30,
  gizmos: false,
  displayLevel: 'default',
};

//#region CirlceRuler
type CircleRulerViewMandatoryOptions = {
  radius: number;
  angleOffset: number;
};
type CircleRulerViewOptions = RulerOptions & CircleRulerViewMandatoryOptions & RulerViewDisplayOptions;
type CircleRulerViewConstructorOptions = RulerOptions &
  CircleRulerViewMandatoryOptions &
  Partial<RulerViewDisplayOptions>;

export class CircleRulerView extends Container implements IRulerPixiView, Rescalable {
  ruler: CircleRuler;
  background: Graphics = new Graphics();
  markers: Graphics[] = [];
  foreground: Graphics = new Graphics();
  debug: Graphics = new Graphics();
  options: CircleRulerViewOptions;
  originalOptions: CircleRulerViewOptions;

  constructor(options: CircleRulerViewConstructorOptions = { fractions: 4, radius: 100, angleOffset: 0 }) {
    super();
    this.options = { ...defaultRulerDisplayOptions, 
      fractions: options.fractions,
      radius: options.radius,
      angleOffset: options.angleOffset,
      displayLevel: options.displayLevel ?? 'default',
    };
    this.originalOptions = { ...this.options };

    this.ruler = new CircleRuler(
      this.options.fractions,
      { x: 0, y: 0 },
      toRadians(this.options.angleOffset),
      this.options.radius
    );
    this.addChild(this.background);

    for (var _marker of this.enumerateRulerMarks()) {
      const g = new Graphics();
      g.scale.y = 0;
      this.markers.push(g);
      this.addChild(g);
    }

    this.addChild(this.foreground);
    this.addChild(this.debug);
  }

  async launch(animate: boolean): Promise<void> {
    if(this.options.displayLevel === 'empty') return;

    if (!animate) {
      for (var marker of this.markers) {
        marker.scale.y = 1;
      }
      return;
    }

    const offset = this.ruler.angleOffset;
    const addAngle = 360 / this.ruler.fractions;
    const startAngle = -90 + offset;

    this.markers.forEach((marker) => {
      gsap.to(marker.scale, { y: 1, duration: 0.2 });
    });
    await wait(300);

    for (let i = 0; i < this.markers.length - 1; i++) {
      let arc = new DashedArcView({
        fillColor: 0xffbe18,
        dash: 12,
        lineWidth: 0,
        lineColor: 0xffffff,
      });
      let angleStart = (startAngle + addAngle * i) * (Math.PI / 180);
      let angleEnd = (startAngle + addAngle * (i + 1)) * (Math.PI / 180);
      arc.draw(this.ruler.radius - this.options.floorHeight, angleStart, angleEnd);
      this.addChild(arc);

      await gsap.fromTo(arc, { alpha: 0 }, { alpha: 1, duration: 0.2 });
      gsap.to(arc, { alpha: 0, duration: 0.4, onComplete: () => arc.destroy() });
    }
  }

  isInBounds(value: number, offset: number): boolean {
    return true;
  }

  rescale(scaleFactor: number): void {
    this.options = { ...this.originalOptions };
    this.options.borderWidth *= scaleFactor;
    this.options.markerWidth *= scaleFactor;
    this.options.markerRoundRadius *= scaleFactor;
    this.options.floorHeight *= scaleFactor;
    this.options.skyHeight *= scaleFactor;
  }

  resize(container: Rect): void {
    const { width, height } = container;
    this.options.radius = this.ruler.radius = Math.min(width, height) / 2 - Math.min(width, height) * 0.13;
    this.x = container.x + container.width / 2;
    this.y = container.y + container.height / 2;
    this.draw();
  }

  getFraction(position: Vec2): number {
    return this.ruler.getFraction(this.invertYAxis(this.toLocal(position)));
  }

  getTransformFromAngle(angle: number, offset: Vec2 = { x: 0, y: 0 }): [Vec2, number] {
    return this.getTransform(angle / twicePI, offset);
  }

  getTransform(fraction: number | FractionData, offset: Vec2 = { x: 0, y: 0 }): [Vec2, number] {
    const position = this.getPosition(fraction, {
      x: offset.x + 0,
      y: offset.y - this.options.floorHeight / 2,
    });
    const rotation = this.getRotation(position);
    return [this.toGlobal(position), rotation];
  }

  getPosition(fraction: number | FractionData, offset: Vec2 = { x: 0, y: 0 }): Vec2 {
    return this.invertYAxis(this.ruler.getPosition(fraction, offset));
  }

  getRotation(position: Vec2): number {
    return this.ruler.angleFromPosition({ x: position.x, y: -position.y });
  }

  private invertYAxis(v: Vec2): Vec2 {
    v.y = -v.y;
    return v;
  }

  enumerateRulerMarks(offset: Vec2 = { x: 0, y: 0 }): Vec2[] {
    return [...enumerateRulerMarks(this.ruler, 1, offset)].map(this.invertYAxis);
  }

  circleTangent(origin: Vec2, point: Vec2, clockwise: boolean = true): Vec2 {
    var result = normalize(perpendicular(subtract(point, origin)));
    if (!clockwise) {
      result.x *= -1;
      result.y *= -1;
    }
    return result;
  }

  draw() {
    this.background.clear();
    this.foreground.clear();

    this.background.lineStyle({
      width: this.options.borderWidth * 2 + this.options.floorHeight,
      color: this.options.borderColor,
      alignment: 0,
    });
    this.background.drawCircle(0, 0, this.ruler.radius);
    this.background.endFill();

    let i = 0;
    const origin = { x: 0, y: 0 };
    const direction = { x: 0, y: 0 };
    const markers = this.enumerateRulerMarks({
      x: 0,
      y: -this.options.floorHeight,
    });

    for (var marker of markers) {
      this.background.lineStyle({
        width: this.options.borderWidth,
        color: this.options.borderColor,
        alignment: 0,
      });

      let markerGraphics = this.markers[i];
      let tangent = this.circleTangent(origin, marker, false);

      scale(tangent, this.options.markerWidth / 2, tangent);

      const markerOrigin = add(marker, tangent);

      markerGraphics.clear();
      markerGraphics.x = markerOrigin.x;
      markerGraphics.y = markerOrigin.y;
      markerGraphics.beginFill(this.options.fillColor);
      markerGraphics.drawRoundedRect(
        0,
        0,
        this.options.markerWidth,
        this.ruler.radius - this.options.markerRoundRadius,
        this.options.markerRoundRadius
      );
      markerGraphics.endFill();
      markerGraphics.rotation = this.getRotation(fromTo(origin, marker, direction));

      i++;
    }

    this.setChildIndex(this.foreground, this.children.length - 1);
    this.foreground.lineStyle({
      width: this.options.borderWidth,
      color: this.options.fillColor,
      alignment: 0,
    });
    this.foreground.drawCircle(0, 0, this.ruler.radius - this.options.floorHeight);
    this.foreground.endFill();

    this.setChildIndex(this.debug, this.children.length - 1);

    if (this.options.gizmos) this.drawGizmos(this.debug);
  }

  drawGizmos(graphics: Graphics) {
    const markers = this.enumerateRulerMarks();
    graphics.lineStyle({
      width: 1,
      color: 0xff0000,
      alignment: 0,
    });

    markers.forEach((m, i) => {
      graphics.lineStyle(i == markers.length - 1 ? 0 : 1, colorLerp(0xff0000, 0x00ff00, i / (markers.length - 2)));
      graphics.drawCircle(m.x, m.y, 4);
      graphics.moveTo(0, 0);
      graphics.lineTo(m.x, m.y);
    });
  }
}
//#endregion

//#region LineRuler
type LineRulerDisplayOptions = RulerViewDisplayOptions &
  TextViewOptions & {
    units: number;
    pixelsPerUnit: number;
    textRadius: number;
    textScaling: number;
    textSpacing: number;
    characterMarkerColor: number;
    text: RulerDisplayMode;
  };

const defaultHorizontalRulerDisplayOptions: LineRulerDisplayOptions = {
  ...defaultRulerDisplayOptions,
  ...defaultTextViewOptions,
  units: 3,
  pixelsPerUnit: 100,
  textRadius: 25,
  textScaling: 1.1,
  textSpacing: 10,
  skyHeight: 80,
  characterMarkerColor: 0xffbe18,
  text: 'fraction',
};

type LineRulerViewConstructorOptions = RulerOptions & {
  type?: 'line' | 'segment';
  from?: number;
  to?: number;
} & Partial<LineRulerDisplayOptions>;

type LineRulerViewOptions = RulerOptions & {
  type: 'line' | 'segment';
  from: number;
  to: number;
} & LineRulerDisplayOptions;

export class LineRulerView extends Container implements IRulerPixiView, Rescalable {
  public ruler: LineRuler;
  public options: LineRulerViewOptions;
  private originalOptions: LineRulerViewOptions;

  private rulerBackGraphics: Graphics = new Graphics();
  private rulerFrontGraphics: Graphics = new Graphics();
  private debugGraphics: Graphics = new Graphics();
  private rulerMarkers: Graphics[] = [];
  private texts: FractionView[] = [];
  private centerMarkerBackground: Graphics = new Graphics();
  // private centerFractions: FractionView[] = [];

  private entityFractionDatas: FractionData[] = [];
  private entityMarkers: Graphics[] = [];
  private entityTexts: FractionView[] = [];

  private pointerMarkers: Map<number, Graphics> = new Map();
  private offsetX: number = 0;

  public get from(): number {
    return (this.options.type == 'line' ? this.options.from - 1 : this.options.from) * this.options.fractions;
  }

  public get to(): number {
    return (this.options.type == 'line' ? this.options.to + 1 : this.options.to) * this.options.fractions;
  }

  constructor(options: LineRulerViewConstructorOptions = { fractions: 4 }) {
    super();

    this.options = {
      ...defaultHorizontalRulerDisplayOptions,
      type: options.type ? options.type : 'line',
      fractions: options.fractions,
      from: options.from ?? 0,
      to: options.to ?? 1,
      displayLevel: options.displayLevel ?? 'default',
      text: options.text ?? 'fraction',
    };

    this.options.units = this.options.to - this.options.from;
    this.originalOptions = { ...this.options };
    this.ruler = new LineRuler(this.options.fractions, { x: 0, y: 0 }, { x: 1, y: 0 }, this.options.pixelsPerUnit);

    const markerLength = [...enumerateRulerMarksFromTo(this.ruler, this.from, this.to)].length;

    this.centerMarkerBackground.scale.y = 0;
    this.addChild(this.centerMarkerBackground);

    // remove for now, may show center fractions later
    // if(options.displayLevel == 'allParts') {    
    //   for (let i = 1; i < markerLength; i++) {
    //     const fraction = new FractionView(
    //       { numerator: 1, denominator: this.options.fractions },
    //       {
    //         fontFamily: this.options.fontFamily,
    //         fontWeight: this.options.fontWeight,
    //         fontSize: 20,
    //         fill: this.options.fillColor,
    //         align: 'justify',
    //       },
    //       { showPositiveSymbol: false }
    //     );
    //     fraction.scale.set(0);
    //     this.addChild(fraction);
    //     this.centerFractions.push(fraction);
    //   }
    // }
    
    this.addChild(this.rulerBackGraphics);

    for (let i = 0; i < markerLength; i++) {
      let val = i + (this.options.type == 'line' ? (this.options.from - 1) * this.options.fractions : 0);
      let isUnit = val % this.ruler.fractions == 0;

      let g = new Graphics();
      this.rulerMarkers.push(g);
      this.addChild(g);

      const fractionView = this.createFractionView(val, isUnit);
      this.texts.push(fractionView);

      if (!isUnit) {
        g.scale.y = 0;
        fractionView.scale.set(0);
      }
    }

    this.rulerFrontGraphics.zIndex = 9;
    this.addChild(this.rulerFrontGraphics);
    this.addChild(this.debugGraphics);

    this.sortableChildren = true;
    this.sortChildren();

    this.draw();
  }

  public addEntityFractions(fractions: FractionData[]){
    if(this.options.displayLevel != 'itemFractions') return;

    for (let fraction of fractions) {
      let isUnit = fraction.numerator % this.ruler.fractions == 0;
      if (isUnit) continue;

      this.entityFractionDatas.push(fraction);

      const g = new Graphics();
      this.entityMarkers.push(g);
      this.addChild(g);
      const fractionView = this.createFractionView(fraction.numerator, false);
      this.entityTexts.push(fractionView);

      g.scale.y = 0;
      fractionView.scale.set(0);
      this.sortChildren();
    }

    this.draw();
  }

  private createFractionView(val: number, isUnit: boolean): FractionView {
    let fractionView = new FractionView(
      { numerator: val, denominator: this.options.fractions },
      {
        fontFamily: this.options.fontFamily,
        fontWeight: this.options.fontWeight,
        fontSize: 20,
        fill: isUnit ? this.options.fillColor : this.options.borderColor,
        align: 'justify',
      },
      { showPositiveSymbol: false }
    );

    if (this.options.text == 'mixed') fractionView.setMode(isUnit ? 'decimal' : 'fraction');
    else fractionView.setMode(val == 0 ? 'decimal' : this.options.text);
    this.addChild(fractionView);

    return fractionView;
  }

  public async launch(animate: boolean): Promise<void> {
    let displayLevel = this.options.displayLevel;
    if(displayLevel === 'empty') return;
    
    if (displayLevel === 'itemFractions') {
      for(let i=0; i < this.entityMarkers.length; i++){
        let marker = this.entityMarkers[i];
        let text = this.entityTexts[i];

        if (animate) {
          gsap.to(marker.scale, { y: 1, duration: 0.4, ease: 'back.out(10)' });
          gsap.to(text.scale, { x: 1, y: 1, duration: 0.3, ease: 'back.out(2.5)' }).delay(0.3);
          await wait(30);
        } else {
          marker.scale.y = 1;
          text.scale.set(1);
        }
      }
      return;
    }

    for (let i = 0; i < this.rulerMarkers.length; i++) {
      if (i % this.ruler.fractions == 0) continue;

      let marker = this.rulerMarkers[i];
      let text = this.texts[i];
      if (animate) {
        gsap.to(marker.scale, { y: 1, duration: 0.4, ease: 'back.out(10)' });
        if (displayLevel === 'allFractions') {
          gsap.to(text.scale, { x: 1, y: 1, duration: 0.3, ease: 'back.out(2.5)' }).delay(0.3);
        }
        await wait(30);
      } else {
        marker.scale.y = 1;
        if (displayLevel === 'allFractions') {
          text.scale.set(1);
        }
      }
    }

    if (displayLevel === 'allParts') {
      if (animate) gsap.to(this.centerMarkerBackground.scale, { y: 1, duration: 0.2 });
      else this.centerMarkerBackground.scale.y = 1;

      // this.centerFractions.forEach((fraction) => {
      //   if (animate) gsap.to(fraction.scale, { x: 1, y: 1, duration: 0.3, ease: 'back.out(2.5)' }).delay(0.1);
      //   else fraction.scale.set(1);
      // });
    }
  }

  public addPointerMarker(fraction: number) {
    if (this.pointerMarkers.has(fraction)) return;
    let g = new Graphics();
    gsap.fromTo(g.scale, { y: 0 }, { y: 1, duration: 0.2, ease: 'back.out(1.7)' });
    this.addChild(g);
    this.pointerMarkers.set(fraction, g);

    this.draw();
  }

  rescale(scaleFactor: number): void {
    this.options = { ...this.originalOptions };
    this.options.borderWidth *= scaleFactor;
    this.options.markerWidth *= scaleFactor;
    this.options.markerHeight *= scaleFactor;
    this.options.markerRoundRadius *= scaleFactor;
    this.options.floorHeight *= scaleFactor;
    this.options.textRadius *= scaleFactor;
    this.options.textSpacing *= scaleFactor;
  }

  resize(container: Rect): void {
    let newPixelsPerUnit = container.width / this.options.units;
    this.ruler.pixelsPerUnit = newPixelsPerUnit;
    this.options.pixelsPerUnit = newPixelsPerUnit;
    this.draw();
    this.x = container.x - this.offsetX;
    this.y = container.y + container.height / 2;
  }

  getFraction(position: Vec2): number {
    return this.ruler.getFraction(this.toLocal(position));
  }
  getTransform(fraction: number | FractionData): [Vec2, number] {
    return [this.toGlobal(this.ruler.getPosition(fraction, { x: 0, y: -this.options.floorHeight })), 0];
  }

  private getMarkerRect(marker: Vec2, i: number, out?: Rectangle): Rectangle {
    out = out ?? new Rectangle();
    let isUnit = i % this.ruler.fractions == 0;
    out.x = marker.x - this.options.markerWidth / 2;
    out.y = marker.y - 1;
    out.width = this.options.markerWidth;
    out.height = this.options.markerHeight * (isUnit ? this.options.markerUnitScale : 1);

    return out;
  }

  draw(options = this.options) {
    const displayLevel = options.displayLevel;
    const numberLine = options.type == 'line';
    let markers = [...enumerateRulerMarksFromTo(this.ruler, this.from, this.to)];

    let first = markers[0];
    let last = markers[markers.length - 1];

    if (numberLine) this.offsetX = options.from * (markers[options.fractions].x - first.x);

    this.rulerBackGraphics.clear();
    this.debugGraphics.clear();

    if (options.gizmos) this.drawGizmos(this.debugGraphics);

    this.rulerBackGraphics.lineStyle({
      width: options.borderWidth,
      color: options.borderColor,
      alignment: 1,
    });

    this.rulerBackGraphics.beginFill(options.borderColor);
    this.rulerBackGraphics.drawRoundedRect(
      first.x - options.markerWidth / 2,
      first.y - options.floorHeight,
      last.x - first.x + options.markerWidth,
      options.floorHeight + 5,
      options.markerRoundRadius / 4
    );
    this.rulerBackGraphics.endFill();
    this.rulerBackGraphics.lineStyle({ width: 0 });

    let index = 0;
    let markerRect = new Rectangle();

    this.centerMarkerBackground.clear();
    // let centerFractionIndex = 0;
    let lastR: Rectangle | undefined;
    let centerMarkerHeigth = 0;

    function getFontSize(text: FractionView, isUnit: boolean) {
      let size = options.textRadius * options.textScaling;
      return size * ((text.DisplayMode === 'decimal'|| text.DisplayMode === 'fractionMixed') && isUnit ? 1 : 0.65);
    }

    if(displayLevel === 'itemFractions'){
      let marker ={ ...markers[1] };
      
      for(let name in this.entityFractionDatas){
        const fraction = this.entityFractionDatas[name];
        let index = this.entityFractionDatas.indexOf(fraction);

        marker.x = this.getTransform(fraction)[0].x - this.x;
        this.getMarkerRect(marker, 1, markerRect);
        let r = markerRect;

        const fractionView = this.entityTexts[index];
        fractionView.position.set(marker.x, r.height + options.textSpacing + options.textRadius);
        fractionView.setFontSize(getFontSize(fractionView, false));

        this.drawMarker(this.entityMarkers[index], r);
      }
    }

    for (var marker of markers) {
      let isUnit = index % this.ruler.fractions == 0;

      this.getMarkerRect(marker, index, markerRect);
      let r = markerRect;

      this.drawMarker(this.rulerMarkers[markers.indexOf(marker)], r);

      if (displayLevel === 'allParts') {
        if (index === 0) centerMarkerHeigth = r.height * 1.3;
        if (lastR != undefined) {
          let width = r.x - lastR.x - options.markerWidth;
          this.centerMarkerBackground.beginFill(options.borderColor, 0.4);
          this.centerMarkerBackground.drawRoundedRect(
            lastR.x + options.borderWidth,
            options.floorHeight,
            width,
            centerMarkerHeigth,
            options.markerRoundRadius
          );
          this.centerMarkerBackground.endFill();

          // const centerFraction = this.centerFractions[centerFractionIndex];
          // centerFraction.position.set(r.x - width / 2, options.floorHeight + centerMarkerHeigth / 2);
          // centerFraction.FontSize = getFontSize(centerFraction, false) * 0.9;
          // centerFractionIndex += 1;
        }
      }

      const textY = (displayLevel === 'allParts'? centerMarkerHeigth : r.height) + options.textSpacing + options.textRadius;
      const fractionView = this.texts[index];
      fractionView.position.set(marker.x, textY);
      fractionView.setFontSize( getFontSize(fractionView, isUnit));

      if (isUnit) {
        this.rulerBackGraphics.beginFill(options.borderColor);
        this.rulerBackGraphics.drawCircle(marker.x, textY * 1.02, options.textRadius);
        this.rulerBackGraphics.endFill();
      }

      lastR = markerRect.clone();
      index++;
    }

    this.rulerFrontGraphics.position.set(first.x, first.y - 1);
    this.rulerFrontGraphics.clear();
    this.rulerFrontGraphics.beginFill(options.fillColor);

    this.rulerFrontGraphics.drawRect(
      -markerRect.width / 2,
      0,
      last.x - first.x + markerRect.width,
      options.markerWidth * 1.1
    );
    this.rulerFrontGraphics.endFill();

    this.pointerMarkers.forEach((graphics, fraction) => {
      this.getMarkerRect(this.ruler.getPosition(fraction), this.ruler.fractions * fraction, markerRect);
      let r = markerRect!;
      graphics.clear();
      graphics.position.set(r.x, r.y);

      graphics.lineStyle({ width: options.borderWidth, color: options.borderColor, alignment: 1 });
      graphics.beginFill(options.characterMarkerColor);
      graphics.drawRoundedRect(0, 0, r.width, r.height, options.markerRoundRadius);
      graphics.endFill();
    });
  }

  private drawMarker(marker: Graphics, r: Rectangle, options = this.options) {
    marker.clear();
    marker.lineStyle({
      width: options.borderWidth,
      color: options.borderColor,
      alignment: 1,
    });
    marker.position.set(r.x, r.y);
    marker.beginFill(options.fillColor);
    marker.drawRoundedRect(0, r.height * 0.05, r.width, r.height * 0.95, options.markerRoundRadius);
    marker.endFill();
  }

  private drawGizmos(graphics: Graphics) {
    graphics.x = 0;
    let markers = [...enumerateRulerMarks(this.ruler, 5, { x: 0, y: 0 })];
    markers.forEach((m) => {
      graphics.lineStyle({ width: 1, color: 0xff0000 });
      graphics.drawCircle(m.x, m.y, 3);
    });
    graphics.lineStyle({ width: 1, color: 0xff0000 });
    graphics.moveTo(markers[0].x, markers[0].y);
    graphics.lineTo(markers[markers.length - 1].x, markers[markers.length - 1].y);

    markers = [...enumerateRulerMarks(this.ruler, 5, { x: 0, y: -this.options.floorHeight })];

    markers.forEach((m) => {
      graphics.lineStyle({ width: 1, color: 0x0000ff });
      graphics.drawCircle(m.x, m.y, 3);
    });

    return markers;
  }

  public isInBounds(value: number, offset: number) {
    return value <= this.options.to + offset && value >= this.options.from - offset;
  }
}
