import { CharacterSkinType } from './character-view';
import { RocketCityView } from './entityviews/rocket-city-view';
import { RocketOceanView } from './entityviews/rocket-ocean-view';
import { RocketSpaceView } from './entityviews/rocket-space-view';
import { RocketView } from './entityviews/rocket-view';
import { RocketMineView } from './entityviews/rocket-mine-view';
import { RocketDesertView } from './entityviews/rocket-desert-view';

import defaultBackground from './assets/backgrounds/01-default.png?url';
import oceanBackground from './assets/backgrounds/02-ocean.png?url';
import spaceBackground from './assets/backgrounds/03-space.png?url';
import mineBackground from './assets/backgrounds/04-mine.png?url';
import cityBackground from './assets/backgrounds/05-city.png?url';
import desertBackground from './assets/backgrounds/06-desert.png?url';

export enum WorldType {
  Default,
  Ocean,
  Space,
  Mine,
  City,
  Desert,
}

const backgroundMap = new Map<WorldType, any>([
  [WorldType.Default, defaultBackground],
  [WorldType.Ocean, oceanBackground],
  [WorldType.Space, spaceBackground],
  [WorldType.Mine, mineBackground],
  [WorldType.City, cityBackground],
  [WorldType.Desert, desertBackground],
]);

const characterMap = new Map<WorldType, CharacterSkinType>([
  [WorldType.Default, 'none'],
  [WorldType.Ocean, 'water'],
  [WorldType.Space, 'moto'],
  [WorldType.Mine, 'helico'],
  [WorldType.City, 'water'],
  [WorldType.Desert, 'f1'],
]);

export type VehicleSkin = 'default' | 'ocean' | 'space' | 'mine' | 'city' | 'desert';

const vehicleMap = new Map<WorldType, VehicleSkin>([
  [WorldType.Default, 'default'],
  [WorldType.Ocean, 'ocean'],
  [WorldType.Space, 'space'],
  [WorldType.Mine, 'mine'],
  [WorldType.City, 'city'],
  [WorldType.Desert, 'desert'],
]);

export const defaultPuzzleSkin: PuzzleSkinData = {
  background: defaultBackground,
  characterSkin: 'none',
  vehicle: 'default',
};

export type PuzzleSkinData = {
  background: string;
  characterSkin: CharacterSkinType;
  vehicle: VehicleSkin;
};

export function getPuzzleSkin(world: WorldType): PuzzleSkinData {
  return {
    background: backgroundMap.get(world)!,
    characterSkin: characterMap.get(world)!,
    vehicle: vehicleMap.get(world)!,
  };
}

export function getVehicleAssetClass(vehicle: VehicleSkin): any {
  switch (vehicle) {
    default:
    case 'default':
      return RocketView;
    case 'ocean':
      return RocketOceanView;
    case 'space':
      return RocketSpaceView;
    case 'city':
      return RocketCityView;
    case 'mine':
      return RocketMineView;
    case 'desert':
      return RocketDesertView;
  }
}

export class PuzzleSkinController {
  private levelCount: number = 0;
  private changeSkinLevelCount = 3;
  private nowWorld: WorldType = WorldType.Default;
  public didWorldChanged: boolean = true;

  public getSkin() {
    return getPuzzleSkin(this.nowWorld);
  }

  public addLevel() {
    this.levelCount += 1;
    if (this.levelCount < this.changeSkinLevelCount) return;

    this.levelCount = 0;
    this.nowWorld = ((this.nowWorld + 1) % Object.keys(WorldType).filter((o) => isNaN(Number(o))).length) as WorldType;
    this.didWorldChanged = true;
  }
}
